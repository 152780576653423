// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
import { goalRedModel } from '..';
// Models

export enum DetailGActionTypes {
    DetailGOnServerCreated = '[Edit DetailG Dialog] DetailG On Server Created',
    DetailGCreated = '[Edit DetailG Dialog] DetailG Created',
    DetailGUpdated = '[Edit DetailG Dialog] DetailG Updated',
    DetailGsStatusUpdated = '[DetailGs List Page] DetailGs Status Updated',
    OneDetailGDeleted = '[DetailGs List Page] One DetailG Deleted',

    DetailGsPageRequested = '[DetailGs List Page] DetailGs Page Requested',
    DetailGsPageLoaded = '[DetailGs API] DetailGs Page Loaded',


    DetailGsPageCancelled = '[DetailGs API] DetailGs Page Cancelled',
    DetailGsPageToggleLoading = '[DetailGs] DetailGs Page Toggle Loading',
    DetailGActionToggleLoading = '[DetailGs] DetailGs Action Toggle Loading'
}

export class DetailGOnServerCreated implements Action {
    readonly type = DetailGActionTypes.DetailGOnServerCreated;
    constructor(public payload: { DetailG: goalRedModel }) { }
}

export class DetailGCreated implements Action {
    readonly type = DetailGActionTypes.DetailGCreated;
    constructor(public payload: { DetailG: goalRedModel }) { }
}

export class DetailGUpdated implements Action {
    readonly type = DetailGActionTypes.DetailGUpdated;
    constructor(public payload: {
        partialDetailG: Update<goalRedModel>, 
        DetailG: goalRedModel 
    }) { }  
}

export class DetailGsStatusUpdated implements Action {
    readonly type = DetailGActionTypes.DetailGsStatusUpdated;
    constructor(public  payload: {
        DetailGs: goalRedModel[],
        status: number
    }) { }
}

export class OneDetailGDeleted implements Action {
    readonly type = DetailGActionTypes.OneDetailGDeleted;
    constructor(public payload: { id: string }) {}
}


export class DetailGsPageRequested implements Action {
    readonly type = DetailGActionTypes.DetailGsPageRequested;
    constructor(public payload: { list: any }) { }
}

export class DetailGsPageLoaded implements Action {
    readonly type = DetailGActionTypes.DetailGsPageLoaded;
    constructor(public payload: any) { }
}

export class DetailGsPageCancelled implements Action {
    readonly type = DetailGActionTypes.DetailGsPageCancelled;
}

export class DetailGsPageToggleLoading implements Action {
    readonly type = DetailGActionTypes.DetailGsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class DetailGActionToggleLoading implements Action {
    readonly type = DetailGActionTypes.DetailGActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}




export type DetailGActions = DetailGOnServerCreated
| DetailGCreated
| DetailGUpdated
| DetailGsStatusUpdated
| OneDetailGDeleted
| DetailGsPageRequested
| DetailGsPageLoaded
| DetailGsPageCancelled
| DetailGsPageToggleLoading
| DetailGActionToggleLoading;
