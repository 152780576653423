// Angular
import { Component, Input, OnInit, ViewChild, Inject, ChangeDetectorRef, OnChanges } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions
} from "ng-apexcharts";
import { MAT_DIALOG_DATA } from '@angular/material';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
};

export interface Widget5Data {
  pic?: string;
  title: string;
  desc: string;
  url?: string;
  info?: string;
  largeInfo?: string;
}
@Component({ 
  selector: 'kt-visitordashbord',
  templateUrl: './visitordashbord.component.html', 
  styleUrls: ['./visitordashbord.component.scss']
})
export class VisitordashbordComponent implements OnChanges {


  // Public properties
  @Input() type: string = 'line';
  // @ViewChild('chart', { static: true }) chart: ElementRef;
  @Input('chat') chat: any;
  // @ViewChild('chart', { static: true }) chart: ChartComponent;
  // public chartOptions: Partial<ChartOptions>;
  public chartOptions: any;
  // public chartOptions: Partial<ChartOptions>;
  somme: number = 0;

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   */
  constructor(private _cdr: ChangeDetectorRef,
  ) {
  }
  changeStatus(): void {

    setTimeout(() => {

      this._cdr.detectChanges()
      this._cdr.markForCheck()

    }, 1500);
  }
  obj: any = '';

  ngOnChanges() {
    if(this.chat){

    this.chartOptions = {
      series: this.chat.count,
      chart: {
        // width: 360,
        height: 300,
        type: "donut"
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          }
        },
      },
    title: {
      text: 'Percentage Of Returning Visitors',
      align: "left",
      margin: 10,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        // fontFamily:  undefined,
        color: "#0D1861",
      },
    },
      colors: ['#B3C8DC','#3BB5FE', '#B3C8DC', '#B3C8DC'],
      labels: ['Non Returning Visitors','Returning Visitors'],
      legend: {
        show: true,
        position: "bottom"
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              show:true,
              position: "left"
            }
          }
        }
      ]
    };
    this.changeStatus();
  }
  }


}
