// Angular
import { Component, Input, OnInit, ViewChild, Inject, ChangeDetectorRef, OnChanges } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions
} from "ng-apexcharts";
import { MAT_DIALOG_DATA } from '@angular/material';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
};

export interface Widget5Data {
  pic?: string;
  title: string;
  desc: string;
  url?: string;
  info?: string;
  largeInfo?: string;
}

@Component({
  selector: 'kt-chartscinariodashbprd2',
  templateUrl: './chartscinariodashbprd2.component.html',
  styleUrls: ['./chartscinariodashbprd2.component.scss']
})
export class Chartscinariodashbprd2Component implements OnChanges {

  // Public properties
  @Input() type: string = 'line';
  // @ViewChild('chart', { static: true }) chart: ElementRef;
  @Input('chat') chat: any;
  // @ViewChild('chart', { static: true }) chart: ChartComponent;
  // public chartOptions: Partial<ChartOptions>;
  public chartOptions: any;
  // public chartOptions: Partial<ChartOptions>;
  somme: number = 0;

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   */
  constructor(private _cdr: ChangeDetectorRef,
  ) {
  }
  changeStatus(): void {

    setTimeout(() => {

      this._cdr.detectChanges()
      this._cdr.markForCheck()

    }, 1500);
  }
  obj: any = '';

  ngOnChanges() {
    if (this.chat) {
      console.log("babbbbbbbbbbbbbbbbbbbbbbbbbb");
      this.chat.importance;
      this.chartOptions = {
        series: [
          {
            // name: "feature importance",
            data: this.chat.importance,
          },
        ],
        legend: {
          show: false,
        },
        colors: [
          "#3BB5FE",
          "#90BDF1",
          "#B4D1F2",
          "#C3DAF3",
          "#C3D3E2",
          "#CFDBE6",
          "#B3C8DC",
        ],
        chart: {
          type: "bar",
          height: 300,
          width: 750,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
            normal: {
              filter: {
                type: 'none',
              },
            },
          },
        },
        title: {
          text: 'AI Model Most Important Feature',
          align: 'left',
          margin: 10,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#0D1861',
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
          },
        },
        xaxis: {
          categories: this.chat.columns,
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "10px",
            },
          },
        },
      };
      
      this.changeStatus();
    }
  }
}
