// Angular
import { Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef, OnChanges } from '@angular/core';
// Layout config
import { LayoutConfigService } from '../../../../../core/_base/layout';
import Chart from 'chart.js';
import { VisitorsService } from '../../../../../core/visiteur/_services/visiteur.service';
import { goalRedsService } from '../../../../../core/goal';
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexXAxis,
	ApexDataLabels,
	ApexTitleSubtitle,
	ApexStroke,
	ApexGrid
} from "ng-apexcharts";

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	dataLabels: ApexDataLabels;
	grid: ApexGrid;
	stroke: ApexStroke;
	title: ApexTitleSubtitle;
	colors:any;
};
/**
 * Sample components with sample data
 */
@Component({
	selector: 'kt-widget12',
	templateUrl: './widget12.component.html',
	styleUrls: ['./widget12.component.scss']
})

export class Widget12Component implements  OnChanges {
	obj: any = '';
	bool: boolean = false;
	changeStatus(): void {

		setTimeout(() => {

			this._cdr.detectChanges()
			this._cdr.markForCheck()

		}, 1500);
	}
	// Public properties
	@Input() data: { labels: string[], datasets: any[] };
	@Input() type: string = 'line';
	// @ViewChild('chart', { static: true }) chart: ElementRef;
	@Input('chat') chat: any;
	// @Input('x') x: any;
	// @Input('date3') date3: any;
	// @Input('date4') date4: any;
	@ViewChild("chart", { static: true }) chart: ChartComponent;
	public chartOptions: any;
	dataScores: number[] = [50, 20, 30, 70, 20];

	/**
	 * Component constructor
	 * @param layoutConfigService
	 */
	constructor(
		private layoutConfigService: LayoutConfigService,
		private VisitorsService: VisitorsService, private goalRedsService: goalRedsService,
		private _cdr: ChangeDetectorRef,
	) {
	}
	ngOnChanges() { 
		this.obj = '';
		// this.goalRedsService.filter(this.idgoal, this.x, this.date3, this.date4).subscribe(list => {
		// let obj = JSON.parse(JSON.stringify(list));			
		this.obj = this.chat;

		// console.log(this.obj.gain_rate);
		this.changeStatus();
		const color = Chart.helpers.color;
		this.chartOptions = {
			series: [
				{
					name: "",
					data: this.obj.gain_rate2.rates
				}
			],
			chart: {
				height: 350,
				type: "line",
				zoom: {
					enabled: false
				}
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: "straight"
			},
			title: {
				text: "",
				align: "left"
			},
			colors: ['#70b77e'],
			grid: {
				row: {
					colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
					opacity: 0.5
				}
			},
			xaxis: {
				categories: this.obj.gain_rate2.dates
			}
		};
		// this.changeStatus();

		// });
		// this.initChart(); 
	}


}