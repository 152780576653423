import { mergeMap, tap } from 'rxjs/operators';
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { BaseDataSource, QueryRealtimeParamsModel } from '../../_base/crud';
import { AppState } from '../../reducers';
import { selectKpiaddsInStore, selectKpiaddsPageLoading, selectKpiaddsShowInitWaitingMessage } from '../_selectors/kpiadd.selectors';


export class KpiaddsDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
        super();

		this.loading$ = this.store.pipe(
			select(selectKpiaddsPageLoading),
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectKpiaddsShowInitWaitingMessage)
        );
        
        this.store.pipe(
			select(selectKpiaddsInStore),
		).subscribe((response: QueryRealtimeParamsModel) => {
			// this.paginatorTotalSubject.next(response.totalCount);			
			this.entitySubject.next(response.items);
		});

    }
}