import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { goalRedModel } from '../_models/goalRedModel';
import * as firebase from 'firebase';
import { catchError, map, finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_goalRed_URL = 'api/goalReds';

@Injectable()
export class goalRedsService {
	token: string;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	creategoalRed(goalRed: goalRedModel): Observable<goalRedModel> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post<goalRedModel>(environment.API_URL + "/goals", {
			'goalName': goalRed.Goalname,
			'idScenario': goalRed.tag,
			// 'actionName': goalRed.actionName,
			'createdOn': goalRed.createdon,
			'listPopup': goalRed.listpop,
			'triggers': goalRed.Pop1,
			// 'popup_intervals': goalRed.Pop2,
			ignored_data_per_day: {
				'2': { 'percentage': goalRed.j2, 'act_randomly': goalRed.b2, 'capture': !goalRed.c2, 'default_discount_percentage': goalRed.discount2 },
				'6': { 'percentage': goalRed.j6, 'act_randomly': goalRed.b6, 'capture': !goalRed.c6, 'default_discount_percentage': goalRed.discount6 },
				'4': { 'percentage': goalRed.j4, 'act_randomly': goalRed.b4, 'capture': !goalRed.c4, 'default_discount_percentage': goalRed.discount4 },
				'0': { 'percentage': goalRed.j0, 'act_randomly': goalRed.b0, 'capture': !goalRed.c0, 'default_discount_percentage': goalRed.discount0 },
				'1': { 'percentage': goalRed.j1, 'act_randomly': goalRed.b1, 'capture': !goalRed.c1, 'default_discount_percentage': goalRed.discount1 },
				'5': { 'percentage': goalRed.j5, 'act_randomly': goalRed.b5, 'capture': !goalRed.c5, 'default_discount_percentage': goalRed.discount5 },
				'3': { 'percentage': goalRed.j3, 'act_randomly': goalRed.b3, 'capture': !goalRed.c3, 'default_discount_percentage': goalRed.discount3 },
			}
		}, { headers: headers });
	}
	getAllgoalReds(): Observable<goalRedModel[]> {
		return this.http.get<goalRedModel[]>(environment.API_URL + '/cars');
	}

	getgoalRedById(goalRedId: number): Observable<goalRedModel> {
		return this.http.get<goalRedModel>(API_goalRed_URL + `/${goalRedId}`);
	}
	findDetailGs(eid): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get<any>(environment.API_URL + '/goals/' + eid, { headers: headers });
	}
	getchart(eid, x): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get<any>(environment.reporting + '/statistics/visitor_events/' + eid + '/' + x, { headers: headers });

	}
	erroHandler(error: HttpErrorResponse) {
		return 's';
	}
	findKpiGoals(goal_id: any, x, to, from): Observable<QueryResultsModel> {
		if (x === 'Range by date') {
			let headers = new HttpHeaders();
			headers = headers.append("Content-Type", "application/json");
			return this.http.get<any>(environment.reporting + '/statistics/dashboard/' + goal_id + '?date_range=' + from + ',' + to, { headers: headers }).pipe(
				catchError(err => {
					// this._errorMessage.next(err);
					console.error('UPDATE ITEM', err);
					return of(null);
				}),
				finalize(() => { })
			);
		} else if (x === 'Last week') {
			let headers = new HttpHeaders();
			headers = headers.append("Content-Type", "application/json");
			return this.http.get<any>(environment.reporting + '/statistics/dashboard/' + goal_id + '?date_range=' + 'last_week', { headers: headers }).pipe(
				catchError(err => {
					// this._errorMessage.next(err);
					console.error('UPDATE ITEM', err);
					return of(null);
				}),
				finalize(() => { })
			);
		} else if (x === 'All') {
			let headers = new HttpHeaders();
			headers = headers.append("Content-Type", "application/json");
			return this.http.get<any>(environment.reporting + '/statistics/dashboard/' + goal_id, { headers: headers }).pipe(
				catchError(err => {
					// this._errorMessage.next(err);
					console.error('UPDATE ITEM', err);
					return of(null);
				}),
				finalize(() => { })
			);
		}
		else {
			let headers = new HttpHeaders();
			headers = headers.append("Content-Type", "application/json");
			return this.http.get<any>(environment.reporting + '/statistics/dashboard/' + goal_id + '?date_range=' + 'last_month', { headers: headers }).pipe(
				catchError(err => {
					// this._errorMessage.next(err);
					console.error('UPDATE ITEM', err);
					return of(null);
				}),
				finalize(() => { })
			);
		}


	}
	getstatisticParIdGoalPopop(idGoal: any, x, to, from): Observable<QueryResultsModel> {
		if (x === 'Range by date') {
			let headers = new HttpHeaders();
			headers = headers.append("Content-Type", "application/json");
			return this.http.get<any>(environment.reporting + '/statistics/goals/' + idGoal + '/popup_efficiency' + '?date_range=' + from + ',' + to, { headers: headers }).pipe(
				catchError(err => {
					// this._errorMessage.next(err);
					console.error('UPDATE ITEM', err);
					return of(null);
				}),
				finalize(() => { })
			);
		} else if (x === 'Last week') {
			let headers = new HttpHeaders();
			headers = headers.append("Content-Type", "application/json");
			return this.http.get<any>(environment.reporting + '/statistics/goals/' + idGoal + '/popup_efficiency' + '?date_range=' + 'last_week', { headers: headers }).pipe(
				catchError(err => {
					// this._errorMessage.next(err);
					console.error('UPDATE ITEM', err);
					return of(null);
				}),
				finalize(() => { })
			);
		} else if (x === 'All') {
			let headers = new HttpHeaders();
			headers = headers.append("Content-Type", "application/json");
			return this.http.get<any>(environment.reporting + '/statistics/goals/' + idGoal + '/popup_efficiency', { headers: headers }).pipe(
				catchError(err => {
					// this._errorMessage.next(err);
					console.error('UPDATE ITEM', err);
					return of(null);
				}),
				finalize(() => { })
			);
		}
		else {
			let headers = new HttpHeaders();
			headers = headers.append("Content-Type", "application/json");
			return this.http.get<any>(environment.reporting + '/statistics/goals/' + idGoal + '/popup_efficiency' + '?date_range=' + 'last_month', { headers: headers }).pipe(
				catchError(err => {
					// this._errorMessage.next(err);
					console.error('UPDATE ITEM', err);
					return of(null);
				}),
				finalize(() => { })
			);
		}


	}
	// getstatisticParIdGoalPopop(idGoal): Observable<any> {
	// 	let headers = new HttpHeaders();
	// 	headers = headers.append("Content-Type", "application/json");
	// 	return this.http.get<any>(environment.reporting + '/statistics/goals/' + idGoal + '/popup_efficiency', { headers: headers });

	// }
	geteventvisitor(idGoal): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get<any>(environment.reporting + '/statistics/visitor_events/' + idGoal, { headers: headers }).pipe(
			catchError(err => {
				// this._errorMessage.next(err);
				console.error('UPDATE ITEM', err);
				return of(null);
			}),
			finalize(() => { })
		);

	}
	getstatisticParPopup(): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get<any>(environment.reporting + '/statistics/popups_efficiency', { headers: headers });

	}
	findgoalReds(): any {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.API_URL + '/goals', { headers: headers }).pipe(
			catchError(err => {
				// this._errorMessage.next(err);
				console.error('UPDATE ITEM', err);
				return of(null);
			}),
			finalize(() => { })
		);
	}
	findgoalReds2(queryParams: QueryParamsModel): any {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.API_URL + '/goals', { headers: headers });
	}
	updateActive(id: string, value: boolean): Observable<any> {
		console.log(id);

		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post<goalRedModel>(environment.API_URL + '/goals/update_activation/' + id, {
			"is_activated": value,
		}
			, { headers: headers }).pipe(
				catchError(err => {
					console.error('CREATE ITEM', err);
					return of({ id: undefined, err: err });
				})
			);
	}
	rule
	updateseuil(goalRed, seuil): Observable<any> {
		if (!goalRed.rule) {
			this.rule = 0;
		} else {
			this.rule = goalRed.rule;
		}
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL + '/goals/' + goalRed.id, {
			'priority': seuil,
			'rule': this.rule
		}, { headers: headers });
	}
	updateseuil2(goalRed, seuil): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL + '/goals/' + goalRed.id, {
			'rule': seuil
		}, { headers: headers });
	}
	updateGoal(goalRed: goalRedModel): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL + '/goals/' + goalRed.id, {
			// "Goalname": goalRed.Goalname,
			// // "actionName": goalRed.actionName,
			// // "goalCategory": goalRed.goalCategory,
			// "loginValue": goalRed.loginValue,
			// "sessionIdValue": goalRed.sessionIdValue,
			// "visiIdValue": goalRed.visiIdValue,
			ignored_data_per_day: {
				'2': { 'percentage': goalRed.j2, 'act_randomly': goalRed.b2, 'capture': goalRed.c2, 'default_discount_percentage': goalRed.discount2 },
				'6': { 'percentage': goalRed.j6, 'act_randomly': goalRed.b6, 'capture': goalRed.c6, 'default_discount_percentage': goalRed.discount6 },
				'4': { 'percentage': goalRed.j4, 'act_randomly': goalRed.b4, 'capture': goalRed.c4, 'default_discount_percentage': goalRed.discount4 },
				'0': { 'percentage': goalRed.j0, 'act_randomly': goalRed.b0, 'capture': goalRed.c0, 'default_discount_percentage': goalRed.discount0 },
				'1': { 'percentage': goalRed.j1, 'act_randomly': goalRed.b1, 'capture': goalRed.c1, 'default_discount_percentage': goalRed.discount1 },
				'5': { 'percentage': goalRed.j5, 'act_randomly': goalRed.b5, 'capture': goalRed.c5, 'default_discount_percentage': goalRed.discount5 },
				'3': { 'percentage': goalRed.j3, 'act_randomly': goalRed.b3, 'capture': goalRed.c3, 'default_discount_percentage': goalRed.discount3 },
			}


		}, { headers: headers });
	}



	updategoalRed(goalRed: goalRedModel): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL + '/goalReds/' + goalRed.id, {
			"activate": goalRed.activate,
		}, { headers: headers });
	}

	updateStatusForgoalRed(goalReds: goalRedModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			goalRedsForUpdate: goalReds,
			newStatus: status
		};
		const url = API_goalRed_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	deletegoalRed(goalRedId: number): Observable<goalRedModel> {
		return this.http.delete<goalRedModel>(environment.API_URL + '/cars' + '/' + goalRedId);
	}

	deletegoalReds(ids: number[] = []): Observable<any> {
		const url = API_goalRed_URL + '/deletegoalReds';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { goalRedIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders });
	}
	deletegoal(goalRedId): Observable<goalRedModel> {
		return this.http.delete<goalRedModel>(environment.API_URL + '/goals' + '/' + goalRedId);
	}

}
