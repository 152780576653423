import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { scoringRecordActions, scoringRecordActionTypes } from '../_actions/scoringRecord.actions';
import { scoringRecordModel } from '../_models/scoringRecordModel';
import { QueryParamsModel } from '../../_base/crud';

export interface scoringRecordState extends EntityState<scoringRecordModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedscoringRecordId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<scoringRecordModel> = createEntityAdapter<scoringRecordModel>();

export const initialscoringRecordState: scoringRecordState = adapter.getInitialState({
    scoringRecordForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedscoringRecordId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function scoringRecordsReducer(state = initialscoringRecordState, action: scoringRecordActions): scoringRecordState {
    switch  (action.type) {
        case scoringRecordActionTypes.scoringRecordsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedscoringRecordId: undefined
            };
        }    

        case scoringRecordActionTypes.scoringRecordActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }

        case scoringRecordActionTypes.scoringRecordOnServerCreated: return {
            ...state
        };

        case scoringRecordActionTypes.scoringRecordUpdated: return adapter.updateOne(action.payload.partialscoringRecord, state);
        case scoringRecordActionTypes.scoringRecordUpdated2: return adapter.updateOne(action.payload.partialscoringRecord, state);

        // case scoringRecordActionTypes.scoringRecordsStatusUpdated: {
        //     const _partialscoringRecord: Update<scoringRecordModel>[] = [];
        //     for (let i = 0; i < action.payload.scoringRecords.length; i++) {
        //         _partialscoringRecord.push({
		// 		    id: action.payload.scoringRecords[i].id,
		// 		    changes: {
        //                 status: action.payload.status
        //             }
		// 	    });
        //     }
        //     return adapter.updateMany(_partialscoringRecord, state);
        // }

        case scoringRecordActionTypes.OnescoringRecordDeleted: return adapter.removeOne(action.payload.id, state);

        case scoringRecordActionTypes.scoringRecordsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }

        case scoringRecordActionTypes.scoringRecordsPageLoaded: {           
            return adapter.addOne(action.payload.scoringRecords.status_fs, {
                ...initialscoringRecordState,                
            });
        }
        case scoringRecordActionTypes.BqscoringRecordsPageLoaded: {               
                    
            return adapter.addOne(action.payload.scoringRecords.status_bq, {
                ...initialscoringRecordState,                
            });
        }
        case scoringRecordActionTypes.ScoringscoringRecordPageLoaded: {               
                    
            return adapter.addOne(action.payload.scoringRecords.calculate_score, {
                ...initialscoringRecordState,                
            });
        }
        case scoringRecordActionTypes.TriggerPageLoaded: {               
                    
            return adapter.addOne(action.payload.scoringRecords.trigger_action, {
                ...initialscoringRecordState,                
            });
        }
        default: return state;
    }
}



export const getscoringRecordState = createFeatureSelector<scoringRecordModel>('scoringRecords');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
