import { BaseModel } from '../../_base/crud';

export class EventCapturedModel  extends BaseModel {
	id: number;
	model: string;
	yearOfConstruction: Date;
	numDoors: number;
	clim: number;  
	browser:string;
	visiteur_id:string;
	ip_address:string;
	device_type:string;
	page_title:string;
	domain:string;
	status: number; 
	date_hours: string;
	city:string;
	type:string;
	session_id:string;
	clear() {
		this.yearOfConstruction = new Date();
		this.model = '';
		this.numDoors = 4;
		this.clim = 0; 
		this.status=1;
	}
}

