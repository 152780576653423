
import * as firebase from 'firebase';
// Angular
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../../core/auth';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$: Observable<User>;
	pulse:any;
	pulseLight:any;
	iconType:any;
	flaticon:any;
	settings:any;
	dot:any;

	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;
	user: { [key: string]: any; };
	eids: any;
	eid: any;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AppState>,private http: HttpClient,private _cdr: ChangeDetectorRef,private router: Router,  ) {
		this.get().subscribe((res: any) => {
			this.eids = res.user_data.claims.eids;
			this.eid = res.user_data.claims.eid;
		});
		this.get2().subscribe((res: any) => {
			this.changeStatus();

			this.user = res.user_data;
		  });
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		
	}
	changeStatus(): void {

		setTimeout(() => {
		  this._cdr.detectChanges()
		  this._cdr.markForCheck()

		}, 500);
	   }
	get2() {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.API_URL+'/users/user_claims', { headers: headers });
	}
	save(x) {
		this.changeeid(x).subscribe(list => {
			this.get().subscribe((res: any) => {
				this.changeStatus();
				this.router.navigateByUrl('/goal/detail', { skipLocationChange: true }).then(() => {
					this.router.navigate(['/popup/goal']);
					this.changeStatus();
					this.get();
		
				});
				this.eids = res.user_data.claims.eids;
				this.eid = res.user_data.claims.eid;
				this.changeStatus();
			});

		})
	}
	// Set bg image path
	@Input() bgImage: string;

	get() {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.API_URL+'/users/user_claims', { headers: headers });
	}
	backGroundStyle(): string {
		if (!this.bgImage) {
			return 'none';
		}

		return 'url(' + this.bgImage + ')';
	}
	changeeid(eid): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL+'/users/set_active_namespace/' + eid, { headers: headers });
	} 
	/**
	 * Log out
	 */
	logout() {
		this.store.dispatch(new Logout());
	}
}

