// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { scoringRecordModel } from '../_models/scoringRecordModel';

export enum scoringRecordActionTypes {
    scoringRecordOnServerCreated = '[Edit scoringRecord Dialog] scoringRecord On Server Created',
    scoringRecordCreated = '[Edit scoringRecord Dialog] scoringRecord Created',
    scoringRecordUpdated = '[Edit scoringRecord Dialog] scoringRecord Updated',
    scoringRecordUpdated2 = '[Edit scoringRecord Dialog] scoringRecord Updated2',
    scoringRecordsStatusUpdated = '[scoringRecords List Page] scoringRecords Status Updated',
    OnescoringRecordDeleted = '[scoringRecords List Page] One scoringRecord Deleted',

    scoringRecordsPageRequested = '[scoringRecords List Page] scoringRecords Page Requested',
    scoringRecordsPageLoaded = '[scoringRecords API] scoringRecords Page Loaded',
    BqscoringRecordsPageRequested = '[BqscoringRecords List Page] BqscoringRecords Page Requested',
    BqscoringRecordsPageLoaded = '[BqscoringRecords API] BqscoringRecords Page Loaded',

    ScoringscoringRecordPageRequested = '[ScoringscoringRecord List Page] ScoringscoringRecord Page Requested',
    ScoringscoringRecordPageLoaded = '[ScoringscoringRecord API] ScoringscoringRecord Page Loaded',
    TriggerPageRequested = '[ScoringscoringRecord List Page] Trigger Page Requested',
    TriggerPageLoaded = '[ScoringscoringRecord API] Trigger Page Loaded',
    scoringRecordsPageCancelled = '[scoringRecords API] scoringRecords Page Cancelled',
    scoringRecordsPageToggleLoading = '[scoringRecords] scoringRecords Page Toggle Loading',
    scoringRecordActionToggleLoading = '[scoringRecords] scoringRecords Action Toggle Loading'
}

export class scoringRecordOnServerCreated implements Action {
    readonly type = scoringRecordActionTypes.scoringRecordOnServerCreated;
    constructor(public payload: { scoringRecord: scoringRecordModel }) { }
}

export class scoringRecordCreated implements Action {
    readonly type = scoringRecordActionTypes.scoringRecordCreated;
    constructor(public payload: { scoringRecord: scoringRecordModel }) { }
}

export class scoringRecordUpdated implements Action {
    readonly type = scoringRecordActionTypes.scoringRecordUpdated;
    constructor(public payload: {
        partialscoringRecord: Update<scoringRecordModel>, 
        scoringRecord: scoringRecordModel 
    }) { }
}
export class scoringRecordUpdated2 implements Action {
    readonly type = scoringRecordActionTypes.scoringRecordUpdated2;
    constructor(public payload: {
        partialscoringRecord: Update<any>, 
        scoringRecord: any ,
        type:string
    }) { }
}


export class scoringRecordsStatusUpdated implements Action {
    readonly type = scoringRecordActionTypes.scoringRecordsStatusUpdated;
    constructor(public  payload: {
        scoringRecords: scoringRecordModel[],
        status: number
    }) { }
}

export class OnescoringRecordDeleted implements Action {
    readonly type = scoringRecordActionTypes.OnescoringRecordDeleted;
    constructor(public payload: { id: number }) {}
}


export class scoringRecordsPageRequested implements Action {
    readonly type = scoringRecordActionTypes.scoringRecordsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class scoringRecordsPageLoaded implements Action {
    readonly type = scoringRecordActionTypes.scoringRecordsPageLoaded;
    constructor(public payload :any ) { }
}

export class BqscoringRecordsPageRequested implements Action {
    readonly type = scoringRecordActionTypes.BqscoringRecordsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class BqscoringRecordsPageLoaded implements Action {
    readonly type = scoringRecordActionTypes.BqscoringRecordsPageLoaded;
    constructor(public payload :any ) { }
}
export class ScoringscoringRecordPageRequested implements Action {
    readonly type = scoringRecordActionTypes.ScoringscoringRecordPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class ScoringscoringRecordPageLoaded implements Action {
    readonly type = scoringRecordActionTypes.ScoringscoringRecordPageLoaded;
    constructor(public payload :any ) { }
}

export class TriggerPageRequested implements Action {
    readonly type = scoringRecordActionTypes.TriggerPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class TriggerPageLoaded implements Action {
    readonly type = scoringRecordActionTypes.TriggerPageLoaded;
    constructor(public payload :any ) { }
}
export class scoringRecordsPageCancelled implements Action {
    readonly type = scoringRecordActionTypes.scoringRecordsPageCancelled;
}

export class scoringRecordsPageToggleLoading implements Action {
    readonly type = scoringRecordActionTypes.scoringRecordsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class scoringRecordActionToggleLoading implements Action {
    readonly type = scoringRecordActionTypes.scoringRecordActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type scoringRecordActions = scoringRecordOnServerCreated
| scoringRecordCreated
| scoringRecordUpdated
| scoringRecordUpdated2
| scoringRecordsStatusUpdated
| OnescoringRecordDeleted
| scoringRecordsPageRequested
| scoringRecordsPageLoaded
| BqscoringRecordsPageRequested
| BqscoringRecordsPageLoaded
| ScoringscoringRecordPageRequested
| ScoringscoringRecordPageLoaded
| TriggerPageRequested
| TriggerPageLoaded
| scoringRecordsPageCancelled
| scoringRecordsPageToggleLoading
| scoringRecordActionToggleLoading;
