import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { TriggerModel } from '../_models/TriggerModel';
import * as firebase from 'firebase';
import { environment } from '../../../../environments/environment';

const API_Trigger_URL = 'api/Triggers';

@Injectable()
export class TriggersService {
	token: string;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	createTrigger(Trigger: TriggerModel): Observable<TriggerModel> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post<TriggerModel>(environment.API_URL+"/Triggers", Trigger, { headers: headers });
	}

	getAllTriggers(): Observable<TriggerModel[]> {
		return this.http.get<TriggerModel[]>(environment.API_URL+'/cars');
	}

	getTriggerById(TriggerId: number): Observable<TriggerModel> {
		return this.http.get<TriggerModel>(API_Trigger_URL + `/${TriggerId}`);
	}

	findTriggers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {		
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get<QueryResultsModel>(environment.API_URL+'/enterprises/db_status', { headers: headers });
	}

	updateTrigger(Trigger: TriggerModel): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post(environment.API_URL+'/enterprises/db_status?db='
			+ Trigger.db + '&status=' + Trigger.status, { option: false }, { headers: headers });
	}
	updateTrigger2(Trigger: any, type: string): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		if (type === "score") {
			return this.http.post(environment.API_URL+'/enterprises/db_status?calculate_score='
				+ Trigger,  { headers: headers });
		}else if(type === "toggle"){
			return this.http.post(environment.API_URL+'/enterprises/db_status?trigger_action='
				+ Trigger,  { headers: headers });
		}

	}


	updateStatusForTrigger(Triggers: TriggerModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			TriggersForUpdate: Triggers,
			newStatus: status
		};
		const url = API_Trigger_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	deleteTrigger(TriggerId: number): Observable<TriggerModel> {
		return this.http.delete<TriggerModel>(environment.API_URL+'/cars' + '/' + TriggerId);
	}

	deleteTriggers(ids: number[] = []): Observable<any> {
		const url = API_Trigger_URL + '/deleteTriggers';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { TriggerIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders });
	}
}
