import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../_models/user.model';
import { catchError, map } from 'rxjs/operators';
import { auth } from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { environment1 } from '../../../../environments/environment';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthService {
    token: string = "";
    user$: Observable<User>;
    typeLogin: string = "";
    constructor(private http: HttpClient, private afAuth: AngularFireAuth,
        private router: Router, private afs: AngularFirestore, private ngZone: NgZone, private store: Store<AppState>,


    ) {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((idToken) => {
                    this.token = idToken;
                }, (error) => {
                });
            }
        });

    }
    idtokencureent: string;
	get() {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.API_URL+'/users/user_claims', { headers: headers });
	}
    // statistics/admin_dashboard_enterprises_list

    getstatistics() {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.reporting+'/statistics/admin_dashboard_enterprises_list', { headers: headers });
	}
    get_all_users_admin() {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.API_URL+'/users/get_all_users_admin', { headers: headers });
	}
    getstatisticsbyid(id,eid) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.reporting+'/statistics/admin_dashboard_compaign_analysis?eid='+eid+'&goal_id='+id, { headers: headers });
	}
    
    AddNameSpace(nameSpace: string, img: string | null) {
        let headers = new HttpHeaders().append("Content-Type", "application/json");
    
        // Prepare the payload
        const payload: any = { "namespace": nameSpace };
        if (img !== null) {
          payload.logo_img = img;
        }
    
        return this.http.post(environment.API_URL + "/fba/enterprises", payload, {
          headers: headers,
          responseType: 'text'
        });
      }
    changeeid(eid): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL+'/users/set_active_namespace/' + eid, { headers: headers });
	}
    resultat: any;

    loginwithtoken() {
        firebase.auth().currentUser.getIdToken().then((idToken) => {
                firebase.auth().signInWithCustomToken(idToken).then((data) => {
                });
        });
    }
    getToknen(n: number): string {
        if (n == 1) {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    user.getIdToken().then((idToken) => {
                        this.token = idToken;
                    }, (error) => {
                    });
                } else {
                }
            });
            return this.token
        }

    }

    refreshToken() {
        
        firebase.auth().currentUser.getIdToken().then(res =>{
           this.token=res; 
        });
        return this.token
    }
    updateProfile(user) {
        // @app.route('/fba/users/<string:uid>', methods=['PUT'])
        var userup = firebase.auth().currentUser;
        return userup.updateProfile({
            displayName: user.display_name,
            // photoURL: "https://example.com/jane-q-user/profile.jpg"
        })
    }
    // gettokenapi(token) {
    //     let headers = new HttpHeaders();
    //     headers = headers.append('Authorization', token);
    //     headers = headers.append("Content-Type", "application/json");
    //     return this.http.get('${environment.API_URL}/fba/login', { headers: headers, responseType: 'text' });
    // }

    logout() {
        return this.afAuth.auth.signOut().then(() => {
            firebase.auth().signOut().then(function () {
                // Sign-out successful.
                this.router.navigate(['/auth/login']);
                this.getToknen(2);
                environment1.authTokenKey = null;

            }).catch(function (error) {
                // An error happened.
            });
        })

    }
    async login(email: string, password: string) {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password);
    }
    async googleSignin() {
        const provider = new auth.GoogleAuthProvider();
        return this.afAuth.auth.signInWithPopup(provider)
    }

    getUserByToken(): Promise<User> {
        return new Promise((resolve, reject) => {
            const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
                unsubscribe();
                if (user) {
                    return user;
                }
            });
        });
    }
    register(user: User): Observable<any> {
        if (user.phone_number === '') {
            return this.http.post<User>(`${environment.API_URL}/fba/register`, {
                email: user.email.toLowerCase(),
                password: user.password,
                display_name: user.display_name,
            }).pipe(
                map((res: User) => res),
                catchError(err => {
                    // handle the error appropriately here
                    console.error('Error occurred during registration:', err);
                    return of(null); // use of(null) to return an observable with a null value
                })
            );
        } else {
            return this.http.post<User>(`${environment.API_URL}/fba/register`, {
                email: user.email.toLowerCase(),
                password: user.password,
                display_name: user.display_name,
                phone_number: user.phone_number,
            }).pipe(
                map((res: User) => res),
                catchError(err => {
                    // handle the error appropriately here
                    console.error('Error occurred during registration:', err);
                    return of(null); // use of(null) to return an observable with a null value
                })
            );
        }
    }
    
    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    public requestPassword(passwordResetEmail: string) {
        return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
            .then(() => {
                window.alert('Password reset email sent, check your inbox.');
            }).catch((error) => {
                window.alert(error)
            })
    }




    /*
     * Handle Http operation that failed.
     * Let the app continue.
   *
   * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
