import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, finalize } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
// import { kpi } from '../_models/kpi';
import { Observable } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { ScenarioConfigModel } from '../_models/scenarioConfigModel';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

/*api

    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `${localStorage.getItem('token')}`);
    headers = headers.append("Content-Type", "application/json");
	return this.http.post(environment.API_URL+"/scenarios",
	

	*/



const API_ScenarioConfig_URL = 'api/ScenarioConfigs';
@Injectable()
export class ScenarioConfigsService {
	today: number = Date.now();
	onFileSelected: BehaviorSubject<any>;
	token: string;
	eventLisylist = new BehaviorSubject(undefined);

	private eventlist = [];


	eventlist$ = this.eventLisylist.asObservable();

	constructor(public http: HttpClient, private firestore: AngularFirestore, private router: Router, private httpUtils: HttpUtilsService
	) {
		this.onFileSelected = new BehaviorSubject({});

	}
	getAllscenario() {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.API_URL + '/scenarios', { headers: headers })
			.pipe(map(res => {
				return res;
			}));
	}

	getactivescenario() {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.API_URL + '/scenarios?status=true', { headers: headers }).pipe(map(res => {
			return res;
		}));
	}


	getnaturelconvertion(id) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.reporting + '/statistics/scenario_stats/' + id, { headers: headers }).pipe(
			catchError(err => {
				// this._errorMessage.next(err);
				console.error('UPDATE ITEM', err);
				return of(null);
			}),
			finalize(() => { })
		);
	}

	getOnescenario(id) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.API_URL + '/scenarios/' + id, { headers: headers })
			.pipe(map(res => {
				return res;
			}));
	}

	genrate(id) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.preprocessing + '/generate/' + id, { headers: headers })
			.pipe(map(res => {
				return res;
			}));
	}
	train(id) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.preprocessing + '/train/' + id, { headers: headers })
			.pipe(map(res => {
				return res;
			}));
	}
	updatescenario(id, obj) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL + "/scenarios/" + id,
			{

				"status": obj,
			}, { headers: headers });
	}
	updateKpi(id, tab, champ) {
		let data: any = {
			"kpis": {
				"general": {
				}
			}
		}
		data['kpis']['general'][champ] = tab
		console.log(data);
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL + "/scenarios/" + id,
			data, { headers: headers });
	}
	updateKpiSinglearticle(id, tab) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL + "/scenarios/" + id,
			{
				"kpis": {
					"standard": {
						"Single_article_bought": tab
					}
				},
			}, { headers: headers });
	}
	deleteSenario(id) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.delete(environment.API_URL + '/scenarios/' + id, { headers: headers })
			.pipe(map(res => {
				return res;
			}));
	}
	getEID() {
		// {{domain-console}}/enterprises/get_eid
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.API_URL + '/enterprises/get_eid', { headers: headers })
			.pipe(map(res => {
				return res;
			}));
	}
	generating(eid, id) {
		return this.firestore.collection("enterprises").doc(eid).collection("scenarios").doc(id).valueChanges();
		// return this.firestore.doc(`/entreprises/${idNameSpace}`).collection<PropertyAnalysis>("analysis").doc($key).valueChanges(); ;

	}

	setProfileListKpi(eventList) {
		this.eventlist = eventList;
		this.eventLisylist.next(eventList);
	}

	getProfileList() {
		return Object.assign({}, this.eventlist);
	}
	setProfileInit(eventList) {
		this.eventlist = eventList;
		this.eventLisylist.next(eventList);
	}
	Short_longterm: boolean;
	config: any;
	createScenarioConfig(ScenarioConfig: ScenarioConfigModel): Observable<ScenarioConfigModel> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		// if (ScenarioConfig.sessionIdValue === true) {
		// 	this.Short_longterm = true;
		// 	this.config = {
		// 		"number_of_clicks_on_the_buttons": { "options": "Specific Tag", "tag": [] },
		// 		"number_of_pages_read_in_the_session": { "options": "Specific Tag", "tag": [] },
		// 		"number_of_pages_scrolled_in_the_session": { "options": "Specific Tag", "tag": [] },
		// 		"time_per_page_in_the_session": { "options": "Specific Tag", "tag": [] },
		// 		"form_submitted_in_the_session": { "options": "Specific Tag", "tag": [] },
		// 		"specific_page_read_in_the_session": { "options": "Specific Tag", "tag": [] },
		// 		"download_performed_in_the_session": { "options": "Specific Tag", "tag": [] },
		// 	}
		// }
		// else {
		// this.Short_longterm = false;
		this.config = {
			// "average_session_duration": { "options": "Specific Tag", "tag": [] },
			// "number_of_sessions_(visits)": { "options": "Specific Tag", "tag": [] },
			// "time_spent": { "options": "Specific Tag", "tag": [] },
			"number_of_clicks_on_the_buttons": { "options": "Specific Tag", "tag": [] },
			"number_of_pages_read": { "options": "Specific Tag", "tag": [] },
			// "average_time_per_page": { "options": "Specific Tag", "tag": [] },
			"number_of_pages_scrolled": { "options": "Specific Tag", "tag": [] },
			// "form_submitted": { "options": "Specific Tag", "tag": [] },
			// "specific_page_read": { "options": "Specific Tag", "tag": [] },
			// "download_performed": { "options": "Specific Tag", "tag": [] },
		}
		// }
		return this.http.post<ScenarioConfigModel>(environment.API_URL + "/scenarios",
			{
				// "sessionIdValue": ScenarioConfig.sessionIdValue,
				// "visiIdValue": ScenarioConfig.visiIdValue,
				// "loginValue": ScenarioConfig.loginValue,
				"longterm_onetime": ScenarioConfig.longterm_onetime,
				"name": ScenarioConfig.titre,
				"parameters":{
					"verbose": 100,
					"metric": "recall",
					"eval_metric": "logloss",
					"n_estimators": 1500,
					"learning_rate": 0.1,
					"early_stopping_rounds": 100,
					"boosting_type": "gbdt",
					"sampling_ratio": 0.1,
					"random_state": 42,
				},
				"description": ScenarioConfig.description,
				"goalCategory": ScenarioConfig.goalCategory,
				"status": false,
				// 'popup_intervals': ScenarioConfig.Pop2,
				"generating": false,
				"Short_longterm": this.Short_longterm,
				'eventsNumber': 10,
				"label": ScenarioConfig.tag,
				"config": this.config,
				"accuracy": {
					"latest_accuracy": "N/A",
					"latest_update": "N/A"
				},
			}, { headers: headers }).pipe(map(res => {
				// if (this.router.url != '/goal/add') {
				// 	this.router.navigate(['/scenario/kpiEdit/' + res.id]);
				// }
				return res;

			}));
	}


	getAllScenarioConfigs(): Observable<ScenarioConfigModel[]> {
		return this.http.get<ScenarioConfigModel[]>(environment.API_URL + '/cars');
	}

	getScenarioConfigById(ScenarioConfigId: number): Observable<ScenarioConfigModel> {
		return this.http.get<ScenarioConfigModel>(API_ScenarioConfig_URL + `/${ScenarioConfigId}`);
	}

	findScenarioConfigs(queryParams: QueryParamsModel): Observable<QueryResultsModel> {

		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		console.log(headers.get("Authorization"));

		return this.http.get<QueryResultsModel>(environment.API_URL + '/scenarios', { headers: headers }).pipe(
			catchError(err => {
				// this._errorMessage.next(err);
				console.error('UPDATE ITEM', err);
				return of(null);
			}),
			finalize(() => { })
		);

	}
	findgoal(x): Observable<any> {

		if (x === 'Last week') {
			let headers = new HttpHeaders();
			headers = headers.append("Content-Type", "application/json");
			return this.http.get(environment.reporting + '/statistics/campaign_details' + '?date_range=' + 'last_week', { headers: headers }).pipe(
				catchError(err => {
					// this._errorMessage.next(err);
					console.error('UPDATE ITEM', err);
					return of(null);
				}),
				finalize(() => { })
			);
		} else
			if (x === 'Last month') {
				let headers = new HttpHeaders();
				headers = headers.append("Content-Type", "application/json");
				return this.http.get(environment.reporting + '/statistics/campaign_details' + '?date_range=' + 'last_month', { headers: headers }).pipe(
					catchError(err => {
						// this._errorMessage.next(err);
						console.error('UPDATE ITEM', err);
						return of(null);
					}),
					finalize(() => { })
				);
			}
			else {
				let headers = new HttpHeaders();
				headers = headers.append("Content-Type", "application/json");
				return this.http.get(environment.reporting + '/statistics/campaign_details', { headers: headers }).pipe(
					catchError(err => {
						// this._errorMessage.next(err);
						console.error('UPDATE ITEM', err);
						return of(null);
					}),
					finalize(() => { })
				);

			}
	}
	getvisitorchart(queryParams: QueryParamsModel, x): Observable<any> {
		if (x === 'Last week') {
			let headers = new HttpHeaders();
			headers = headers.append("Content-Type", "application/json");
			return this.http.get<QueryResultsModel>(environment.reporting + '/statistics/visitor_stats' + '?date_range=' + 'last_week', { headers: headers }).pipe(
				catchError(err => {
					// this._errorMessage.next(err);
					console.error('UPDATE ITEM', err);
					return of(null);
				}),
				finalize(() => { })
			);

		} else
			if (x === 'Last month') {
				let headers = new HttpHeaders();
				headers = headers.append("Content-Type", "application/json");
				return this.http.get<QueryResultsModel>(environment.reporting + '/statistics/visitor_stats' + '?date_range=' + 'last_month', { headers: headers }).pipe(
					catchError(err => {
						// this._errorMessage.next(err);
						console.error('UPDATE ITEM', err);
						return of(null);
					}),
					finalize(() => { })
				);

			}
			else {
				let headers = new HttpHeaders();
				headers = headers.append("Content-Type", "application/json");
				return this.http.get<QueryResultsModel>(environment.reporting + '/statistics/visitor_stats', { headers: headers }).pipe(
					catchError(err => {
						// this._errorMessage.next(err);
						console.error('UPDATE ITEM', err);
						return of(null);
					}),
					finalize(() => { })
				);

			}


	}
	getmulticampaign(queryParams: QueryParamsModel, x,id): Observable<QueryResultsModel> {
		if (x === 'Last week') {
			let headers = new HttpHeaders();
			headers = headers.append("Content-Type", "application/json");
			return this.http.get<QueryResultsModel>(environment.reporting + '/statistics/multicampaign_stats' + '?date_range=' + 'last_week&goal_id='+id, { headers: headers }).pipe(
				catchError(err => {
					// this._errorMessage.next(err);
					console.error('UPDATE ITEM', err);
					return of(null);
				}),
				finalize(() => { })
			);

		} else
			if (x === 'Last month') {
				let headers = new HttpHeaders();
				headers = headers.append("Content-Type", "application/json");
				return this.http.get<QueryResultsModel>(environment.reporting + '/statistics/multicampaign_stats' + '?date_range=' + 'last_month&goal_id='+id, { headers: headers }).pipe(
					catchError(err => {
						// this._errorMessage.next(err);
						console.error('UPDATE ITEM', err);
						return of(null);
					}),
					finalize(() => { })
				);

			}
			else {
				let headers = new HttpHeaders();
				headers = headers.append("Content-Type", "application/json");
				return this.http.get<QueryResultsModel>(environment.reporting + '/statistics/multicampaign_stats?goal_id='+id, { headers: headers }).pipe(
					catchError(err => {
						// this._errorMessage.next(err);
						console.error('UPDATE ITEM', err);
						return of(null);
					}),
					finalize(() => { })
				);

			}


	}
	updateScinario(goalRed: ScenarioConfigModel): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL + '/scenarios/' + goalRed.id, {
			'popup_intervals': goalRed.Pop2,
		}, { headers: headers });
	}
	updateToggle(ScenarioConfig: ScenarioConfigModel): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL + '/scenarios/' + ScenarioConfig.id, {
			"status": ScenarioConfig.status,
		}, { headers: headers });
	}

	updateStatusForScenarioConfig(ScenarioConfigs: ScenarioConfigModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			ScenarioConfigsForUpdate: ScenarioConfigs,
			newStatus: status
		};
		const url = API_ScenarioConfig_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	deleteScenarioConfig(ScenarioConfigId: number): Observable<ScenarioConfigModel> {
		return this.http.delete<ScenarioConfigModel>(environment.API_URL + '/cars' + '/' + ScenarioConfigId);
	}

	deleteScenarioConfigs(ids: number[] = []): Observable<any> {
		const url = API_ScenarioConfig_URL + '/deleteScenarioConfigs';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { ScenarioConfigIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders });
	}

}
