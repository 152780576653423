import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
import { TriggerModel } from '../_models/TriggerModel';
import { TriggerState } from '../_reducers/Trigger.reducers';

export const selectTriggersState = createFeatureSelector<TriggerState>('Triggers');

export const selectTriggerById = (TriggerId: number) => createSelector(
    selectTriggersState,
    TriggerState => TriggerState.entities[TriggerId]
);

export const selectTriggersPageLoading = createSelector(
    selectTriggersState,
    TriggerState => TriggerState.listLoading
);

export const selectTriggersActionLoading = createSelector(
    selectTriggersState,
    TriggerState => TriggerState.actionsloading
);

export const selectLastCreatedTriggerId = createSelector(
    selectTriggersState,
    TriggerState => TriggerState.lastCreatedTriggerId
);

export const selectTriggersShowInitWaitingMessage = createSelector(
    selectTriggersState,
    TriggerState => TriggerState.showInitWaitingMessage
);

export const selectTriggersInStore = createSelector(
    selectTriggersState,
    TriggerState => {
        const items: TriggerModel[] = [];
        each(TriggerState.entities, element => {
            items.push(element);
        });
        return new QueryResultsModel(items, TriggerState.totalCount, '');
    }
);

