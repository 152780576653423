import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { EventCapturedActions, EventCapturedActionTypes } from '../_actions/EventCaptured.actions';
import { EventCapturedModel } from '../_models/EventCapturedModel';
import { QueryParamsModel } from '../../_base/crud';

export interface EventCapturedState extends EntityState<EventCapturedModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedEventCapturedId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}   

export const adapter: EntityAdapter<EventCapturedModel> = createEntityAdapter<EventCapturedModel>({
    selectId: EventConfigModel => EventConfigModel.date_hours+EventConfigModel.type+EventConfigModel.city+EventConfigModel.session_id
  });

export const initialEventCapturedState: EventCapturedState = adapter.getInitialState({
    EventCapturedForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedEventCapturedId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function EventCapturedsReducer(state = initialEventCapturedState, action: EventCapturedActions): EventCapturedState {
    switch  (action.type) {
        case EventCapturedActionTypes.EventCapturedsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedEventCapturedId: undefined
            };
        }    

        case EventCapturedActionTypes.EventCapturedActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }

        case EventCapturedActionTypes.EventCapturedOnServerCreated: return {
            ...state
        };

        case EventCapturedActionTypes.EventCapturedUpdated: return adapter.updateOne(action.payload.partialEventCaptured, state);

        case EventCapturedActionTypes.EventCapturedsStatusUpdated: {
            const _partialEventCaptured: Update<EventCapturedModel>[] = [];
            for (let i = 0; i < action.payload.EventCaptureds.length; i++) {
                _partialEventCaptured.push({
				    id: action.payload.EventCaptureds[i].id,
				    changes: {
                        status: action.payload.status
                    }
			    });
            }
            return adapter.updateMany(_partialEventCaptured, state);
        }

        case EventCapturedActionTypes.OneEventCapturedDeleted: return adapter.removeOne(action.payload.id, state);

        case EventCapturedActionTypes.EventCapturedsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }

        case EventCapturedActionTypes.EventCapturedsPageLoaded: {
            return adapter.addMany(action.payload.EventCaptureds, {
                ...initialEventCapturedState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}



export const getEventCapturedState = createFeatureSelector<EventCapturedModel>('EventCaptureds');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
