// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { RecordModel } from '../_models/RecordModel';

export enum RecordActionTypes {
    RecordOnServerCreated = '[Edit Record Dialog] Record On Server Created',
    RecordCreated = '[Edit Record Dialog] Record Created',
    RecordUpdated = '[Edit Record Dialog] Record Updated',
    RecordUpdated2 = '[Edit Record Dialog] Record Updated2',
    RecordsStatusUpdated = '[Records List Page] Records Status Updated',
    OneRecordDeleted = '[Records List Page] One Record Deleted',

    RecordsPageRequested = '[Records List Page] Records Page Requested',
    RecordsPageLoaded = '[Records API] Records Page Loaded',
    BqRecordsPageRequested = '[BqRecords List Page] BqRecords Page Requested',
    BqRecordsPageLoaded = '[BqRecords API] BqRecords Page Loaded',

    ScoringRecordPageRequested = '[ScoringRecord List Page] ScoringRecord Page Requested',
    ScoringRecordPageLoaded = '[ScoringRecord API] ScoringRecord Page Loaded',
    TriggerPageRequested = '[ScoringRecord List Page] Trigger Page Requested',
    TriggerPageLoaded = '[ScoringRecord API] Trigger Page Loaded',
    RecordsPageCancelled = '[Records API] Records Page Cancelled',
    RecordsPageToggleLoading = '[Records] Records Page Toggle Loading',
    RecordActionToggleLoading = '[Records] Records Action Toggle Loading'
}

export class RecordOnServerCreated implements Action {
    readonly type = RecordActionTypes.RecordOnServerCreated;
    constructor(public payload: { Record: RecordModel }) { }
}

export class RecordCreated implements Action {
    readonly type = RecordActionTypes.RecordCreated;
    constructor(public payload: { Record: RecordModel }) { }
}

export class RecordUpdated implements Action {
    readonly type = RecordActionTypes.RecordUpdated;
    constructor(public payload: {
        partialRecord: Update<RecordModel>, 
        Record: RecordModel 
    }) { }
}
export class RecordUpdated2 implements Action {
    readonly type = RecordActionTypes.RecordUpdated2;
    constructor(public payload: {
        partialRecord: Update<any>, 
        Record: any ,
        type:string
    }) { }
}


export class RecordsStatusUpdated implements Action {
    readonly type = RecordActionTypes.RecordsStatusUpdated;
    constructor(public  payload: {
        Records: RecordModel[],
        status: number
    }) { }
}

export class OneRecordDeleted implements Action {
    readonly type = RecordActionTypes.OneRecordDeleted;
    constructor(public payload: { id: number }) {}
}


export class RecordsPageRequested implements Action {
    readonly type = RecordActionTypes.RecordsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class RecordsPageLoaded implements Action {
    readonly type = RecordActionTypes.RecordsPageLoaded;
    constructor(public payload :any ) { }
}

export class BqRecordsPageRequested implements Action {
    readonly type = RecordActionTypes.BqRecordsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class BqRecordsPageLoaded implements Action {
    readonly type = RecordActionTypes.BqRecordsPageLoaded;
    constructor(public payload :any ) { }
}
export class ScoringRecordPageRequested implements Action {
    readonly type = RecordActionTypes.ScoringRecordPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class ScoringRecordPageLoaded implements Action {
    readonly type = RecordActionTypes.ScoringRecordPageLoaded;
    constructor(public payload :any ) { }
}

export class TriggerPageRequested implements Action {
    readonly type = RecordActionTypes.TriggerPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class TriggerPageLoaded implements Action {
    readonly type = RecordActionTypes.TriggerPageLoaded;
    constructor(public payload :any ) { }
}
export class RecordsPageCancelled implements Action {
    readonly type = RecordActionTypes.RecordsPageCancelled;
}

export class RecordsPageToggleLoading implements Action {
    readonly type = RecordActionTypes.RecordsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class RecordActionToggleLoading implements Action {
    readonly type = RecordActionTypes.RecordActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type RecordActions = RecordOnServerCreated
| RecordCreated
| RecordUpdated
| RecordUpdated2
| RecordsStatusUpdated
| OneRecordDeleted
| RecordsPageRequested
| RecordsPageLoaded
| BqRecordsPageRequested
| BqRecordsPageLoaded
| ScoringRecordPageRequested
| ScoringRecordPageLoaded
| TriggerPageRequested
| TriggerPageLoaded
| RecordsPageCancelled
| RecordsPageToggleLoading
| RecordActionToggleLoading;
