import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SetupService {

  constructor(public http: HttpClient, ) { }
  getstup() {
    return this.http.get(environment.API_URL+'/enterprises/get_token', {
      headers: new HttpHeaders(),
      responseType: 'text'
    }
    ).pipe(map(
      res => {
        return res;
      }
    ))
  }
  get() {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.API_URL+'/users/user_claims', { headers: headers });
	}
}
