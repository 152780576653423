import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, map, tap, delay } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryResultsModel } from '../../_base/crud';
import { EventConfigsService } from '../_services';
import { AppState } from '../../reducers';
import {

    EventConfigActionToggleLoading,
    EventConfigActionTypes,
    EventConfigsPageLoaded,
    EventConfigsPageRequested,
    EventConfigsPageToggleLoading,
    OneEventConfigDeleted,
    EventConfigOnServerCreated,
    EventConfigCreated,
    EventConfigUpdated,
    AllEventConfigDeleted

} from '../_actions/EventConfig.actions';
import { of } from 'rxjs';

@Injectable()
export class EventConfigEffects {
    showPageLoadingDistpatcher = new EventConfigsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new EventConfigActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new EventConfigActionToggleLoading({ isLoading: false });

    @Effect()
    loadEventConfigsPage$ = this.actions$.pipe(
        ofType<EventConfigsPageRequested>(EventConfigActionTypes.EventConfigsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.EventConfigsService.findEventConfigs(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new EventConfigsPageLoaded({
                EventConfigs: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );
    @Effect()
    createScenarioConfig$ = this.actions$
        .pipe(
            ofType<EventConfigOnServerCreated>(EventConfigActionTypes.EventConfigOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.EventConfigsService.createScenarioConfig(payload.EventConfig).pipe(
                    tap(res => {
                        this.store.dispatch(new EventConfigCreated({ EventConfig: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );
    @Effect()
    deleteCustomer$ = this.actions$
        .pipe(
            ofType<OneEventConfigDeleted>(EventConfigActionTypes.OneEventConfigDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.EventConfigsService.deleteEventConfig(payload.indicateur,payload.type);
                }
            ),
            map(() => { 
                return this.hideActionLoadingDistpatcher;
            }),
        );

        
        @Effect()
        updateToggel$ = this.actions$
            .pipe(
                ofType<EventConfigUpdated>(EventConfigActionTypes.EventConfigUpdated),
                mergeMap(( { payload } ) => {                        
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.EventConfigsService.updateEventConfig(payload.EventConfig,payload.id);
                }),
                map(() => {
                    return this.hideActionLoadingDistpatcher;
                })
            );

            @Effect()
            deleteAllCustomer$ = this.actions$
                .pipe(
                    ofType<AllEventConfigDeleted>(EventConfigActionTypes.AllEventConfigDeleted),
                    mergeMap(( { payload } ) => {
                            this.store.dispatch(this.showActionLoadingDistpatcher);
                            return this.EventConfigsService.deleteAllEventConfig();
                        }
                    ),
                    map(() => {
                        return this.hideActionLoadingDistpatcher;
                    }),
                );

    constructor(private actions$: Actions, private EventConfigsService: EventConfigsService, private store: Store<AppState>) { }

}
