export const environment = {
    production: true,
    API_URL: 'https://console-back-notapvifoq-od.a.run.app',
    preprocessing:'https://preprocessing-notapvifoq-od.a.run.app',
    reporting:'https://sm-reporting-notapvifoq-od.a.run.app',
    hmr: false,
    firebaseConfig: {
      apiKey: "AIzaSyASShwUSGsIJ5d-SMDsxJdcDbNE71gIOr0",	
      authDomain: "smarkettest.firebaseapp.com",
      projectId: "smarkettest",
      storageBucket: "smarkettest.appspot.com",
      messagingSenderId: "972340343543",
      appId: "1:972340343543:web:300d78686e52f829abd9f2"
    }
  };
  export const environment1 = {
    production: false,
    isMockEnabled: false, // You have to switch this, when your real back-end is done
    authTokenKey: 'authce9d77b308c149d5992a80073637e4d5'
  };
  
  
  
  