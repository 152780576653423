import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, map, tap, delay } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryRealtimeParamsModel } from '../../_base/crud';
import { KpisService } from '../_services';
import { AppState } from '../../reducers';
import {

    KpiaddActionToggleLoading,
    KpiaddActionTypes,
    KpiaddsPageLoaded,
    KpiaddsPageRequested,
    KpiaddsPageToggleLoading,
    OneKpiaddDeleted,
    KpiaddOnServerCreated,
    KpiaddCreated,

} from '../_actions/kpiadd.actions';
import { of } from 'rxjs';

@Injectable()
export class KpiaddEffects {
    showPageLoadingDistpatcher = new KpiaddsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new KpiaddActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new KpiaddActionToggleLoading({ isLoading: false });

    @Effect()
    createKpiadd$ = this.actions$
        .pipe(
            ofType<KpiaddOnServerCreated>(KpiaddActionTypes.KpiaddOnServerCreated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.KpisService.createKpi2(payload.id, payload.type).pipe(
                    tap(res => {
                        console.log(res);
                        this.store.dispatch(new KpiaddCreated({ Kpiadd:res  }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );
    

    constructor(private actions$: Actions, private KpisService: KpisService, private store: Store<AppState>) { }

}
