import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { DetailsActions, DetailsActionTypes } from '../_actions/details.actions';
import { DetailsModel } from '../_models/detailsModel';
import { QueryParamsModel } from '../../_base/crud';

export interface DetailsState extends EntityState<DetailsModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedDetailsId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<DetailsModel> = createEntityAdapter<DetailsModel>({
    selectId: EventConfigModel => EventConfigModel.date
  });
  
export const initialDetailsState: DetailsState = adapter.getInitialState({
    DetailsForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedDetailsId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function DetailssReducer(state = initialDetailsState, action: DetailsActions): DetailsState {
    switch  (action.type) {
        case DetailsActionTypes.DetailssPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedDetailsId: undefined
            };
        }    

        case DetailsActionTypes.DetailsActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }

        case DetailsActionTypes.DetailsOnServerCreated: return {
            ...state
        };

        case DetailsActionTypes.DetailsUpdated: return adapter.updateOne(action.payload.partialDetails, state);

        // case DetailsActionTypes.DetailssStatusUpdated: {
        //     const _partialDetails: Update<DetailsModel>[] = [];
        //     for (let i = 0; i < action.payload.Detailss.length; i++) {
        //         _partialDetails.push({
		// 		    id: action.payload.Detailss[i].id,
		// 		    changes: {
        //                 status: action.payload.status
        //             }
		// 	    });
        //     }
        //     return adapter.updateMany(_partialDetails, state);
        // }

        case DetailsActionTypes.OneDetailsDeleted: return adapter.removeOne(action.payload.id, state);

        case DetailsActionTypes.DetailssPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }

        case DetailsActionTypes.DetailssPageLoaded: {
            return adapter.addMany(action.payload.Detailss, {
                ...initialDetailsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}



export const getDetailsState = createFeatureSelector<DetailsModel>('Detailss');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
