import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryRealtimeParamsModel, HttpExtenstionsModel } from '../../_base/crud';
import { KpiState } from '../_reducers/kpi.reducers';
import { KpiModel } from '../_models/kpiModel';

export const selectKpisState = createFeatureSelector<KpiState>('Kpis');

export const selectKpiById = (KpiId: number) => createSelector(
    selectKpisState,
    KpiState => KpiState.entities[KpiId]
);

export const selectKpisPageLoading = createSelector(
    selectKpisState,
    KpiState => KpiState.listLoading
);

export const selectKpisActionLoading = createSelector(
    selectKpisState,
    KpiState => KpiState.actionsloading
);

export const selectLastCreatedKpiId = createSelector(
    selectKpisState,
    KpiState => KpiState.lastCreatedKpiId
);

export const selectKpisShowInitWaitingMessage = createSelector(
    selectKpisState,
    KpiState => KpiState.showInitWaitingMessage
);

export const selectKpisInStore = createSelector(
    selectKpisState,
    KpiState => {
        const items: KpiModel[] = [];
        each(KpiState.entities, element => {
            items.push(element);
        });        
        const httpExtension = new HttpExtenstionsModel();
        const result: KpiModel[] = httpExtension.sortArray(items, KpiState.lastQuery.sortField, KpiState.lastQuery.sortOrder);
        return new QueryRealtimeParamsModel(result);
    }
);

