// Models and Consts
export { goalRedModel } from './_models/goalRedModel';


// DataSources
export { goalRedsDataSource } from './_data-sources/goalRed.datasource';

export { DetailGsDataSource } from './_data-sources/DetailG.datasource';

// Actions
// goalRed Actions =>
export {
goalRedActionToggleLoading,
goalRedActionTypes,
goalRedsPageCancelled,
goalRedsPageLoaded,
goalRedsPageRequested,
goalRedsPageToggleLoading,
goalRedsStatusUpdated,
OnegoalRedDeleted,
goalRedOnServerCreated,
goalRedCreated,
goalRedUpdated
} from './_actions/goalRed.actions';

export {
    DetailGActionToggleLoading,
    DetailGActionTypes,
    DetailGsPageCancelled,
    DetailGsPageLoaded,
    DetailGsPageRequested,
    DetailGsPageToggleLoading,
    DetailGsStatusUpdated,
    OneDetailGDeleted,
    DetailGOnServerCreated,
    DetailGCreated,
    DetailGUpdated
    } from './_actions/DetailG.actions';
// Effects
export { goalRedEffects } from './_effects/goalRed.effect';

export { DetailGEffects } from './_effects/DetailG.effect';

// Reducers
export { goalRedsReducer } from './_reducers/goalRed.reducers';

export { DetailGsReducer } from './_reducers/DetailG.reducers';

// Selectors
// goalRed selectors =>
export {
    selectgoalRedById,
    selectgoalRedsInStore,
    selectgoalRedsShowInitWaitingMessage,
    selectLastCreatedgoalRedId,
    selectgoalRedsActionLoading,
    selectgoalRedsPageLoading
} from './_selectors/goalRed.selectors';

export {
    selectDetailGById,
    selectDetailGsInStore,
    selectDetailGsShowInitWaitingMessage,
    selectLastCreatedDetailGId,
    selectDetailGsActionLoading,
    selectDetailGsPageLoading
} from './_selectors/DetailG.selectors';


// Services
export { goalRedsService } from './_services/';

export { MomentDateFormatter } from './_services/';
