import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { EventConfigActions, EventConfigActionTypes } from '../_actions/EventConfig.actions';
import { EventConfigModel } from '../_models/EventConfigModel';
import { QueryParamsModel } from '../../_base/crud';

export interface EventConfigState extends EntityState<EventConfigModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedEventConfigId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}



export const adapter: EntityAdapter<EventConfigModel> = createEntityAdapter<EventConfigModel>({
    selectId: EventConfigModel => EventConfigModel.indicateur+EventConfigModel.tags
  });

export const initialEventConfigState: EventConfigState = adapter.getInitialState({
    EventConfigForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedEventConfigId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function EventConfigsReducer(state = initialEventConfigState, action: EventConfigActions): EventConfigState {
    switch  (action.type) {
        case EventConfigActionTypes.EventConfigsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedEventConfigId: undefined
            };
        }    

        case EventConfigActionTypes.EventConfigActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }

        case EventConfigActionTypes.EventConfigOnServerCreated: return {
            ...state
        };

        case EventConfigActionTypes.EventConfigUpdated: return adapter.updateOne(action.payload.partialEventConfig, state);

        // case EventConfigActionTypes.EventConfigsStatusUpdated: {
        //     const _partialEventConfig: Update<EventConfigModel>[] = [];
        //     for (let i = 0; i < action.payload.EventConfigs.length; i++) {
        //         _partialEventConfig.push({
		// 		    id: action.payload.EventConfigs[i].id,
		// 		    changes: {
        //                 status: action.payload.status
        //             }
		// 	    });
        //     }
        //     return adapter.updateMany(_partialEventConfig, state);
        // }

        case EventConfigActionTypes.OneEventConfigDeleted: return adapter.removeOne(action.payload.indicateur, state);

        case EventConfigActionTypes.AllEventConfigDeleted: return adapter.removeAll(state);

        case EventConfigActionTypes.EventConfigsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }

        case EventConfigActionTypes.EventConfigsPageLoaded: {
            return adapter.addMany(action.payload.EventConfigs, {
                ...initialEventConfigState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}



export const getEventConfigState = createFeatureSelector<EventConfigModel>('EventConfigs');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
