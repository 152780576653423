import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { scoringRecordModel } from '../_models/scoringRecordModel';
import * as firebase from 'firebase';
import { environment } from '../../../../environments/environment';

const API_scoringRecord_URL = 'api/scoringRecords';

@Injectable()
export class scoringRecordsService {
	token: string;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	createscoringRecord(scoringRecord: scoringRecordModel): Observable<scoringRecordModel> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post<scoringRecordModel>(environment.API_URL+"/scoringRecords", scoringRecord, { headers: headers });
	}

	getAllscoringRecords(): Observable<scoringRecordModel[]> {
		return this.http.get<scoringRecordModel[]>(environment.API_URL+'/cars');
	}

	getscoringRecordById(scoringRecordId: number): Observable<scoringRecordModel> {
		return this.http.get<scoringRecordModel>(API_scoringRecord_URL + `/${scoringRecordId}`);
	}

	findscoringRecords(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get<QueryResultsModel>(environment.API_URL+'/enterprises/db_status', { headers: headers });
	}

	updatescoringRecord(scoringRecord: scoringRecordModel): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post(environment.API_URL+'/enterprises/db_status?db='
			+ scoringRecord.db + '&status=' + scoringRecord.status, { option: false }, { headers: headers });
	}
	updatescoringRecord2(scoringRecord: any, type: string): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		if (type === "score") {
			return this.http.post(environment.API_URL+'/enterprises/db_status?calculate_score='
				+ scoringRecord,  { headers: headers });
		}else if(type === "toggle"){
			return this.http.post(environment.API_URL+'/enterprises/db_status?trigger_action='
				+ scoringRecord,  { headers: headers });
		}

	}


	updateStatusForscoringRecord(scoringRecords: scoringRecordModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			scoringRecordsForUpdate: scoringRecords,
			newStatus: status
		};
		const url = API_scoringRecord_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	deletescoringRecord(scoringRecordId: number): Observable<scoringRecordModel> {
		return this.http.delete<scoringRecordModel>(environment.API_URL+'/cars' + '/' + scoringRecordId);
	}

	deletescoringRecords(ids: number[] = []): Observable<any> {
		const url = API_scoringRecord_URL + '/deletescoringRecords';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { scoringRecordIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders });
	}
}
