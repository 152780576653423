import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, map, tap, delay } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryRealtimeParamsModel } from '../../_base/crud';
import { goalRedsService } from '../_services';
import { AppState } from '../../reducers';
import {

    DetailGActionToggleLoading,
    DetailGActionTypes,
    DetailGsPageLoaded,
    DetailGsPageRequested,
    DetailGsPageToggleLoading,
    OneDetailGDeleted,
    DetailGUpdated

} from '../_actions/DetailG.actions';
import { of } from 'rxjs';

@Injectable()
export class DetailGEffects {
    showPageLoadingDistpatcher = new DetailGsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new DetailGActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new DetailGActionToggleLoading({ isLoading: false });
    @Effect()
    loadDetailGsPage$ = this.actions$.pipe(
        ofType<DetailGsPageRequested>(DetailGActionTypes.DetailGsPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            return this.DetailGsService.findDetailGs(payload.list);

        }),
        map(response => {
            const result: QueryRealtimeParamsModel = response;
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new DetailGsPageLoaded({
                DetailGs: result
            });
            return pageLoadedDispatch;
        })
    );
    @Effect()
    updateToggel$ = this.actions$
        .pipe(
            ofType<DetailGUpdated>(DetailGActionTypes.DetailGUpdated),
            mergeMap(( { payload } ) => {                        
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.DetailGsService.updateGoal(payload.DetailG);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

  
    constructor(private actions$: Actions, private DetailGsService: goalRedsService, private store: Store<AppState>) { }

}
