import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatIconModule, MatPaginatorModule, MatProgressSpinnerModule, MatSortModule, MatTableModule, } from '@angular/material';
import { CoreModule } from '../../../../core/core.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// Datatable
import { DataTableComponent } from './general/data-table/data-table.component';
// General widgets
import { Widget1Component } from './widget1/widget1.component';
import { Widget4Component } from './widget4/widget4.component';
import { Widget5Component } from './widget5/widget5.component';
import { Widget12Component } from './widget12/widget12.component';
import { Widget14Component } from './widget14/widget14.component';
import { Widget26Component } from './widget26/widget26.component';
import { Timeline2Component } from './timeline2/timeline2.component';
import {
	goalRedsReducer,
	goalRedEffects,
	MomentDateFormatter,
	goalRedsService,
} from '../../../../../app/core/goal';
import { NgApexchartsModule } from "ng-apexcharts";
import { ChartscinariodashbprdComponent } from './chartscinariodashbprd/chartscinariodashbprd.component';
import { Chartscinariodashbprd2Component } from './chartscinariodashbprd2/chartscinariodashbprd2.component';
import { VisitordashbordComponent } from './visitordashbord/visitordashbord.component';
import { VisitoreventdashbordComponent } from './visitoreventdashbord/visitoreventdashbord.component';
import { GeberaledashbordComponent } from './geberaledashbord/geberaledashbord.component';
import { PopbygoalComponent } from './popbygoal/popbygoal.component';
import { CerclechartComponent } from './cerclechart/cerclechart.component';
@NgModule({
	declarations: [
		DataTableComponent,
		// Widgets
		Widget1Component,
		Widget4Component,
		Widget5Component,
		Widget12Component,
		Widget14Component,
		Widget26Component,
		Timeline2Component,
		ChartscinariodashbprdComponent,
		Chartscinariodashbprd2Component,
		VisitordashbordComponent,
		VisitoreventdashbordComponent,
		GeberaledashbordComponent,
		PopbygoalComponent,
		CerclechartComponent,
	],
	providers: [
		goalRedsService,
	],
	exports: [
		DataTableComponent,
		// Widgets
		Chartscinariodashbprd2Component,
		Widget1Component,
		GeberaledashbordComponent,
		VisitordashbordComponent,
		ChartscinariodashbprdComponent,
		Widget4Component,
		VisitoreventdashbordComponent,
		Widget5Component,
		Widget12Component,
		Widget14Component,
		Widget26Component,
		Timeline2Component,
		CerclechartComponent,
		PopbygoalComponent
	],
	entryComponents: [
		Widget14Component,
		Widget5Component,
		PopbygoalComponent,
		CerclechartComponent
	],
	imports: [
		CommonModule,
		PerfectScrollbarModule,
		MatTableModule,
		CoreModule,
		MatIconModule,
		NgApexchartsModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		MatPaginatorModule,
		MatSortModule,
	]
})
export class WidgetModule {
}
