import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { EventConfigModel } from '../_models/EventConfigModel';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_EventConfig_URL = 'api/EventConfigs';

@Injectable()
export class EventConfigsService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }
	// createEventConfig(EventConfig: EventConfigModel): Observable<EventConfigModel> {
	// 	const httpHeaders = this.httpUtils.getHTTPHeaders();
	// 	return this.http.post<EventConfigModel>(API_EventConfig_URL, EventConfig, { headers: httpHeaders});
	// }
	createScenarioConfig(config: any): Observable<EventConfigModel> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post<EventConfigModel>(environment.API_URL+'/enterprises/filtered_events', config, { headers: headers }).pipe(map(res => {
			return res;
		}));
	}
	getAllEventConfigs(): Observable<EventConfigModel[]> {
		return this.http.get<EventConfigModel[]>(environment.API_URL+'/cars');
	}

	getEventConfigById(EventConfigId: number): Observable<EventConfigModel> {
		return this.http.get<EventConfigModel>(API_EventConfig_URL + `/${EventConfigId}`);
	}

	findEventConfigs(queryParams: QueryParamsModel): Observable<QueryResultsModel> {

		let headers = new HttpHeaders();  
		headers = headers.append("Content-Type", "application/json");
		return this.http.get<QueryResultsModel>(environment.API_URL+'/enterprises/filtered_events', { headers: headers });
	}

	updateEventConfig(EventConfig: EventConfigModel,id): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL+'/enterprises/filtered_events/'+ id,
			EventConfig ,{ headers: headers });
	} 

	updateStatusForEventConfig(EventConfigs: EventConfigModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			EventConfigsForUpdate: EventConfigs,
			newStatus: status
		};
		const url = API_EventConfig_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	deleteEventConfig(EventConfigId: string, type: string): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.delete(environment.API_URL+'/enterprises/filtered_events/'+ EventConfigId);

	}
	deleteAllEventConfig(): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.API_URL+'/enterprises/events/purge', { headers: headers }).pipe(map(res => {
			return res;
		}));

	}


}
