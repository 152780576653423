
// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaderResponse, HttpSentEvent, HttpProgressEvent, HttpUserEvent } from '@angular/common/http';
// RxJS
import * as firebase from 'firebase';
import { environment1 } from '../../../../../environments/environment';
import { AuthService } from '../../../auth/_services/auth.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Router } from '@angular/router';
import { timer, Observable, throwError } from 'rxjs';
import { windowTime, tap, mergeAll, catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';


/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
	token: string;

	constructor(public authService: AuthService, public router: Router) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | Observable<any> {
		console.log(this.router.url);
		
		if (this.router.url == '/auth/register') {
			console.log("eeeeeeeeeeeeeeeeeeeee");
			
			environment1.authTokenKey = "";
		}
		if(req.url.includes('https://api.unlayer.com/')){
			req = req.clone({ 
				setHeaders: {
					Authorization: 'Basic dno0TXJqQlVmS3dZU09nVnh3VFg1MGFlRGt1T29HV0lKcDZWanhCT0pJYktsSE52VUdybVlkbW5jd2p3Z3VacTo='
				}
			});
		}
		else{
			if (this.router.url !== '/auth/register') {

				req = req.clone({ 
					setHeaders: {
						Authorization: this.authService.refreshToken()
					}
				});
			}
	
		}
		return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {

			if (error.status === 403) {
				req = req.clone({
					setHeaders: {
						Authorization: this.authService.refreshToken()
					}
				});
				return next.handle(req)
					.pipe(
						catchError((error) => {
							return throwError(error);
						})
					);

			} else {

				req = req.clone({
					setHeaders: {
						Authorization: this.authService.refreshToken()
					}
				});
			}
			return throwError(error);
		}));
	}
}





