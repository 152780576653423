import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { KpiActions, KpiActionTypes } from '../_actions/kpi.actions';
import { KpiModel } from '../_models/kpiModel';
import { QueryParamsModel } from '../../_base/crud';

export interface KpiState extends EntityState<KpiModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedKpiId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<KpiModel> = createEntityAdapter<KpiModel>({
    selectId: KpiModel => KpiModel.name
  });

export const initialKpiState: KpiState = adapter.getInitialState({
    KpiForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedKpiId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function KpisReducer(state = initialKpiState, action: KpiActions): KpiState {
    switch  (action.type) {
        case KpiActionTypes.KpisPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedKpiId: undefined
            };
        }    
 
        case KpiActionTypes.KpiActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }

        case KpiActionTypes.KpiOnServerCreated: return {
            ...state
        };

        case KpiActionTypes.KpiUpdated: return adapter.updateOne(action.payload.partialKpi, state);

        case KpiActionTypes.OneKpiDeleted: return adapter.removeOne(action.payload.id, state);

        case KpiActionTypes.KpisPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }

        case KpiActionTypes.KpisPageLoaded: {
            return adapter.addOne(action.payload.Kpis, {
                ...initialKpiState,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}



export const getKpiState = createFeatureSelector<KpiModel>('Kpis');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
