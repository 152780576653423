import { Component, HostListener, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../core/auth/_services/auth.service';
@Component({
  selector: 'kt-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  user: any;
  form: FormGroup;
  _formBuilder: any;
  constructor(private fb: FormBuilder,
    private auth: AuthService,
  ) { }
	public window = window.innerWidth;
	//get window width on any window size change
	@HostListener('window:resize')
	onWindowResize() {
		this.window = window.innerWidth;
		// console.log('Window inner width:', this.window);
	}
  ngOnInit() {
    this.form = this.fb.group({
      phone_number: ['', Validators.compose([
        Validators.minLength(8),
      ])
      ],
      email: ['', Validators.compose([
        Validators.required,
        Validators.email,
        Validators.minLength(3),
        Validators.maxLength(320)
      ]),
      ],
      display_name: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ],
      ),
      ],
      picture: ['', Validators.compose([
        Validators.required,
      ]),]
    });
    this.auth.get().subscribe((res: any) => {

      this.user = res.user_data;
    });
  }
  
  update() {
    this.auth.updateProfile(this.form.value).then(res => {
      firebase.auth().updateCurrentUser(this.user).then(list => {
      }

      )
    }).catch(function (error) {
    });



  }

}
