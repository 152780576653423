import { BaseModel } from '../../_base/crud';

export class DetailsModel extends BaseModel {
  id: number;
  Detailsname?: string;
  accurancy: string;
  scenario: string;
  createdDate:Date;
  date:string;
  type:string;
  activate:boolean;
  clear() {
    this.id = 0;
    this.Detailsname = '';
    this.accurancy = '';
    this.scenario = '';
    this.type='';
  }
}