import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { BqRecordsModel } from '../_models/BqRecordsModel';
import * as firebase from 'firebase';
import { environment } from '../../../../environments/environment';

const API_BqRecord_URL = 'api/BqRecords';

@Injectable()
export class BqRecordsService {
	token: string;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	createBqRecord(BqRecord: BqRecordsModel): Observable<BqRecordsModel> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post<BqRecordsModel>(environment.API_URL+"/BqRecords", BqRecord, { headers: headers });
	}

	getAllBqRecords(): Observable<BqRecordsModel[]> {
		return this.http.get<BqRecordsModel[]>(environment.API_URL+'/cars');
	}

	getBqRecordById(BqRecordId: number): Observable<BqRecordsModel> {
		return this.http.get<BqRecordsModel>(API_BqRecord_URL + `/${BqRecordId}`);
	}

	findBqRecords(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get<QueryResultsModel>(environment.API_URL+'/enterprises/db_status', { headers: headers });
	}

	updateBqRecord(BqRecord: BqRecordsModel): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post(environment.API_URL+'/enterprises/db_status?db='
			+ BqRecord.db + '&status=' + BqRecord.status, { option: false }, { headers: headers });
	}
	updateBqRecord2(BqRecord: any, type: string): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		if (type === "score") {
			return this.http.post(environment.API_URL+'/enterprises/db_status?calculate_score='
				+ BqRecord,  { headers: headers });
		}else if(type === "toggle"){
			return this.http.post(environment.API_URL+'/enterprises/db_status?trigger_action='
				+ BqRecord,  { headers: headers });
		}

	}


	updateStatusForBqRecord(BqRecords: BqRecordsModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			BqRecordsForUpdate: BqRecords,
			newStatus: status
		};
		const url = API_BqRecord_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	deleteBqRecord(BqRecordId: number): Observable<BqRecordsModel> {
		return this.http.delete<BqRecordsModel>(environment.API_URL+'/cars' + '/' + BqRecordId);
	}

	deleteBqRecords(ids: number[] = []): Observable<any> {
		const url = API_BqRecord_URL + '/deleteBqRecords';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { BqRecordIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders });
	}
}
