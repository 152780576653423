// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { KpiModel } from '../_models/KpiModel';

export enum KpiaddActionTypes {
    KpiaddOnServerCreated = '[Edit Kpiadd Dialog] Kpiadd On Server Created',
    KpiaddCreated = '[Edit Kpiadd Dialog] Kpiadd Created',
    KpiaddUpdated = '[Edit Kpiadd Dialog] Kpiadd Updated',
    KpiaddsStatusUpdated = '[Kpiadds List Page] Kpiadds Status Updated',
    OneKpiaddDeleted = '[Kpiadds List Page] One Kpiadd Deleted',

    KpiaddsPageRequested = '[Kpiadds List Page] Kpiadds Page Requested',
    KpiaddsPageLoaded = '[Kpiadds API] Kpiadds Page Loaded',


    KpiaddsPageCancelled = '[Kpiadds API] Kpiadds Page Cancelled',
    KpiaddsPageToggleLoading = '[Kpiadds] Kpiadds Page Toggle Loading',
    KpiaddActionToggleLoading = '[Kpiadds] Kpiadds Action Toggle Loading'
}

export class KpiaddOnServerCreated implements Action {
    readonly type = KpiaddActionTypes.KpiaddOnServerCreated;
    constructor(public payload: { id:string, type: any[] }) { }
}

export class KpiaddCreated implements Action {
    readonly type = KpiaddActionTypes.KpiaddCreated;
    constructor(public payload: { Kpiadd: KpiModel }) { }
}

export class KpiaddUpdated implements Action {
    readonly type = KpiaddActionTypes.KpiaddUpdated;
    constructor(public payload: {
        partialKpiadd: Update<KpiModel>, 
        Kpiadd: KpiModel 
    }) { }
}

export class KpiaddsStatusUpdated implements Action {
    readonly type = KpiaddActionTypes.KpiaddsStatusUpdated;
    constructor(public  payload: {
        Kpiadds: KpiModel[],
        status: number
    }) { }
}

export class OneKpiaddDeleted implements Action {
    readonly type = KpiaddActionTypes.OneKpiaddDeleted;
    constructor(public payload: { id: number }) {}
}


export class KpiaddsPageRequested implements Action {
    readonly type = KpiaddActionTypes.KpiaddsPageRequested;
    constructor(public payload: { list: any }) { }
}

export class KpiaddsPageLoaded implements Action {
    readonly type = KpiaddActionTypes.KpiaddsPageLoaded;
    constructor(public payload: any) { }
}

export class KpiaddsPageCancelled implements Action {
    readonly type = KpiaddActionTypes.KpiaddsPageCancelled;
}

export class KpiaddsPageToggleLoading implements Action {
    readonly type = KpiaddActionTypes.KpiaddsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class KpiaddActionToggleLoading implements Action {
    readonly type = KpiaddActionTypes.KpiaddActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type KpiaddActions = KpiaddOnServerCreated
| KpiaddCreated
| KpiaddUpdated
| KpiaddsStatusUpdated
| OneKpiaddDeleted
| KpiaddsPageRequested
| KpiaddsPageLoaded
| KpiaddsPageCancelled
| KpiaddsPageToggleLoading
| KpiaddActionToggleLoading
