import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
import { DetailsModel } from '../_models/detailsModel';
import { DetailsState } from '../_reducers/details.reducers';

export const selectDetailssState = createFeatureSelector<DetailsState>('Detailss');

export const selectDetailsById = (DetailsId: number) => createSelector(
    selectDetailssState,
    DetailsState => DetailsState.entities[DetailsId]
);

export const selectDetailssPageLoading = createSelector(
    selectDetailssState,
    DetailsState => DetailsState.listLoading
);

export const selectDetailssActionLoading = createSelector(
    selectDetailssState,
    DetailsState => DetailsState.actionsloading
);

export const selectLastCreatedDetailsId = createSelector(
    selectDetailssState,
    DetailsState => DetailsState.lastCreatedDetailsId
);

export const selectDetailssShowInitWaitingMessage = createSelector(
    selectDetailssState,
    DetailsState => DetailsState.showInitWaitingMessage
);

export const selectDetailssInStore = createSelector(
    selectDetailssState,
    DetailsState => {
        const items: DetailsModel[] = [];
        each(DetailsState.entities, element => {
            items.push(element);
        });
        return new QueryResultsModel(items, DetailsState.totalCount, '');
    }
);

