import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { BqRecordsActions, BqRecordsActionTypes } from '../_actions/BqRecords.actions';
import { BqRecordsModel } from '../_models/BqRecordsModel';
import { QueryParamsModel } from '../../_base/crud';

export interface BqRecordState extends EntityState<BqRecordsModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedBqRecordId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<BqRecordsModel> = createEntityAdapter<BqRecordsModel>();

export const initialBqRecordState: BqRecordState = adapter.getInitialState({
    BqRecordForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedBqRecordId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function BqRecordsReducer(state = initialBqRecordState, action: BqRecordsActions): BqRecordState {
    switch  (action.type) {
        case BqRecordsActionTypes.BqRecordsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedBqRecordId: undefined
            };
        }    

        case BqRecordsActionTypes.BqRecordsActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }

        case BqRecordsActionTypes.BqRecordsOnServerCreated: return {
            ...state
        };

        case BqRecordsActionTypes.BqRecordsUpdated: return adapter.updateOne(action.payload.partialBqRecords, state);
        case BqRecordsActionTypes.BqRecordsUpdated2: return adapter.updateOne(action.payload.partialBqRecords, state);

        // case BqRecordsActionTypes.BqRecordsStatusUpdated: {
        //     const _partialBqRecord: Update<BqRecordModel>[] = [];
        //     for (let i = 0; i < action.payload.BqRecords.length; i++) {
        //         _partialBqRecord.push({
		// 		    id: action.payload.BqRecords[i].id,
		// 		    changes: {
        //                 status: action.payload.status
        //             }
		// 	    });
        //     }
        //     return adapter.updateMany(_partialBqRecord, state);
        // }

        case BqRecordsActionTypes.OneBqRecordsDeleted: return adapter.removeOne(action.payload.id, state);

        case BqRecordsActionTypes.BqRecordsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }

        case BqRecordsActionTypes.BqRecordsPageLoaded: {           
            return adapter.addOne(action.payload.BqRecords.status_fs, {
                ...initialBqRecordState,                
            });
        }
        case BqRecordsActionTypes.BqBqRecordsPageLoaded: {               
                    
            return adapter.addOne(action.payload.BqRecords.status_bq, {
                ...initialBqRecordState,                
            });
        }
        case BqRecordsActionTypes.ScoringBqRecordsPageLoaded: {               
                    
            return adapter.addOne(action.payload.BqRecords.calculate_score, {
                ...initialBqRecordState,                
            });
        }
        case BqRecordsActionTypes.TriggerPageLoaded: {               
                    
            return adapter.addOne(action.payload.BqRecords.trigger_action, {
                ...initialBqRecordState,                
            });
        }
        default: return state;
    }
}



export const getBqRecordState = createFeatureSelector<BqRecordsModel>('BqRecords');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
