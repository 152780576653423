// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { DetailsModel } from '../_models/detailsModel';

export enum DetailsActionTypes {
    DetailsOnServerCreated = '[Edit Details Dialog] Details On Server Created',
    DetailsCreated = '[Edit Details Dialog] Details Created',
    DetailsUpdated = '[Edit Details Dialog] Details Updated',
    DetailssStatusUpdated = '[Detailss List Page] Detailss Status Updated',
    OneDetailsDeleted = '[Detailss List Page] One Details Deleted',

    DetailssPageRequested = '[Detailss List Page] Detailss Page Requested',
    DetailssPageLoaded = '[Detailss API] Detailss Page Loaded',


    DetailssPageCancelled = '[Detailss API] Detailss Page Cancelled',
    DetailssPageToggleLoading = '[Detailss] Detailss Page Toggle Loading',
    DetailsActionToggleLoading = '[Detailss] Detailss Action Toggle Loading'
}

export class DetailsOnServerCreated implements Action {
    readonly type = DetailsActionTypes.DetailsOnServerCreated;
    constructor(public payload: { id:string,id2:string,champ:any }) { }
}

export class DetailsCreated implements Action {
    readonly type = DetailsActionTypes.DetailsCreated;
    constructor(public payload: { Details: DetailsModel }) { }
}

export class DetailsUpdated implements Action {
    readonly type = DetailsActionTypes.DetailsUpdated;
    constructor(public payload: {
        partialDetails: Update<DetailsModel>, 
        Details: DetailsModel 
    }) { }
}

export class DetailssStatusUpdated implements Action {
    readonly type = DetailsActionTypes.DetailssStatusUpdated;
    constructor(public  payload: {
        Detailss: DetailsModel[],
        status: number
    }) { }
}

export class OneDetailsDeleted implements Action {
    readonly type = DetailsActionTypes.OneDetailsDeleted;
    constructor(public payload: { id: number }) {}
}


export class DetailssPageRequested implements Action {
    readonly type = DetailsActionTypes.DetailssPageRequested;
    constructor(public payload: { list: any,id:string }) { }
}


export class DetailssPageLoaded implements Action {
    readonly type = DetailsActionTypes.DetailssPageLoaded;
    constructor(public payload:any) { }
}

export class DetailssPageCancelled implements Action {
    readonly type = DetailsActionTypes.DetailssPageCancelled;
}

export class DetailssPageToggleLoading implements Action {
    readonly type = DetailsActionTypes.DetailssPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class DetailsActionToggleLoading implements Action {
    readonly type = DetailsActionTypes.DetailsActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type DetailsActions = DetailsOnServerCreated
| DetailsCreated
| DetailsUpdated
| DetailssStatusUpdated
| OneDetailsDeleted
| DetailssPageRequested
| DetailssPageLoaded
| DetailssPageCancelled
| DetailssPageToggleLoading
| DetailsActionToggleLoading;
