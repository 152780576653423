import { mergeMap, tap } from 'rxjs/operators';
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
import { AppState } from '../../reducers';
import { selectgoalRedsInStore, selectgoalRedsPageLoading, selectgoalRedsShowInitWaitingMessage } from '../_selectors/goalRed.selectors';


export class goalRedsDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
        super();

		this.loading$ = this.store.pipe(
			select(selectgoalRedsPageLoading),
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectgoalRedsShowInitWaitingMessage)
        );
        
        this.store.pipe(
			select(selectgoalRedsInStore),
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});

    }
}