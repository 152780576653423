import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { TriggerActions, TriggerActionTypes } from '../_actions/Trigger.actions';
import { TriggerModel } from '../_models/TriggerModel';
import { QueryParamsModel } from '../../_base/crud';

export interface TriggerState extends EntityState<TriggerModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedTriggerId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<TriggerModel> = createEntityAdapter<TriggerModel>();

export const initialTriggerState: TriggerState = adapter.getInitialState({
    TriggerForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedTriggerId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function TriggersReducer(state = initialTriggerState, action: TriggerActions): TriggerState {
    switch  (action.type) {
        case TriggerActionTypes.TriggersPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedTriggerId: undefined
            };
        }    

        case TriggerActionTypes.TriggerActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }

        case TriggerActionTypes.TriggerOnServerCreated: return {
            ...state
        };

        case TriggerActionTypes.TriggerUpdated: return adapter.updateOne(action.payload.partialTrigger, state);
        case TriggerActionTypes.TriggerUpdated2: return adapter.updateOne(action.payload.partialTrigger, state);

        // case TriggerActionTypes.TriggersStatusUpdated: {
        //     const _partialTrigger: Update<TriggerModel>[] = [];
        //     for (let i = 0; i < action.payload.Triggers.length; i++) {
        //         _partialTrigger.push({
		// 		    id: action.payload.Triggers[i].id,
		// 		    changes: {
        //                 status: action.payload.status
        //             }
		// 	    });
        //     }
        //     return adapter.updateMany(_partialTrigger, state);
        // }

        case TriggerActionTypes.OneTriggerDeleted: return adapter.removeOne(action.payload.id, state);

        case TriggerActionTypes.TriggersPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }

        case TriggerActionTypes.TriggersPageLoaded: {           
            return adapter.addOne(action.payload.Triggers.status_fs, {
                ...initialTriggerState,                
            });
        }
        case TriggerActionTypes.BqTriggersPageLoaded: {               
                    
            return adapter.addOne(action.payload.Triggers.status_bq, {
                ...initialTriggerState,                
            });
        }
        case TriggerActionTypes.ScoringTriggerPageLoaded: {               
                    
            return adapter.addOne(action.payload.Triggers.calculate_score, {
                ...initialTriggerState,                
            });
        }
        case TriggerActionTypes.TriggerPageLoaded: {               
                    
            return adapter.addOne(action.payload.Triggers.trigger_action, {
                ...initialTriggerState,                
            });
        }
        default: return state;
    }
}



export const getTriggerState = createFeatureSelector<TriggerModel>('Triggers');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
