import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
import { RecordModel } from '../_models/RecordModel';
import { RecordState } from '../_reducers/Record.reducers';

export const selectRecordsState = createFeatureSelector<RecordState>('Records');

export const selectRecordById = (RecordId: number) => createSelector(
    selectRecordsState,
    RecordState => RecordState.entities[RecordId]
);

export const selectRecordsPageLoading = createSelector(
    selectRecordsState,
    RecordState => RecordState.listLoading
);

export const selectRecordsActionLoading = createSelector(
    selectRecordsState,
    RecordState => RecordState.actionsloading
);

export const selectLastCreatedRecordId = createSelector(
    selectRecordsState,
    RecordState => RecordState.lastCreatedRecordId
);

export const selectRecordsShowInitWaitingMessage = createSelector(
    selectRecordsState,
    RecordState => RecordState.showInitWaitingMessage
);

export const selectRecordsInStore = createSelector(
    selectRecordsState,
    RecordState => {
        const items: RecordModel[] = [];
        each(RecordState.entities, element => {
            items.push(element);
        });
        return new QueryResultsModel(items, RecordState.totalCount, '');
    }
);

