// Angular
import { Component, Input, OnInit, ViewChild, Inject } from '@angular/core';
import {
	ApexAxisChartSeries,
	ApexChart,
	ChartComponent,
	ApexDataLabels,
	ApexXAxis,
	ApexPlotOptions
} from "ng-apexcharts";
import { MAT_DIALOG_DATA } from '@angular/material';

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	xaxis: ApexXAxis;
};

export interface Widget5Data {
	pic?: string;
	title: string;
	desc: string;
	url?: string;
	info?: string;
	largeInfo?: string;
}

@Component({
	selector: 'kt-widget5',
	templateUrl: './widget5.component.html',
	styleUrls: ['./widget5.component.scss']
})
export class Widget5Component {
	@ViewChild('chart', { static: true }) chart: ChartComponent;
	public chartOptions: any;
	@Input() data: { labels: string[]; datasets: any[] };

	constructor(@Inject(MAT_DIALOG_DATA) public datachart: any) {
		if (!this.data) {
			console.log(this.datachart.event);

			this.chartOptions = {
				series: [
					{
						name: "feature importance",
						data: this.datachart.event.importance,
					}
				],

				chart: {

					type: "bar",
					height: 350
				},

				plotOptions: {
					bar: {
						colors: {
							ranges: [{
								from: 0,
								to: 10000000000,
								color: '#27AE60'
							}],
						}, horizontal: true
					}
				},

				dataLabels: {
					enabled: false
				},

				xaxis: {
					categories: this.datachart.event.columns
				}
			};

		}
	}
}