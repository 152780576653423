import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
import { ScenarioConfigState } from '../_reducers/scenarioConfig.reducers';
import { ScenarioConfigModel } from '../_models/scenarioConfigModel';

export const selectScenarioConfigsState = createFeatureSelector<ScenarioConfigState>('ScenarioConfigs');

export const selectScenarioConfigById = (ScenarioConfigId: number) => createSelector(
    selectScenarioConfigsState,
    ScenarioConfigState => ScenarioConfigState.entities[ScenarioConfigId]
);

export const selectScenarioConfigsPageLoading = createSelector(
    selectScenarioConfigsState,
    ScenarioConfigState => ScenarioConfigState.listLoading
);

export const selectScenarioConfigsActionLoading = createSelector(
    selectScenarioConfigsState,
    ScenarioConfigState => ScenarioConfigState.actionsloading
);

export const selectLastCreatedScenarioConfigId = createSelector(
    selectScenarioConfigsState,
    ScenarioConfigState => ScenarioConfigState.lastCreatedScenarioConfigId
);

export const selectScenarioConfigsShowInitWaitingMessage = createSelector(
    selectScenarioConfigsState,
    ScenarioConfigState => ScenarioConfigState.showInitWaitingMessage
);

export const selectScenarioConfigsInStore = createSelector(
    selectScenarioConfigsState,
    ScenarioConfigState => {
        const items: ScenarioConfigModel[] = [];
        each(ScenarioConfigState.entities, element => {
            items.push(element);
        });
        return new QueryResultsModel(items, ScenarioConfigState.totalCount, '');
    }
);

