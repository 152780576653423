import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { DetailGActions, DetailGActionTypes } from '../_actions/DetailG.actions';
import { QueryParamsModel } from '../../_base/crud';
import { goalRedModel } from '..';

export interface DetailGState extends EntityState<goalRedModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedDetailGId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<goalRedModel> = createEntityAdapter<goalRedModel>();
  
export const initialDetailGState: DetailGState = adapter.getInitialState({
    DetailGForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedDetailGId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function DetailGsReducer(state = initialDetailGState, action: DetailGActions): DetailGState {
    switch  (action.type) {
        case DetailGActionTypes.DetailGsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedDetailGId: undefined
            };
        }    

        case DetailGActionTypes.DetailGActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }

        case DetailGActionTypes.DetailGOnServerCreated: return {
            ...state
        };

        case DetailGActionTypes.DetailGUpdated: return adapter.updateOne(action.payload.partialDetailG, state);

        case DetailGActionTypes.DetailGsStatusUpdated: {
            const _partialDetailG: Update<goalRedModel>[] = [];
            for (let i = 0; i < action.payload.DetailGs.length; i++) {
                _partialDetailG.push({
				    id: action.payload.DetailGs[i].id,
				    changes: {
                        status: action.payload.status
                    }
			    });
            }
            return adapter.updateMany(_partialDetailG, state);
        }

        case DetailGActionTypes.OneDetailGDeleted: return adapter.removeOne(action.payload.id, state);

        case DetailGActionTypes.DetailGsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        } 

        case DetailGActionTypes.DetailGsPageLoaded: {
            return adapter.addOne(action.payload.DetailGs, {
                ...initialDetailGState,
                listLoading: false,
                showInitWaitingMessage: false
            }); 
        }
        default: return state;
    }
}



export const getDetailGState = createFeatureSelector<goalRedModel>('DetailG');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
