import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { DetailsModel } from '../_models/detailsModel';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../../../environments/environment';

const API_Details_URL = 'api/Detailss';

@Injectable()
export class DetailssService {
	token: string;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private firestore: AngularFirestore) { }

	createDetails(Details: DetailsModel): Observable<DetailsModel> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post<DetailsModel>(environment.API_URL + "/Detailss", Details, { headers: headers });
	}
	Duplicated(id, id2, champ): Observable<DetailsModel> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post<DetailsModel>(environment.API_URL + "/scenarios/senario_config_dupliquer/" + id, {
			'senario_config_id': id2,
			'tab': champ,
		}, { headers: headers });
	}
	getAllDetailss(): Observable<DetailsModel[]> {
		return this.http.get<DetailsModel[]>(environment.API_URL + '/cars');
	}

	getDetailsById(DetailsId: number): Observable<DetailsModel> {
		return this.http.get<DetailsModel>(API_Details_URL + `/${DetailsId}`);
	}

	findDetailss(id: string, eid: string): Observable<any> {
		console.log(eid);
		console.log(id);
		console.log("ejjejejejejjejeejj");
		
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		const f = this.firestore.collection<any>('enterprises').doc(id).collection("scenarios").doc(eid).collection("logs", ref => {
			return ref.orderBy("date", "desc").where("scenario_id", "==", eid) 
		}).valueChanges()
		f.subscribe(res => {
			console.log(res);

		})
		return f;
	}
	
	updateDetails(Details: DetailsModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_Details_URL, Details, { headers: httpHeader });
	}

	updateStatusForDetails(Detailss: DetailsModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			DetailssForUpdate: Detailss,
			newStatus: status
		};
		const url = API_Details_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	deleteDetails(DetailsId: number): Observable<DetailsModel> {
		return this.http.delete<DetailsModel>(environment.API_URL + '/cars' + '/' + DetailsId);
	}

	deleteDetailss(ids: number[] = []): Observable<any> {
		const url = API_Details_URL + '/deleteDetailss';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { DetailsIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders });
	}
}
