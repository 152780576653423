import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VisitorsService {

  constructor(public http: HttpClient) { }

  getAllVisitors() {
 
    return this.http.get(environment.API_URL+"/visitors");
  }

  getaction() {
  
    return this.http.get(environment.API_URL+'/logs/actions').pipe(map(res => {
      return res;
    }));
  }


  getAllScoreLog() {
    return this.http.get(environment.API_URL+"/logs/score");
  }
  getEventbqByVisiteur() {
    return this.http.get(environment.API_URL+"/enterprises/events_bq?by=visitor_id");
  }


  getAllNotes(idVisitor) {
    return this.http.get(environment.API_URL+"/" + idVisitor + "/notes");
  }

  getscore(session_id, visitor_id) {
    return this.http.get(environment.API_URL+'/ml/predict?session_id=' + session_id + '&visitor_id=' + visitor_id).pipe(map(res => {
      return res;
    }));


  }

  getListScore(visitor_id) {
    return this.http.get(environment.API_URL+'/logs/score?visitor_id=' + visitor_id).pipe(map(res => {
      return res;
    }));



  }

  getListScoreactivesenario(visitor_id, scenario_id) {
    return this.http.get(environment.API_URL+'/logs/score?visitor_id=' + visitor_id + '&scenario_id=' + scenario_id).pipe(map(res => {
      return res;
    }));



  }

  actionLogs(visitor_id) {
    return this.http.get(environment.API_URL+'/actions?visitor_id=' + visitor_id).pipe(map(res => {
      return res;
    }));
  }

  getChartBySessionId(session_id) {

    return this.http.get(environment.API_URL+'//logs/actions?scenario_id=' + session_id).pipe(map(res => {
      return res;
    }));


  }


}