// Angular
import * as firebase from 'firebase';
// Angular
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
// RxJS
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../core/reducers';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
@Component({
	selector: 'kt-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent { 
	constructor( ) {
	
	}
}
