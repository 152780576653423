import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, map, tap, delay } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryResultsModel } from '../../_base/crud';
import { EventCapturedsService } from '../_services';
import { AppState } from '../../reducers';
import {

    EventCapturedActionToggleLoading,
    EventCapturedActionTypes,
    EventCapturedsPageLoaded,
    EventCapturedsPageRequested,
    EventCapturedsPageToggleLoading,
    OneEventCapturedDeleted

} from '../_actions/EventCaptured.actions';
import { of } from 'rxjs';

@Injectable()
export class EventCapturedEffects {
    showPageLoadingDistpatcher = new EventCapturedsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new EventCapturedActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new EventCapturedActionToggleLoading({ isLoading: false });

    @Effect()
    loadEventCapturedsPage$ = this.actions$.pipe(
        ofType<EventCapturedsPageRequested>(EventCapturedActionTypes.EventCapturedsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.EventCapturedsService.findEventCaptureds(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new EventCapturedsPageLoaded({
                EventCaptureds: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteCustomer$ = this.actions$
        .pipe(
            ofType<OneEventCapturedDeleted>(EventCapturedActionTypes.OneEventCapturedDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.EventCapturedsService.deleteEventCaptured(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private EventCapturedsService: EventCapturedsService, private store: Store<AppState>) { }

}
