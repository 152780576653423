// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { goalRedModel } from '../_models/goalRedModel';

export enum goalRedActionTypes {
    goalRedOnServerCreated = '[Edit goalRed Dialog] goalRed On Server Created',
    goalRedCreated = '[Edit goalRed Dialog] goalRed Created',
    goalRedUpdated = '[Edit goalRed Dialog] goalRed Updated',
    goalRedsStatusUpdated = '[goalReds List Page] goalReds Status Updated',
    OnegoalRedDeleted = '[goalReds List Page] One goalRed Deleted',

    goalRedsPageRequested = '[goalReds List Page] goalReds Page Requested',
    goalRedsPageLoaded = '[goalReds API] goalReds Page Loaded',


    goalRedsPageCancelled = '[goalReds API] goalReds Page Cancelled',
    goalRedsPageToggleLoading = '[goalReds] goalReds Page Toggle Loading',
    goalRedActionToggleLoading = '[goalReds] goalReds Action Toggle Loading'
}

export class goalRedOnServerCreated implements Action {
    readonly type = goalRedActionTypes.goalRedOnServerCreated;
    constructor(public payload: { goalRed: goalRedModel }) { }
}

export class goalRedCreated implements Action {
    readonly type = goalRedActionTypes.goalRedCreated;
    constructor(public payload: { goalRed: goalRedModel }) { }
}

export class goalRedUpdated implements Action {
    readonly type = goalRedActionTypes.goalRedUpdated;
    constructor(public payload: {
        partialgoalRed: Update<goalRedModel>, 
        goalRed: goalRedModel 
    }) { }
}

export class goalRedsStatusUpdated implements Action {
    readonly type = goalRedActionTypes.goalRedsStatusUpdated;
    constructor(public  payload: {
        goalReds: goalRedModel[],
        status: number
    }) { }
}

export class OnegoalRedDeleted implements Action {
    readonly type = goalRedActionTypes.OnegoalRedDeleted;
    constructor(public payload: { id: number }) {}
}


export class goalRedsPageRequested implements Action {
    readonly type = goalRedActionTypes.goalRedsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class goalRedsPageLoaded implements Action {
    readonly type = goalRedActionTypes.goalRedsPageLoaded;
    constructor(public payload: { goalReds: goalRedModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class goalRedsPageCancelled implements Action {
    readonly type = goalRedActionTypes.goalRedsPageCancelled;
}

export class goalRedsPageToggleLoading implements Action {
    readonly type = goalRedActionTypes.goalRedsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class goalRedActionToggleLoading implements Action {
    readonly type = goalRedActionTypes.goalRedActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type goalRedActions = goalRedOnServerCreated
| goalRedCreated
| goalRedUpdated
| goalRedsStatusUpdated
| OnegoalRedDeleted
| goalRedsPageRequested
| goalRedsPageLoaded
| goalRedsPageCancelled
| goalRedsPageToggleLoading
| goalRedActionToggleLoading;
