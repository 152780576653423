import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { goalRedActions, goalRedActionTypes } from '../_actions/goalRed.actions';
import { goalRedModel } from '../_models/goalRedModel';
import { QueryParamsModel } from '../../_base/crud';

export interface goalRedState extends EntityState<goalRedModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedgoalRedId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<goalRedModel> = createEntityAdapter<goalRedModel>({
    selectId: EventConfigModel => EventConfigModel.id
  });
 
export const initialgoalRedState: goalRedState = adapter.getInitialState({
    goalRedForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedgoalRedId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function goalRedsReducer(state = initialgoalRedState, action: goalRedActions): goalRedState {
    switch  (action.type) {
        case goalRedActionTypes.goalRedsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedgoalRedId: undefined
            };
        }    

        case goalRedActionTypes.goalRedActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }

        case goalRedActionTypes.goalRedOnServerCreated: return {
            ...state
        };

        case goalRedActionTypes.goalRedUpdated: return adapter.updateOne(action.payload.partialgoalRed, state);

        // case goalRedActionTypes.goalRedsStatusUpdated: {
        //     const _partialgoalRed: Update<goalRedModel>[] = [];
        //     for (let i = 0; i < action.payload.goalReds.length; i++) {
        //         _partialgoalRed.push({
		// 		    id: action.payload.goalReds[i].id,
		// 		    changes: {
        //                 status: action.payload.status
        //             }
		// 	    });
        //     }
        //     return adapter.updateMany(_partialgoalRed, state);
        // }

        case goalRedActionTypes.OnegoalRedDeleted: return adapter.removeOne(action.payload.id, state);

        case goalRedActionTypes.goalRedsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }

        case goalRedActionTypes.goalRedsPageLoaded: {
            return adapter.addMany(action.payload.goalReds, {
                ...initialgoalRedState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}



export const getgoalRedState = createFeatureSelector<goalRedModel>('goalReds');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
