import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, map, tap, delay } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryResultsModel } from '../../_base/crud';
import { BqRecordsService } from '../_services';
import { AppState } from '../../reducers';
import {

    BqRecordsActionToggleLoading,
    BqRecordsActionTypes,
    BqRecordsPageLoaded,
    BqRecordsPageRequested,
    BqBqRecordsPageRequested,

    BqRecordsPageToggleLoading,
    OneBqRecordsDeleted,
    BqRecordsOnServerCreated,
    BqRecordsCreated,
    BqRecordsUpdated,
    BqRecordsUpdated2,
    BqBqRecordsPageLoaded,
   


} from '../_actions/BqRecords.actions';
import { of } from 'rxjs';

@Injectable()
export class BqRecordsEffects {
    showPageLoadingDistpatcher = new BqRecordsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new BqRecordsActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new BqRecordsActionToggleLoading({ isLoading: false });

    @Effect()
    loadBqRecordsPage$ = this.actions$.pipe(
        ofType<BqRecordsPageRequested>(BqRecordsActionTypes.BqRecordsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.BqRecordsService.findBqRecords(payload.page);            
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new BqRecordsPageLoaded({
                BqRecords: result
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    loadBqBqRecordsPage$ = this.actions$.pipe(
        ofType<BqBqRecordsPageRequested>(BqRecordsActionTypes.BqBqRecordsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.BqRecordsService.findBqRecords(payload.page);            
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];            
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new BqBqRecordsPageLoaded({
                BqRecords: result
            });
            return pageLoadedDispatch;
        })
    );


    @Effect()
    deleteCustomer$ = this.actions$
        .pipe(
            ofType<OneBqRecordsDeleted>(BqRecordsActionTypes.OneBqRecordsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.BqRecordsService.deleteBqRecord(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

        @Effect()
        createBqRecord$ = this.actions$
            .pipe(
                ofType<BqRecordsOnServerCreated>(BqRecordsActionTypes.BqRecordsOnServerCreated),
                mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.BqRecordsService.createBqRecord(payload.BqRecords).pipe(
                        tap(res => {
                            this.store.dispatch(new BqRecordsCreated({ BqRecords: res }));
                        })
                    );
                }),
                map(() => {
                    return this.hideActionLoadingDistpatcher;
                }),
            );

            @Effect()
            updateToggel$ = this.actions$
                .pipe(
                    ofType<BqRecordsUpdated>(BqRecordsActionTypes.BqRecordsUpdated),
                    mergeMap(( { payload } ) => {                        
                        this.store.dispatch(this.showActionLoadingDistpatcher);
                        this.BqRecordsService.updateBqRecord(payload.BqRecords);
                        return this.BqRecordsService.updateBqRecord(payload.BqRecords);
                    }),
                    map(() => {
                        return this.hideActionLoadingDistpatcher;
                    })
                );
                @Effect()

                updateToggel2$ = this.actions$
                .pipe(
                    ofType<BqRecordsUpdated2>(BqRecordsActionTypes.BqRecordsUpdated2),
                    mergeMap(( { payload } ) => {                                                
                        this.store.dispatch(this.showActionLoadingDistpatcher);
                        this.BqRecordsService.updateBqRecord2(payload.BqRecords,payload.type);
                        return this.BqRecordsService.updateBqRecord2(payload.BqRecords,payload.type);
                    }),
                    map(() => {
                        return this.hideActionLoadingDistpatcher;
                    })
                );
    constructor(private actions$: Actions, private BqRecordsService: BqRecordsService, private store: Store<AppState>) { }

}
