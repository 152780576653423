// Angular
import { Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef, OnChanges } from '@angular/core';
// Layout config
import { LayoutConfigService } from '../../../../../core/_base/layout';
import Chart from 'chart.js';
import { VisitorsService } from '../../../../../core/visiteur/_services/visiteur.service';
import { goalRedsService } from '../../../../../core/goal';
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexXAxis,
	ApexDataLabels,
	ApexStroke,
	ApexMarkers,
	ApexYAxis,
	ApexGrid,
	ApexTitleSubtitle,
	ApexLegend
} from "ng-apexcharts";

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	stroke: ApexStroke;
	dataLabels: ApexDataLabels;
	markers: ApexMarkers;
	tooltip: any; // ApexTooltip;
	yaxis: ApexYAxis;
	grid: ApexGrid;
	legend: ApexLegend;
	colors:any;
	title: ApexTitleSubtitle;
};
/**
 * Sample components with sample data
 */
@Component({
	selector: 'kt-widget26',
	templateUrl: './widget26.component.html',
	styleUrls: ['./widget26.component.scss'] 
})
export class Widget26Component implements OnChanges {
	obj: any = '';
	bool: boolean = false;
	changeStatus(): void {

		setTimeout(() => {

			this._cdr.detectChanges()
			this._cdr.markForCheck()

		}, 1500);
	}
	// Public properties
	@Input() data: { labels: string[], datasets: any[] };
	@Input() type: string = 'line';
	// @ViewChild('chart', { static: true }) chart: ElementRef;
	@Input('chat') chat: any;
	@Input('typechart') typechart: any;
	// @Input('x') x: any;
	// @Input('date3') date3: any;
	// @Input('date4') date4: any;
	@ViewChild("chart", { static: true }) chart: ChartComponent;
	public chartOptions: any;
	dataScores: number[] = [50, 20, 30, 70, 20];

	/**
	 * Component constructor
	 * @param layoutConfigService
	 */
	constructor(
		private layoutConfigService: LayoutConfigService,
		private VisitorsService: VisitorsService, private goalRedsService: goalRedsService,
		private _cdr: ChangeDetectorRef,
	) {
	}
	ngOnChanges() {  
		this.obj = '';
		// this.goalRedsService.filter(this.idgoal, this.x, this.date3, this.date4).subscribe(list => {
		// let obj = JSON.parse(JSON.stringify(list));			
		this.obj = this.chat;
		const color = Chart.helpers.color;
 
		this.chartOptions = {
			series: [
			  {
				name: "Number Of Visitors",
				data: this.chat.sessions,
			  },
			],
			chart: {
			  height: 190,
			  type: "area",
			  toolbar: {
				show: false,
				tools: {
				  download: false,
				}, 
			  },
			},
			dataLabels: {
			  enabled: false,
			},
			stroke: {
			  curve: "smooth",
			  width:0,
			  colors:['#F3CE3F']
			},
			xaxis: {
			  categories: this.chat[this.typechart],
			  title: {
				text: this.typechart
			  }
			},
			legend: {
				show: false,
			  },
			  grid: {
				show: false,
			  },
			colors:['#F3CE3F'],
			tooltip: {
			  x: {
				colors:['red'],
				format: "dd/MM/yy HH:mm",
			  },
			},
		  };

	}




}
