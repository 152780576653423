// Angular
import { Component, Input, OnInit, ViewChild, Inject, ChangeDetectorRef, OnChanges } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions
} from "ng-apexcharts";
import { MAT_DIALOG_DATA } from '@angular/material';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
};

export interface Widget5Data {
  pic?: string;
  title: string;
  desc: string;
  url?: string;
  info?: string;
  largeInfo?: string;
}
@Component({
  selector: 'kt-geberaledashbord',
  templateUrl: './geberaledashbord.component.html',
  styleUrls: ['./geberaledashbord.component.scss']
})
export class GeberaledashbordComponent implements OnChanges {


  // Public properties 
  @Input() type: string = 'line';
  // @ViewChild('chart', { static: true }) chart: ElementRef;
  @Input('chat') chat: any;
  @Input('total') total: any;
  // @ViewChild('chart', { static: true }) chart: ChartComponent;
  // public chartOptions: Partial<ChartOptions>;
  public chartOptions: any;
  // public chartOptions: Partial<ChartOptions>;
  somme: number = 0;

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   */
  constructor(private _cdr: ChangeDetectorRef,
  ) {
  }
  changeStatus(): void {

    setTimeout(() => {

      this._cdr.detectChanges()
      this._cdr.markForCheck()

    }, 1500);
  }
  obj: any = '';
  // x = [];
  ngOnChanges() { 
    if (this.chat) {
      const maxIndex = this.chat.count.indexOf(Math.max.apply(null, this.chat.count));

      // Create an array of colors, with the maximum value in blue and others in gray
      const colors = this.chat.count.map((value, index) => (index === maxIndex ? "#3BB5FE" : "#B3C8DC"));
      this.chartOptions = {
        series: [
          {
            name: "Session's Percentage",
            data: this.chat.count,
          },
        ],
        chart: {
          height: 250,
          type: "bar",
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "%";
            },
            // title: {
            //   formatter: (seriesName) => seriesName / this.total,
            // },
          },
        },
        colors: colors,

        fill: {
          type: "pattern",
          opacity: 1,
          pattern: {
            enabled: true,
            style: [
              // "verticalLines",
              // "squares",
              // "horizontalLines",
              // "circles",
            ],
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "40%",
            distributed: true,
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        title: {
          text: "Number of sessions per events number",
          align: "left",
          margin: 10,
          floating: false,
          style: {
            fontSize: "14px", 
            fontWeight: "bold",
            // fontFamily:  undefined,
            color: "#0D1861",
          },
        },
        xaxis: {
          categories: this.chat.keys,

          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      };;
      this.changeStatus();
    }
  }


}