import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { KpiModel } from '../_models/KpiModel';
import * as firebase from 'firebase';
import { environment } from '../../../../environments/environment';
import { catchError, finalize } from 'rxjs/operators';

const API_Kpi_URL = 'api/Kpis';

@Injectable()
export class KpisService {
	token: string;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	updateScinario(id, Pop2,tab): Observable<any> {
		let headers = new HttpHeaders();
		const json = Object.assign({}, tab); 
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL + '/scenarios/' + id, {
			"eventsNumber": Pop2.eventsNumber,
			"description"  :Pop2.description,
			"name":Pop2.name,
			"config": json,
			"parameters":{
				"verbose": Pop2.verbose,
				"metric": Pop2.metric,
				"eval_metric": Pop2.eval_metric,
				"n_estimators": Pop2.n_estimators,
				"learning_rate": Pop2.learning_rate,
				"early_stopping_rounds": Pop2.early_stopping_rounds,
				"boosting_type": Pop2.boosting_type,
				"sampling_ratio": Pop2.sampling_ratio,
				"random_state": Pop2.random_state,
			},
			// "max_bin": Pop2.max_bin,
			// "over_sampling": Pop2.over_sampling,
		}, { headers: headers });
	}

	updateGoal(id, Pop2): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL + '/goals/' + id, {
			'popup_intervals': Pop2,
		}, { headers: headers });
	}
	createKpi(id, tab, champ): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		console.log(id, tab, champ);
		const json = Object.assign({}, tab); 
		return this.http.put(environment.API_URL + "/scenarios/senario_config/" + id, json, { headers: headers });
	}
	putscinario(id, tab): Observable<any> {

		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		const json = Object.assign({}, tab); 
		return this.http.put(environment.API_URL + '/scenarios/' + id, {
			"config": json
		}, { headers: headers });
		// let headers = new HttpHeaders();
		// headers = headers.append("Content-Type", "application/json");
		// console.log(id, tab, champ);
		// const json = Object.assign({}, tab); 
		// return this.http.put(environment.API_URL + "/scenarios/senario_config/" + id, json, { headers: headers });
	} 
	createKpi2(id, tab): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL + "/scenarios/" + id,
			{
				"kpis": {
					"standard": {
						"Single_article_bought": tab
					}
				},
			}, { headers: headers });
	}

	getAllKpis(): Observable<KpiModel[]> {
		return this.http.get<KpiModel[]>(environment.API_URL + '/cars');
	}

	getKpiById(KpiId: number): Observable<KpiModel> {
		return this.http.get<KpiModel>(API_Kpi_URL + `/${KpiId}`);
	}

	// findKpis(id:string): Observable<QueryResultsModel> {
	// 	let headers = new HttpHeaders();
	// 	headers = headers.append("Content-Type", "application/json");
	// 	return this.http.get<QueryResultsModel>(environment.API_URL+'/scenarios/' + id, { headers: headers });
	// }
	findKpis(id: string): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get<any>(environment.API_URL + '/scenarios/' + id, { headers: headers });
	}

	getnumber(): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get<any>(environment.reporting + '/entreprise_stats', { headers: headers }).pipe(
			catchError(err => {
				// this._errorMessage.next(err);
				console.error('UPDATE ITEM', err);
				return of(null);
			}),
			finalize(() => { })
		);
	}
	// detailScinario_config(id: string): Observable<any> {
	// 	let headers = new HttpHeaders();
	// 	headers = headers.append("Content-Type", "application/json");
	// 	return this.http.get<any>(environment.API_URL + '/scenarios/senario_config/' + id, { headers: headers });
	// }  
	updateKpi(Kpi: KpiModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_Kpi_URL, Kpi, { headers: httpHeader });
	}

	updateStatusForKpi(Kpis: KpiModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			KpisForUpdate: Kpis,
			newStatus: status
		};
		const url = API_Kpi_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	deleteKpi(id, id2, x): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post(environment.API_URL + '/scenarios/delete_senario_config_dupliquer/' + id, {
			'senario_config_id': id2, 
			'duplicated_id': x
		}, { headers: headers });
	}

	deleteKpis(ids: number[] = []): Observable<any> {
		const url = API_Kpi_URL + '/deleteKpis';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { KpiIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders });
	}
}
