import { BaseModel } from '../../_base/crud';

export class ScenarioConfigModel extends BaseModel {
  id: number;
  titre: string;
  description: string;
  generating: string;
  goalCategory: string;
  status: boolean; 
  sessionIdValue: boolean;
  visiIdValue: boolean;
  Pop2:any;
  loginValue: boolean;
  longterm_onetime:boolean;
  accuracy:accuracy;
  tag:string;
  importance_dict:any;
  kpi:kpi;
	date: string | number | Date;
	graph: any;
  clear() {
    this.id = 0;
    this.tag = '';
    this.titre = '';
    this.description = '';
    this.generating = '';
    this.goalCategory = '';
    this.accuracy=null;
    this.kpi=null;
  }

}

export class accuracy {
  latest_accuracy:number;
   latest_update:Date;
}


export class kpi{
genreral:general;
standard:standard;
}
export class general{
  city:string;
  Browser:string;
  DeviceType:string;
  Date:Array<string>;
  Time_spent_in_the_session:string;
  Number_of_clicks_on_the_buttons:Array<string>;
  Number_of_pages_read_in_the_session:Array<string>;
  Time_spent_in_a_page_dwell_time:Array<string>;
}
export class standard {
  Single_article_bought:Array<string>;
}