import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, map, tap, delay } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryResultsModel } from '../../_base/crud';
import { goalRedsService } from '../_services';
import { AppState } from '../../reducers';
import {

    goalRedActionToggleLoading,
    goalRedActionTypes,
    goalRedsPageLoaded,
    goalRedsPageRequested,
    goalRedsPageToggleLoading,
    OnegoalRedDeleted,
    goalRedOnServerCreated,
    goalRedCreated,
    goalRedUpdated

} from '../_actions/goalRed.actions';
import { of } from 'rxjs';

@Injectable()
export class goalRedEffects {
    showPageLoadingDistpatcher = new goalRedsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new goalRedActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new goalRedActionToggleLoading({ isLoading: false });

    @Effect()
    loadgoalRedsPage$ = this.actions$.pipe(
        ofType<goalRedsPageRequested>(goalRedActionTypes.goalRedsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.goalRedsService.findgoalReds();
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map((response:any) => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new goalRedsPageLoaded({
                goalReds: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteCustomer$ = this.actions$
        .pipe(
            ofType<OnegoalRedDeleted>(goalRedActionTypes.OnegoalRedDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.goalRedsService.deletegoalRed(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

        @Effect()
        creategoalRed$ = this.actions$
            .pipe(
                ofType<goalRedOnServerCreated>(goalRedActionTypes.goalRedOnServerCreated),
                mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.goalRedsService.creategoalRed(payload.goalRed).pipe(
                        tap(res => {                             
                            this.store.dispatch(new goalRedCreated({ goalRed: res }));
                        })
                    );
                }),
                map(() => {
                    return this.hideActionLoadingDistpatcher;
                }),
            );

          
            @Effect()
            updateToggel$ = this.actions$
                .pipe(
                    ofType<goalRedUpdated>(goalRedActionTypes.goalRedUpdated),
                    mergeMap(( { payload } ) => {                        
                        this.store.dispatch(this.showActionLoadingDistpatcher);
                        return this.goalRedsService.updategoalRed(payload.goalRed);
                    }),
                    map(() => {
                        return this.hideActionLoadingDistpatcher;
                    })
                );

    constructor(private actions$: Actions, private goalRedsService: goalRedsService, private store: Store<AppState>) { }

}
