export class MenuConfig {
	public defaults: any = {
		aside: {
			self: {},
			items: [
				{
					title: 'Home',
					root: true,
					// icon: 'flaticon2-analytics',
					page: '/popup/home',
					alignment: 'left',
					translate: 'MENU.DASHBOARD',
				},
				{
					title: 'Key Performance Indicators',
					root: true,
					// icon: 'flaticon2-analytics',
					page: '/popup/goal',
					alignment: 'left',
					translate: 'MENU.DASHBOARD',
				},
				{
					title: 'App Configuration',
					root: true,
					// icon: 'flaticon2-analytics',
					alignment: 'left',
					submenu: [
						{
							title: 'Connect Product',
							root: true,
							bullet: 'dot',
							// icon: 'flaticon2-rhombus',
		
							submenu: [
								{
									title: 'App Configuration',
									bullet: 'dot',
									page: '/setup',
								},
								{
									title: 'GDPR',
									bullet: 'dot',
									page: '/gdpr-config/list/gdpr',
								},
		
							]
						},
						{
							title: 'Modeling Hub',
							root: true,
							bullet: 'dot',
							// icon: 'flaticon2-rhombus',
							submenu: [
								{
									title: 'Event Configuration',
									bullet: 'dot',
									page: '/event-config/list',
								},
								{
									title: 'Model Creation',
									root: true,
									page: '/scenario/kpilist',
									alignment: 'left',
									translate: 'MENU.DASHBOARD',
								},
		
							]
						},
						{
							title: 'Campaign Maker',
							root: true,
							bullet: 'dot',
							// icon: 'flaticon2-rhombus',
							submenu: [
								{
									title: 'Call to Action Popup',
									bullet: 'dot',
									root: true,
									page: '/popup/list',
									alignment: 'left',
									translate: 'MENU.DASHBOARD',
								},
								{
									title: 'Campaign Creation',
									root: true,
									page: '/goal/detail',
									alignment: 'left',
									translate: 'MENU.DASHBOARD',
								},
		
							]
						},
					]
				},
			

				
				{
					title: 'System Status',
					root: true,
					// icon: 'flaticon2-analytics',
					page: '/configuration/config',
					alignment: 'left',
					translate: 'MENU.DASHBOARD',
				},
				{
					title: 'Account',
					root: true,
					bullet: 'dot',
					submenu: [


						{
							title: 'User Management',
							bullet: 'dot',
							page: '/userManagment/list'
						},
						{
							title: 'Account Settings',
							bullet: 'dot',
							page: '/account'
						},
					]
				},

				

			]



		}
	};

	public get configs(): any {
		return this.defaults;
	}
}
export class MenuConfigUser {
	public defaults: any = {
		aside: {
			self: {},
			items: [
				{
					title: 'Home',
					root: true,
					// icon: 'flaticon2-analytics',
					page: '/popup/home',
					alignment: 'left',
					translate: 'MENU.DASHBOARD',
				},
				{
					title: 'Key Performance Indicators',
					root: true,
					// icon: 'flaticon2-analytics',
					page: '/popup/goal',
					alignment: 'left',
					translate: 'MENU.DASHBOARD',
				},
				{
					title: 'App Configuration',
					root: true,
					// icon: 'flaticon2-analytics',
					alignment: 'left',
					submenu: [
						{
							title: 'Connect Product',
							root: true,
							bullet: 'dot',
							// icon: 'flaticon2-rhombus',
		
							submenu: [
								{
									title: 'App Configuration',
									bullet: 'dot',
									page: '/setup',
								},
								{
									title: 'GDPR',
									bullet: 'dot',
									page: '/gdpr-config/list/gdpr',
								},
		
							]
						},
						{
							title: 'Modeling Hub',
							root: true,
							bullet: 'dot',
							// icon: 'flaticon2-rhombus',
							submenu: [
								{
									title: 'Event Configuration',
									bullet: 'dot',
									page: '/event-config/list',
								},
								{
									title: 'Model Creation',
									root: true,
									page: '/scenario/kpilist',
									alignment: 'left',
									translate: 'MENU.DASHBOARD',
								},
		
							]
						},
						{
							title: 'Campaign Maker',
							root: true,
							bullet: 'dot',
							// icon: 'flaticon2-rhombus',
							submenu: [
								{
									title: 'Call to Action Popup',
									bullet: 'dot',
									root: true,
									page: '/popup/list',
									alignment: 'left',
									translate: 'MENU.DASHBOARD',
								},
								{
									title: 'Campaign Creation',
									root: true,
									page: '/goal/detail',
									alignment: 'left',
									translate: 'MENU.DASHBOARD',
								},
		
							]
						},
					]
				},
				
				{
					title: 'System Status',
					root: true,
					// icon: 'flaticon2-analytics',
					page: '/configuration/config',
					alignment: 'left',
					translate: 'MENU.DASHBOARD',
				},
				{
					title: 'Account',
					root: true,
					// icon: 'flaticon2-rhombus',
					submenu: [
						{
							title: 'Account Settings',
							root: true,
							alignment: 'left',
							bullet: 'dot',
							page: '/account'
		
						},
						{
							"title": "Companies KPI\'s",
							root: true,
							// icon: 'flaticon2-analytics',
							page: '/userManagment/listCompaign',
							alignment: 'left',
							bullet: 'dot',
							translate: 'MENU.DASHBOARD',
						},
						{
							title: 'User Management',
							root: true,
							bullet: 'dot',
							alignment: 'left',
							page: '/userManagment/ListUsers'
						},
					]
				},
				

				
				

			]



			// items: [
			// 	{
			// 		title: 'Metrics',
			// 		root: true,
			// 		icon: 'flaticon2-analytics',
			// 		page: '/popup/goal',
			// 		alignment: 'left',
			// 		translate: 'MENU.DASHBOARD',
			// 	},

			// 	{
			// 		title: 'Data Captured',
			// 		root: true,
			// 		bullet: 'dot',
			// 		icon: 'flaticon2-rhombus',
			// 		submenu: [
			// 			{
			// 				title: 'Visitors',
			// 				root: true,
			// 				icon: 'flaticon2-avatar',
			// 				page: '/visiteur/list',
			// 				alignment: 'left',
			// 				translate: 'MENU.DASHBOARD',
			// 			},
			// 			{
			// 				title: 'Email-Newsletter',
			// 				root: true,
			// 				bullet: 'dot',
			// 				icon: 'flaticon2-envelope',
			// 				page: '/newsletter',

			// 			},
			// 			{
			// 				title: 'Event Captured',
			// 				root: true,
			// 				bullet: 'dot',
			// 				icon: 'flaticon2-paper-plane',
			// 				page: '/event/listeventsbq',

			// 			},

			// 		]
			// 	},

			// 	{ section: 'Configuration' },
			// 	{
			// 		title: 'Connect',
			// 		root: true,
			// 		bullet: 'dot',
			// 		icon: 'flaticon2-rhombus',
			// 		submenu: [
			// 			{
			// 				title: 'Script JS',
			// 				bullet: 'dot',
			// 				page: '/setup',
			// 			},
			// 			{
			// 				title: 'GDPR Pop-up',
			// 				bullet: 'dot',
			// 				page: '/gdpr-config/list/gdpr',
			// 			},
			// 			{
			// 				title: 'Event Configuration',
			// 				bullet: 'dot',
			// 				page: '/event-config/list',
			// 			},
			// 		]
			// 	},
			// 	{
			// 		title: 'Scenario & AI scoring',
			// 		root: true,
			// 		icon: 'flaticon2-start-up',
			// 		page: '/scenario/kpilist',
			// 		alignment: 'left',
			// 		translate: 'MENU.DASHBOARD',
			// 	},
			// 	{
			// 		title: 'Campaigns',
			// 		root: true,
			// 		icon: 'flaticon2-mail-1',
			// 		page: '/goal/detail',
			// 		alignment: 'left',
			// 		translate: 'MENU.DASHBOARD',
			// 	},
			// 	{
			// 		title: 'Calls to action',
			// 		root: true,
			// 		icon: 'flaticon2-speaker',
			// 		page: '/popup/list',
			// 		alignment: 'left',
			// 		translate: 'MENU.DASHBOARD',
			// 	},
			// 	{ section: 'Account' },
			// 	{
			// 		title: 'Admin',
			// 		root: true,
			// 		bullet: 'dot',
			// 		icon: 'flaticon2-user',
			// 		submenu: [


			// 			{
			// 				title: 'User Management',
			// 				bullet: 'dot',
			// 				page: '/userManagment/list'
			// 			},
			// 			{
			// 				title: 'Account',
			// 				bullet: 'dot',
			// 				page: '/account'
			// 			}, {
			// 				title: 'Billing',
			// 				bullet: 'dot',
			// 				page: '/userManagment/list'
			// 			},
			// 		]
			// 	},
			// 	{
			// 		title: 'Settings',
			// 		root: true,
			// 		bullet: 'dot',
			// 		icon: 'flaticon2-settings',
			// 		submenu: [

			// 			{
			// 				title: 'System status',
			// 				bullet: 'dot',
			// 				page: '/configuration/config',
			// 			},


			// 		]
			// 	},



			// ]
		}
	};

	public get configs(): any {
		return this.defaults;
	}
}