import { BaseModel } from '../../_base/crud';

export class goalRedModel extends BaseModel {
  id: string;
  goalCategory: string;
  Goalname: string;
  actionName: string;
  goalName:string;
  sessionIdValue: boolean;
  createdon: string;
  rule;
  triggersList:any;
  j0: any;
  j1: any;
  preo;
  j2: any;
  j3: any;
  j4: any;
  j5: any;
  j6: any;

  discount0: any;
  discount1: any;
  discount2: any;
  discount3: any;
  discount4: any;
  discount5: any;
  discount6: any;


  b0: any;
  b1: any;
  b2: any;
  b3: any;
  b4: any;
  b5: any;
  b6: any;

  c0: any;
  c1: any;
  c2: any;
  c3: any;
  c4: any;
  c5: any;
  c6: any;
  visiIdValue: boolean;
  loginValue: boolean;
  type: string;
  activate: boolean;
  ignored_data_per_day: any;
  Pop1: any;
  createdOn: any;
  Pop2: any;
  triggers: any;
  idScinario: any;
  listPopup: any
  Pop3: any;
  status: number;
  listpop: any;
  tag: string;
  clear() {
    this.Goalname = '';
    this.actionName = '';
    this.goalCategory = '';
    this.sessionIdValue = false;
    this.visiIdValue = false;
    this.loginValue = false;
    this.type = '';
  }
}