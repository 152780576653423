import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-scenario',
  templateUrl: './scenario.component.html',
})
export class ScenarioComponent implements OnInit {
	constructor(
	) { }

	ngOnInit() {
	}
}










