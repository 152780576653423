// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
import { ScenarioConfigModel } from '../_models/scenarioConfigModel';
export enum ScenarioConfigActionTypes {
    ScenarioConfigOnServerCreated = '[Edit ScenarioConfig Dialog] ScenarioConfig On Server Created',
    ScenarioConfigCreated = '[Edit ScenarioConfig Dialog] ScenarioConfig Created',
    ScenarioConfigUpdated = '[Edit ScenarioConfig Dialog] ScenarioConfig Updated',
    ScenarioConfigsStatusUpdated = '[ScenarioConfigs List Page] ScenarioConfigs Status Updated',
    OneScenarioConfigDeleted = '[ScenarioConfigs List Page] One ScenarioConfig Deleted',
    Generate = '[ScenarioConfigs List Page] One ScenarioConfig Generate',
    Train = '[ScenarioConfigs List Page] One ScenarioConfig Train',

    UpdateStatusScenario = '[ScenarioConfigs List Page] One ScenarioConfig UpdateStatusScenario',

    ScenarioConfigsPageRequested = '[ScenarioConfigs List Page] ScenarioConfigs Page Requested',
    ScenarioConfigsDetailRequested = '[ScenarioConfigs detail sidebar] ScenarioConfigs detail Requested',
    ScenarioConfigsPageLoaded = '[ScenarioConfigs API] ScenarioConfigs Page Loaded',


    ScenarioConfigsPageCancelled = '[ScenarioConfigs API] ScenarioConfigs Page Cancelled',
    ScenarioConfigsPageToggleLoading = '[ScenarioConfigs] ScenarioConfigs Page Toggle Loading',
    ScenarioConfigActionToggleLoading = '[ScenarioConfigs] ScenarioConfigs Action Toggle Loading'
}

export class ScenarioConfigOnServerCreated implements Action {
    readonly type = ScenarioConfigActionTypes.ScenarioConfigOnServerCreated;
    constructor(public payload: { ScenarioConfig: ScenarioConfigModel }) { }
}

export class ScenarioConfigCreated implements Action {
    readonly type = ScenarioConfigActionTypes.ScenarioConfigCreated;
    constructor(public payload: { ScenarioConfig: ScenarioConfigModel }) { }
}

export class ScenarioConfigUpdated implements Action {
    readonly type = ScenarioConfigActionTypes.ScenarioConfigUpdated;
    constructor(public payload: {
        partialScenarioConfig: Update<ScenarioConfigModel>,
        ScenarioConfig: ScenarioConfigModel
    }) { }
}

export class ScenarioConfigsStatusUpdated implements Action {
    readonly type = ScenarioConfigActionTypes.ScenarioConfigsStatusUpdated;
    constructor(public payload: {
        ScenarioConfigs: ScenarioConfigModel[],
        status: number
    }) { }
}

export class OneScenarioConfigDeleted implements Action {
    readonly type = ScenarioConfigActionTypes.OneScenarioConfigDeleted;
    constructor(public payload: { id: number }) { }
}


export class Generate implements Action {
    readonly type = ScenarioConfigActionTypes.Generate;
    constructor(public payload: { id: number }) { }
}
export class Train implements Action {
    readonly type = ScenarioConfigActionTypes.Train;
    constructor(public payload: { id: number }) { }
}
export class ScenarioConfigsPageRequested implements Action {
    readonly type = ScenarioConfigActionTypes.ScenarioConfigsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class ScenarioConfigsPageLoaded implements Action {
    readonly type = ScenarioConfigActionTypes.ScenarioConfigsPageLoaded;
    constructor(public payload: { ScenarioConfigs: ScenarioConfigModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class ScenarioConfigsPageCancelled implements Action {
    readonly type = ScenarioConfigActionTypes.ScenarioConfigsPageCancelled;
}

export class ScenarioConfigsPageToggleLoading implements Action {
    readonly type = ScenarioConfigActionTypes.ScenarioConfigsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class ScenarioConfigActionToggleLoading implements Action {
    readonly type = ScenarioConfigActionTypes.ScenarioConfigActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class ScenarioConfigsDetailRequested implements Action {
    readonly type = ScenarioConfigActionTypes.ScenarioConfigsDetailRequested;
    constructor(public payload: { page: QueryParamsModel, id: string }) { }
}

export type ScenarioConfigActions = ScenarioConfigOnServerCreated
    | ScenarioConfigCreated
    | ScenarioConfigUpdated
    | ScenarioConfigsStatusUpdated
    | OneScenarioConfigDeleted
    | Generate
    | Train
    | ScenarioConfigsDetailRequested
    | ScenarioConfigsPageRequested
    | ScenarioConfigsPageLoaded
    | ScenarioConfigsPageCancelled
    | ScenarioConfigsPageToggleLoading
    | ScenarioConfigActionToggleLoading;
