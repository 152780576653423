// Angular
import { Component, Input, OnInit, ViewChild, Inject, ChangeDetectorRef, OnChanges } from '@angular/core';
import {
	ApexAxisChartSeries,
	ApexChart,
	ChartComponent,
	ApexDataLabels,
	ApexXAxis,
	ApexPlotOptions
} from "ng-apexcharts";
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { goalRedsService } from '../../../../../core/goal';
import { PopbygoalComponent } from '../popbygoal/popbygoal.component';

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	xaxis: ApexXAxis;
};

export interface Widget5Data {
	pic?: string;
	title: string;
	desc: string;
	url?: string;
	info?: string;
	largeInfo?: string;
}
@Component({ 
  selector: 'kt-visitoreventdashbord',
  templateUrl: './visitoreventdashbord.component.html',
  styleUrls: ['./visitoreventdashbord.component.scss']
})
export class VisitoreventdashbordComponent implements OnChanges {

  
  // Public properties
  @Input() type: string = 'line';
  @Input('chat') chat: any;
  @Input('idg') idgoal: any;
  // @ViewChild('chart', { static: true }) chart: ChartComponent;
  public chartOptions: any;
  somme: number = 0;

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   */
  constructor( private _cdr: ChangeDetectorRef,public goalRedsService:goalRedsService,		public dialog: MatDialog,

  ) {
    
  }
  changeStatus(): void {

    setTimeout(() => {

      this._cdr.detectChanges()
      this._cdr.markForCheck()

    }, 1500);
  }
  obj: any = '';
  gopop(x){
    let dialogRef = this.dialog.open(PopbygoalComponent, {
      data: { event: x,event2:this.idgoal, mode: 'edit' },
    });
    dialogRef.afterClosed().subscribe(result => {  
    });
  }
ngOnChanges() {
  if(this.chat){
    this.chartOptions = {
      series: [
        {
          name: "Inflation",
          data: this.chat.count,
        }
      ],
      colors: ["#8aa6ca"],
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true
        },
        events: {
          click:(event, chartContext, config) => {
            this.gopop(config.config.xaxis.categories[config.dataPointIndex]);
        }
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
       
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#d79910"]
        }
      },
      states: {
        hover: {
            filter: {
                type: 'none',
            }
        },
        normal: {
          filter: {
              type: 'none',
          }
      },
    },
      xaxis: {
        categories: this.chat.keys,
  
  
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1
            }
          }
        },
  
        tooltip: {
          enabled: true,
          offsetY: -35
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 0, 100, 100]
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          }
        }
      },
      title: {
        align: "center",
        style: {
          color: "#444"
        }
      }
    };
    this.changeStatus();

  }
  
}
}
