import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, NgZone, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { distinctUntilChanged, skip, take, delay } from 'rxjs/operators';
import { KpisDataSource, KpisPageRequested, KpiOnServerCreated, KpisService, OneKpiDeleted, ScenarioConfigModel, ScenarioConfigOnServerCreated, ScenarioConfigsService } from '../../../../core/scenario';
import { AppState } from '../../../../core/reducers';
import { Store } from '@ngrx/store';
import { Subscription, of } from 'rxjs';
import { EventConfigModel, EventConfigsDataSource, EventConfigsPageRequested } from '../../../../core/event';
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../core/_base/crud';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectionStrategy } from '@angular/core';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeUnderscore' })
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, args?: any): any {


    if (!value.replace(/_/g, " ")) {
      return '';
    } else {
      return value.replace(/_/g, " ").replace(/\b\w/g, first => first.toLocaleUpperCase())
    }
  }
}
@Component({
  selector: 'kt-scenario-edit',
  templateUrl: './scenario-edit.component.html',
  styleUrls: ['./scenario-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default, // or ChangeDetectionStrategy.OnPush

})
export class ScenarioEditComponent implements OnInit {
  id: string;
  data: any;
  item: string;
  @ViewChild('wizard', { static: true }) el: ElementRef;
  ScenarioConfigsResult: any[] = [];
  public myForm: FormGroup;
  dataSource: KpisDataSource;
  dataSourcetag: EventConfigsDataSource;
  @ViewChild('f', { static: true }) analyisForm: NgForm;
  private subscriptions: Subscription[] = [];
  EventConfigsResult: EventConfigModel[] = [];
  ok: boolean = false;
  @ViewChild(MatStepper, { static: false }) stepper: MatStepper;

  numberAvrage = "";
  average_time = "";
  constructor(
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public KpisService: KpisService,
    private _fb: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private ScenarioConfigsService: ScenarioConfigsService,
    private translate: TranslateService,
    public  _formBuilder:FormBuilder,private ngZone: NgZone,
    private router: Router, private _cdr: ChangeDetectorRef,
    private store: Store<AppState>) {
  }
  initAddress() {
    return this._fb.group({
      val: [''],
      tags: [''],
      key: [''],
      time: ['']
    });
  }

  tab: Array<any> = [];
  llll: any = {
    name:'',
    label:'',
    parameters:{n_estimators:''},
    goalCategory: ["Conversion"]
  };
  test2: string = '';
  login = false;
	Conversion = false;
	Churn = false;
	Engagement = false;
  sessioid = false;
	visitorid = false;
	popup = false;
	Coupons = false;
	ConversionClicked() {
		this.Conversion = true;
		this.Churn = false;
		this.Engagement = false;
		this.login = true;
		this.sessioid = false;
		this.visitorid = false;

	}
	ChurnClicked() {
		this.Churn = true;
		this.Conversion = false;
		this.Engagement = false;

		this.sessioid = true;
		this.visitorid = false;
		this.login = false;


	}
	EngagementClicked() {
		this.Engagement = true;
		this.Churn = false;
		this.Conversion = false;

		this.login = false;
		this.sessioid = false;
		this.visitorid = false;
	}
  public window = window.innerWidth;
	//get window width on any window size change
	@HostListener('window:resize')
	onWindowResize() {
	  this.window = window.innerWidth;
	  // console.log('Window inner width:', this.window);
	}
  public demo1TabIndex = 0;
	onSubmit() {
    if (this.id === "undefined") {
		const editedScenario = this.prepareScenario();
		this.createScenario(editedScenario); 
    }else{
      this.demo1TabIndex = 1;
    }
	}
  createScenario(_Scenario: ScenarioConfigModel) {
    // this.store.dispatch(new ScenarioConfigOnServerCreated({ ScenarioConfig: _Scenario }))
    this.ScenarioConfigsService.createScenarioConfig(_Scenario).subscribe((li:any)=>{
      console.log(li);
      this.id=li.id;
      this.get();

      this.demo1TabIndex = 1;


    })
	}
  prepareScenario(): ScenarioConfigModel {
		const _Scenario = new ScenarioConfigModel();
		_Scenario.titre = this.llll.name;
		_Scenario.description = this.llll.description;
		_Scenario.longterm_onetime = this.llll.longterm_onetime
		_Scenario.goalCategory =  this.llll.goalCategory;
		_Scenario.sessionIdValue = false;
		_Scenario.visiIdValue = false
		_Scenario.loginValue = false
		_Scenario.tag = this.llll.label;
		return _Scenario;
	}
  id2: string;
  get() {
    this.llll = [];
    if (this.id) {
      this.KpisService.findKpis(this.id).subscribe((res: any) => {
        this.llll = res;
        this.changeStatus2();
        console.log(res.config);
        // console.log(JSON.parse(res.config));

        // this.KpisService.detailScinario_config(this.id).subscribe(res => {
        for (const [key, value] of Object.entries(res.config)) {
          // console.log(`${key}: ${value}`);
          // Object.getOwnPropertyNames(res.config).forEach(key => {
          // if (key === 'id') {
          // let value = key;
          // this.changeStatus2();
          // this.id2 = value;
          // } else {
          // let value = key;
          this.tab[key] = value;
          // }
          // });
          // this.tab.length = res.config.length - 1;
        }
        console.log(
          this.tab
        );
        // })
      });
    }
  }
  ngOnInit() {

    this.KpisService.getnumber().subscribe(res => {
      if (res.avg_events_number) {
        this.numberAvrage = res.avg_events_number;
      } else {
        this.numberAvrage = null;
      }
      if (res.avg_time_spent) {
        this.average_time = res.avg_time_spent;
      } else {
        this.average_time = null;
      }
      // this.numberAvrage=res.avg_events_number;
      // this.average_time=res.avg_time_spent;
      this.changeStatus2();

    });
  
    this.gettag();

    this.route.params.subscribe(
      (params: Params) => {
        this.id = params['id'];
      });
    if (this.id != "undefined") {
      this.get();
    }
    this.myForm = this._fb.group({
      Numberofclicks: this._fb.array([
        this.initAddress(),
      ]),
    })

  }
  changeStatus2(): void {

    setTimeout(() => {

      this._cdr.detectChanges()
      this._cdr.markForCheck()

    }, 500);
  }
  gettag() {
    this.EventConfigsResult = [];
    this.dataSourcetag = new EventConfigsDataSource(this.store);
    const entitiesSubscription = this.dataSourcetag.entitySubject.pipe(
      skip(1),
      distinctUntilChanged()
    ).subscribe(res => {
      this.changeStatus2();
      this.EventConfigsResult = res;
    });
    this.subscriptions.push(entitiesSubscription);
    of(undefined).pipe(take(1), delay(1000)).subscribe(() => {
      this.loadEventConfigsList();
    });
  }
  loadEventConfigsList() {
    const queryParams = new QueryParamsModel(
      false,
      "asc",
    );
    this.store.dispatch(new EventConfigsPageRequested({ page: queryParams }));
  }
  loadScenarioConfigsList() {
    this.changeStatus2();
    this.store.dispatch(new KpisPageRequested({ list: this.id }));
  }
  items: FormArray;
  test: boolean = false
  nom: string;
  nomt: Array<any> = [];
  show(x) {
    this.ok = true;
  }
  notshow(x) {
    this.ok = false;
  }
  addNumberofclicks(x) {
    this.nom = x;
    this.nomt.push(x);
    const control = <FormArray>this.myForm.controls['Numberofclicks'];
    control.push(this.initAddress());

  }
  removeNumberofclicks(i: number, x) {
    const control = <FormArray>this.myForm.controls['Numberofclicks'];
    this.nomt.splice(i - 1, 1);
    control.removeAt(i);
  }
  delete(x) {
    const _title: string = this.translate.instant('Delete Config Scenario');
    const _description: string = this.translate.instant('Are you sure to permanently delete the Config Scenario');
    const _waitDesciption: string = this.translate.instant('Config Scenario has been deleted');
    const _deleteMessage = this.translate.instant('Config Scenario has been deleted');

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.store.dispatch(new OneKpiDeleted({ id: this.id, id2: this.id2, x: x }));
      this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
      Object.getOwnPropertyNames(this.tab).forEach(key => {
        if (key === x) {
          delete this.tab[x];
        }
      });



      this.gettag();
      this.get();
    });
  }


  NumberS: any;
  NumberP: any;
  Times: any;
  Single: any;

  tabNumClick: any[] = [];
  tabNumClick2: {};
  // inputNumOfClick() {
  //   var arrayControl = this.myForm.get("Numberofclicks") as FormArray;
  //   this.tabNumClick = [];
  //   this.tabNumClick2 = [];
  //   for (var i = 0; i < arrayControl.length; i++) {
  //     var formgroup = arrayControl.at(i) as FormGroup;
  //   }

  // }
  fin: Array<any> = [];
  save2() {

    const Pop2 = {
      "eventsNumber": +this.llll.eventsNumber,
      "n_estimators": +this.llll.parameters.n_estimators,
      "learning_rate": +this.llll.parameters.learning_rate,
      "verbose": +this.llll.parameters.verbose,
      "metric": this.llll.parameters.metric,
      "eval_metric": this.llll.parameters.eval_metric,
      "early_stopping_rounds": +this.llll.parameters.early_stopping_rounds,
      "boosting_type": this.llll.parameters.boosting_type,
      "sampling_ratio": +this.llll.parameters.sampling_ratio,
      "random_state": +this.llll.parameters.random_state,
      "name": this.llll.name,
      "description": this.llll.description,
    }
    this.KpisService.updateScinario(this.id, Pop2, this.tab).subscribe(res => {
      this.router.navigate(['/scenario/kpilist']);
    })
  }
  save() {
    // this.inputNumOfClick();
    console.log(this.tab);
    this.KpisService.putscinario(this.id, this.tab).subscribe(res => {
      this.changeStatus2();
    })
    // this.store.dispatch(new KpiOnServerCreated({ id: this.id, type: this.tab, champ: "" }));
    // this.router.navigate(['/scenario/kpilist']);
  }
}
