// Angular
import { Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef, OnChanges } from '@angular/core';
// Layout config
import { LayoutConfigService } from '../../../../../core/_base/layout';
import Chart from 'chart.js';
// import Chart from 'chart.heatmap.js';

import { VisitorsService } from '../../../../../core/visiteur/_services/visiteur.service';
import { goalRedsService } from '../../../../../core/goal';
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexXAxis,
	ApexDataLabels,
	ApexTitleSubtitle,
	ApexStroke,
	ApexGrid
} from "ng-apexcharts";

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	dataLabels: ApexDataLabels;
	grid: ApexGrid;
	stroke: ApexStroke;
	title: ApexTitleSubtitle;
	colors: any;
};
@Component({
	selector: 'kt-widget1',
	templateUrl: './widget1.component.html',
	styleUrls: ['./widget1.component.scss']
})
export class Widget1Component implements OnChanges {
	obj: any = '';
	bool: boolean = false;
	changeStatus(): void {

		setTimeout(() => {

			this._cdr.detectChanges()
			this._cdr.markForCheck()

		}, 1500);
	}
	// Public properties
	@Input() data: { labels: string[], datasets: any[] };
	@Input() type: string = 'line';
	// @ViewChild('chart', { static: true }) chart: ElementRef;
	@Input('chat') chat: any;
	@ViewChild("chart", { static: true }) chart: ChartComponent;
	public chartOptions: Partial<any>;
	// @Input('x') x: any;
	// @Input('date3') date3: any;
	// @Input('date4') date4: any;

	dataScores: number[] = [50, 20, 30, 70, 20];

	/**
	 * Component constructor
	 * @param layoutConfigService
	 */
	constructor(
		private layoutConfigService: LayoutConfigService,
		private VisitorsService: VisitorsService, private goalRedsService: goalRedsService,
		private _cdr: ChangeDetectorRef,
	) {
	}
	ngOnChanges() {
		this.obj = '';
		// this.goalRedsService.filter(this.idgoal, this.x, this.date3, this.date4).subscribe(list => {
		// let obj = JSON.parse(JSON.stringify(list));			
		this.obj = this.chat;
		this.changeStatus();
		let c
		if (this.obj.latest_accuracy * 100 <= 50) {
			c = '#FF7729'
		} else {
			if (this.obj.latest_accuracy * 100 > 50 && this.obj.latest_accuracy * 100 <= 70) {
				c = '#FFCC29'
			} else {
				c = '#00C521'
			}
		}
		this.chartOptions = {
			series: [Math.round(this.obj.latest_accuracy * 100)],
			chart: {
				height: 250,
				type: "radialBar",
				toolbar: {
					show: false,
				},
			},
			title: {
				text: 'AI Accuracy',
				align: 'left',
				margin: 10,
				floating: false,
				style: {
					fontSize: '14px',
					fontWeight: 'bold',
					// fontFamily:  undefined,
					color: '#0D1861'
				},
			},
			plotOptions: {
				radialBar: {
				  startAngle: -135,
				  endAngle: 225,
				  hollow: {
					margin: 0,
					size: "70%",
					background: "#fff",
					image: undefined,
					position: "front",
					dropShadow: {
					  enabled: true,
					  top: 3,
					  left: 0,
					  blur: 2,
					  opacity: 0.24,
					},
				  },
				  track: {
					background: "#fff",
					strokeWidth: "67%",
					margin: 0, // margin is in pixels
					dropShadow: {
					  enabled: true,
					  top: -3,
					  left: 0,
					  blur: 4,
					  opacity: 0.35,
					},
				  },
		
				  dataLabels: {
					show: true,
					name: {
					  offsetY: -10,
					  show: false,
					  color: "#888",
					  fontSize: "17px",
					},
					value: {
					  formatter: function (val) {
						return val + "%";
					  },
					  offsetY: 7,
					  color: c,
					  fontSize: "36px",
					  show: true,
					},
				  },
				},
			  },
			colors: [c],
			stroke: {
				lineCap: "round",
			},
			// labels: [""],
		};
		// });
		// this.initChart(); 
	}

}