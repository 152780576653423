import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { RecordModel } from '../_models/RecordModel';
import * as firebase from 'firebase';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../environments/environment';

const API_Record_URL = 'api/Records';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class RecordsService {
	token: string;
    today: number = Date.now();
	today2: any;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService,public datePipe: DatePipe,private firestore: AngularFirestore) {
		this.today2 = datePipe.transform(this.today, 'MMM d, y, h:mm:ss a');

	 }
	 get() {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.API_URL+'/users/user_claims', { headers: headers });
	}
	getlog(eid: string, scinarioid: string): Observable<any> {
		console.log(scinarioid);
		console.log("ejjejejejejjejeejj");
		
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		const f = this.firestore.collection<any>('enterprises').doc(eid).collection("logs", ref => {
			return ref.orderBy("date", "desc").where("id", "==", scinarioid).where("type", "==", 'scenario') 
		}).valueChanges()
		f.subscribe(res => {
			console.log(res);

		})
		return f;
	}
	createRecord(Record: RecordModel): Observable<RecordModel> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post<RecordModel>(environment.API_URL+"/Records", Record, { headers: headers });
	}

	getAllRecords(): Observable<RecordModel[]> {
		return this.http.get<RecordModel[]>(environment.API_URL+'/cars');
	}

	getRecordById(RecordId: number): Observable<RecordModel> {
		return this.http.get<RecordModel>(API_Record_URL + `/${RecordId}`);
	}

	findRecords(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get<QueryResultsModel>(environment.API_URL+'/enterprises/db_status',
		 { headers: headers });
	}

	updateRecord(Record: RecordModel): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post(environment.API_URL+'/enterprises/db_status?db='
			+ Record.db + '&status=' + Record.status+ '&last_activated=' + this.today2, { option: false }, { headers: headers });			
	}
	  
	// 
	

	// this.scoringed(false);
	// this.toggled(false);


	updateRecord2(Record: any, type: string): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		if (type === "score") {
			return this.http.post(environment.API_URL+'/enterprises/db_status?calculate_score='
				+ Record,  { headers: headers });
		}else if(type === "toggle"){
			return this.http.post(environment.API_URL+'/enterprises/db_status?trigger_action='
				+ Record,  { headers: headers });
		}else if(type === "GDPR"){
			return this.http.post(environment.API_URL+'/enterprises/db_status?gdpr='
				+ Record,  { headers: headers });
		} 

	}


	updateStatusForRecord(Records: RecordModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			RecordsForUpdate: Records,
			newStatus: status
		};
		const url = API_Record_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	deleteRecord(RecordId: number): Observable<RecordModel> {
		return this.http.delete<RecordModel>(environment.API_URL+'/cars' + '/' + RecordId);
	}

	deleteRecords(ids: number[] = []): Observable<any> {
		const url = API_Record_URL + '/deleteRecords';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { RecordIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders });
	}
}
