import { select } from '@ngrx/store';
// Angular
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Component, OnInit, Inject, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
import { distinctUntilChanged, skip, delay, take } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../core/_base/crud';
import { TypesUtilsService } from '../../../../../app/core/_base/crud';
import { Router } from '@angular/router';
import { ScenarioConfigModel, ScenarioConfigOnServerCreated, selectScenarioConfigsActionLoading } from '../../../../core/scenario';
import { EventConfigModel, EventConfigsDataSource, EventConfigsPageRequested, OneEventConfigDeleted, EventConfigUpdated } from '../../../../core/event';
import { orderBy } from 'lodash';
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
 name: "orderBy"
})
export class OrderByPipe implements PipeTransform {
	transform(array: any, field: string): any[] {
		if (!Array.isArray(array)) {
		  return;
		}
		array.sort((a: any, b: any) => {
		  if (a[field] < b[field]) {
			return -1;
		  } else if (a[field] > b[field]) {
			return 1;
		  } else {
			return 0;
		  }
		});
		return array;
	  }
}

// import { ScenarioConfigsService } from '../../../../core/scenario/_services/ScenarioConfig.service';
@Component({
	selector: 'kt-scenario-add',
	templateUrl: './scenario-add.component.html',
	styleUrls: ['./scenario-add.component.scss']
})
export class ScenarioAddComponent implements OnInit {

	sessioid = false;
	visitorid = false;
	login = false;
	Conversion = false;
	Churn = false;
	Engagement = false;

	popup = false;
	Coupons = false;
	link = false;
	Product = false;
	Email = false;

	sessionIdValue = false;
	visiIdValue = false;
	loginValue = false;
	model1 = 1;

	hasFormErrors: boolean = false;
	scenario: ScenarioConfigModel;
	Scenario: ScenarioConfigModel;
	ScenarioConfigForm: FormGroup;
	dataSource: EventConfigsDataSource;
	viewLoading: boolean = false;
	private subscriptions: Subscription[] = [];
	EventConfigsResult: EventConfigModel[] = [];
	private componentSubscriptions: Subscription;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<ScenarioEditDialogComponent>
	 * @param data: any
	 * @param fb: FormBuilder
	 * @param store: Store<AppState>
	 * @param typesUtilsService: TypesUtilsService
	 */
	constructor(
		public dialogRef: MatDialogRef<ScenarioAddComponent>,
		private fb: FormBuilder,
		// private scenarioService: ScenarioConfigsService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private store: Store<AppState>,
	) { }


	ConversionClicked() {
		this.Conversion = true;
		this.Churn = false;
		this.Engagement = false;
		this.login = true;
		this.sessioid = false;
		this.visitorid = false;

	}
	ChurnClicked() {
		this.Churn = true;
		this.Conversion = false;
		this.Engagement = false;

		this.sessioid = true;
		this.visitorid = false;
		this.login = false;


	}
	EngagementClicked() {
		this.Engagement = true;
		this.Churn = false;
		this.Conversion = false;

		this.login = false;
		this.sessioid = false;
		this.visitorid = false;
	}

	/**
	 * On init
	 */
	ngOnInit() {
		this.get();
		this.store.pipe(select(selectScenarioConfigsActionLoading)).subscribe(res => this.viewLoading = res);
		this.Scenario = this.data.Scenario;
		this.createForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	createForm() {
		this.ScenarioConfigForm = this.fb.group({
			titre: ["", Validators.required],
			description: ["", Validators.required],
			longterm_onetime: [false, Validators.required],
			goalCategory: ["Conversion"],
			tag: ["", Validators.required],
			champ1: [10],
			val1: [true],
			val2: [true],
			val3: [true],
			val4: [false],
			val5: [false],
			val6: [false],
			champ2: [60],
			champ3: [120],
			champ4: [0],
			champ5: [0],
			champ6: [0],
		});
	}
	chowyesno: boolean = false;
	sesionidClicked() {
		console.log(this.sessionIdValue);
		
		this.chowyesno = false;
		this.sessionIdValue = !this.sessionIdValue;
	}

	visiidClicked() {
		this.chowyesno = true;
		this.visiIdValue = !this.visiIdValue
	}

	loginClicked() {
		this.loginValue = !this.loginValue
	}
	get() {
		this.dataSource = new EventConfigsDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.EventConfigsResult = res;
		});
		this.subscriptions.push(entitiesSubscription);
		of(undefined).pipe(take(1), delay(1000)).subscribe(() => {
			this.loadEventConfigsList();
		});
	}
	loadEventConfigsList() {
		const queryParams = new QueryParamsModel(
			false,
			"asc",
		);
		this.store.dispatch(new EventConfigsPageRequested({ page: queryParams }));
	}

	/**
	 * Check control is invalid
	 * @param controlName: string
	 */
	prepareScenario(): ScenarioConfigModel {
		const controls = this.ScenarioConfigForm.controls;
		const _Scenario = new ScenarioConfigModel();
		_Scenario.titre = controls['titre'].value;
		_Scenario.description = controls['description'].value;
		_Scenario.longterm_onetime = controls['longterm_onetime'].value;
		_Scenario.goalCategory = controls['goalCategory'].value;
		_Scenario.sessionIdValue = this.sessionIdValue;
		_Scenario.visiIdValue = this.visiIdValue;
		_Scenario.loginValue = this.loginValue;
		_Scenario.tag = controls['tag'].value;

		// _Scenario.Pop2 = [
		// 	{
		// 		'eventsNumber': controls['champ1'].value,
		// 		'active': controls['val1'].value,
		// 	},
		// 	{
		// 		'eventsNumber': controls['champ2'].value,
		// 		'active': controls['val2'].value,
		// 	},
		// 	{
		// 		'eventsNumber': controls['champ3'].value,
		// 		'active': controls['val3'].value,
		// 	},
		// 	{
		// 		'eventsNumber': controls['champ4'].value,
		// 		'active': controls['val4'].value,
		// 	},
		// 	{
		// 		'eventsNumber': controls['champ5'].value,
		// 		'active': controls['val5'].value,
		// 	},
		// 	{
		// 		'eventsNumber': controls['champ6'].value,
		// 		'active': controls['val6'].value,
		// 	},
		// ]
		return _Scenario;
	}
	/**
	 * On Submit
	 */
	onSubmit() {
		this.hasFormErrors = false;
		const controls = this.ScenarioConfigForm.controls;
		/** check form */ 
		if (this.ScenarioConfigForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.hasFormErrors = true;
			return;
		}
		const editedScenario = this.prepareScenario();
		this.createScenario(editedScenario); 
		this.onClose();

	}

	createScenario(_Scenario: ScenarioConfigModel) {
		this.store.dispatch(new ScenarioConfigOnServerCreated({ ScenarioConfig: _Scenario }));
	}

	onClose() {
		this.dialogRef.close();
	}
	onAlertClose($event) {
		this.hasFormErrors = false;
	}

}
