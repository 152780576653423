import { BaseModel } from '../../_base/crud';

export class TriggerModel extends BaseModel {
  db: string;
  status_fs: string;
  status_bq: string;
  status: string;
	token: string ;
 
  clear() {
    this.db = '';
    this.status = '';
    this.token = '';
  }

  public TriggerModel(x:TriggerModel){
    this.status_bq=x.status_bq;
    this.status_fs=x.status_fs
  }
}