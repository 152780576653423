// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { KpiModel } from '../_models/KpiModel';

export enum KpiActionTypes {
    KpiOnServerCreated = '[Edit Kpi Dialog] Kpi On Server Created',
    KpiCreated = '[Edit Kpi Dialog] Kpi Created',
    KpiUpdated = '[Edit Kpi Dialog] Kpi Updated',
    KpisStatusUpdated = '[Kpis List Page] Kpis Status Updated',
    OneKpiDeleted = '[Kpis List Page] One Kpi Deleted',

    KpisPageRequested = '[Kpis List Page] Kpis Page Requested',
    KpisPageLoaded = '[Kpis API] Kpis Page Loaded',


    KpisPageCancelled = '[Kpis API] Kpis Page Cancelled',
    KpisPageToggleLoading = '[Kpis] Kpis Page Toggle Loading',
    KpiActionToggleLoading = '[Kpis] Kpis Action Toggle Loading'
}

export class KpiOnServerCreated implements Action {
    readonly type = KpiActionTypes.KpiOnServerCreated;
    constructor(public payload: { id:string, type: any[],champ:string }) { }
}

export class KpiCreated implements Action {
    readonly type = KpiActionTypes.KpiCreated;
    constructor(public payload: { Kpi: KpiModel }) { }
}

export class KpiUpdated implements Action {
    readonly type = KpiActionTypes.KpiUpdated;
    constructor(public payload: {
        partialKpi: Update<KpiModel>, 
        Kpi: KpiModel 
    }) { }
}

export class KpisStatusUpdated implements Action {
    readonly type = KpiActionTypes.KpisStatusUpdated;
    constructor(public  payload: {
        Kpis: KpiModel[],
        status: number
    }) { }
}

export class OneKpiDeleted implements Action {
    readonly type = KpiActionTypes.OneKpiDeleted;
    constructor(public payload: { id: any, id2: any, x :any }) {}
}


export class KpisPageRequested implements Action {
    readonly type = KpiActionTypes.KpisPageRequested;
    constructor(public payload: { list: any }) { }
}

export class KpisPageLoaded implements Action {
    readonly type = KpiActionTypes.KpisPageLoaded;
    constructor(public payload: any) { }
}

export class KpisPageCancelled implements Action {
    readonly type = KpiActionTypes.KpisPageCancelled;
}

export class KpisPageToggleLoading implements Action {
    readonly type = KpiActionTypes.KpisPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class KpiActionToggleLoading implements Action {
    readonly type = KpiActionTypes.KpiActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type KpiActions = KpiOnServerCreated
| KpiCreated
| KpiUpdated
| KpisStatusUpdated
| OneKpiDeleted
| KpisPageRequested
| KpisPageLoaded
| KpisPageCancelled
| KpisPageToggleLoading
| KpiActionToggleLoading