import { mergeMap, tap } from 'rxjs/operators';
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
import { AppState } from '../../reducers';
import { selectScenarioConfigsInStore, selectScenarioConfigsPageLoading, selectScenarioConfigsShowInitWaitingMessage } from '../_selectors/scenarioConfig.selectors';


export class ScenarioConfigsDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();

		this.loading$ = this.store.pipe(
			select(selectScenarioConfigsPageLoading),
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectScenarioConfigsShowInitWaitingMessage)
		);

		this.store.pipe(
			select(selectScenarioConfigsInStore),
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});

	}
}