import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
import { goalRedModel } from '../_models/goalRedModel';
import { goalRedState } from '../_reducers/goalRed.reducers';

export const selectgoalRedsState = createFeatureSelector<goalRedState>('goalReds');

export const selectgoalRedById = (goalRedId: number) => createSelector(
    selectgoalRedsState,
    goalRedState => goalRedState.entities[goalRedId]
);

export const selectgoalRedsPageLoading = createSelector(
    selectgoalRedsState,
    goalRedState => goalRedState.listLoading
);

export const selectgoalRedsActionLoading = createSelector(
    selectgoalRedsState,
    goalRedState => goalRedState.actionsloading
);

export const selectLastCreatedgoalRedId = createSelector(
    selectgoalRedsState,
    goalRedState => goalRedState.lastCreatedgoalRedId
);

export const selectgoalRedsShowInitWaitingMessage = createSelector(
    selectgoalRedsState,
    goalRedState => goalRedState.showInitWaitingMessage
);

export const selectgoalRedsInStore = createSelector(
    selectgoalRedsState,
    goalRedState => {
        const items: goalRedModel[] = [];
        each(goalRedState.entities, element => {
            items.push(element);
        });
        return new QueryResultsModel(items, goalRedState.totalCount, '');
    }
);

