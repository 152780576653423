import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, map, tap, delay } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryRealtimeParamsModel } from '../../_base/crud';
import { KpisService } from '../_services';
import { AppState } from '../../reducers';
import {

    KpiActionToggleLoading,
    KpiActionTypes,
    KpisPageLoaded,
    KpisPageRequested,
    KpisPageToggleLoading,
    OneKpiDeleted,
    KpiOnServerCreated,
    KpiCreated,

} from '../_actions/kpi.actions';
import { of } from 'rxjs';

@Injectable()
export class KpiEffects {
    showPageLoadingDistpatcher = new KpisPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new KpiActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new KpiActionToggleLoading({ isLoading: false });
    @Effect()
    loadKpisPage$ = this.actions$.pipe(
        ofType<KpisPageRequested>(KpiActionTypes.KpisPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            return this.KpisService.findKpis(payload.list);
 
        }),
        map(response => {
            const result: QueryRealtimeParamsModel = response;
            const pageLoadedDispatch = new KpisPageLoaded({
                Kpis: result
            });
            return pageLoadedDispatch;
        })
    );
    @Effect()
    createKpi$ = this.actions$
        .pipe(
            ofType<KpiOnServerCreated>(KpiActionTypes.KpiOnServerCreated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.KpisService.createKpi(payload.id, payload.type, payload.champ).pipe(
                    tap(res => {  
                        console.log(res);
                        this.store.dispatch(new KpiCreated({ Kpi:res  }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );
    
    @Effect()
    deleteCustomer$ = this.actions$
        .pipe(
            ofType<OneKpiDeleted>(KpiActionTypes.OneKpiDeleted),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.KpisService.deleteKpi(payload.id,payload.id2,payload.x);
            }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private KpisService: KpisService, private store: Store<AppState>) { }

}
