import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { EventCapturedModel } from '../_models/EventCapturedModel';
import * as firebase from 'firebase';
import { environment } from '../../../../environments/environment';
 
const API_EventCaptured_URL = 'api/EventCaptureds';

@Injectable()
export class EventCapturedsService {
	token :string;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }
	createEventCaptured(EventCaptured: EventCapturedModel): Observable<EventCapturedModel> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<EventCapturedModel>(API_EventCaptured_URL, EventCaptured, { headers: httpHeaders});
	}
	getthreechamp() {
		return this.http.get(environment.reporting+"/visitors/visitor_metrics");
	  }
	
		getAllEventCaptureds(): Observable<EventCapturedModel[]> {
		return this.http.get<EventCapturedModel[]>(environment.API_URL+'/cars');
	}

	getEventCapturedById(EventCapturedId: number): Observable<EventCapturedModel> {
		return this.http.get<EventCapturedModel>(API_EventCaptured_URL + `/${EventCapturedId}`);
	}

	findEventCaptureds(queryParams: QueryParamsModel): Observable<QueryResultsModel> {			
		let headers = new HttpHeaders();   		
		headers = headers.append("Content-Type", "application/json");
		return this.http.get<QueryResultsModel>(environment.API_URL+'/enterprises/events_bq', { headers: headers });
	}

	updateEventCaptured(EventCaptured: EventCapturedModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_EventCaptured_URL, EventCaptured, { headers: httpHeader });
	}

	updateStatusForEventCaptured(EventCaptureds: EventCapturedModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			EventCapturedsForUpdate: EventCaptureds,
			newStatus: status
		};
		const url = API_EventCaptured_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	deleteEventCaptured(EventCapturedId: number): Observable<EventCapturedModel> {
		return this.http.delete<EventCapturedModel>(environment.API_URL+'/cars'+'/'+EventCapturedId);
	}

	deleteEventCaptureds(ids: number[] = []): Observable<any> {
		const url = API_EventCaptured_URL + '/deleteEventCaptureds';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { EventCapturedIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}
}
