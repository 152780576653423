import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { KpiaddActions, KpiaddActionTypes } from '../_actions/kpiadd.actions';
import { KpiModel } from '../_models/kpiModel';
import { QueryParamsModel } from '../../_base/crud';

export interface KpiaddState extends EntityState<KpiModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedKpiaddId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<KpiModel> = createEntityAdapter<KpiModel>();

export const initialKpiaddState: KpiaddState = adapter.getInitialState({
    KpiaddForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedKpiaddId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function KpiaddsReducer(state = initialKpiaddState, action: KpiaddActions): KpiaddState {
    switch  (action.type) {
        case KpiaddActionTypes.KpiaddsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedKpiaddId: undefined
            };
        }    

        case KpiaddActionTypes.KpiaddActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }

        case KpiaddActionTypes.KpiaddOnServerCreated: return {
            ...state
        };

        case KpiaddActionTypes.KpiaddUpdated: return adapter.updateOne(action.payload.partialKpiadd, state);

        case KpiaddActionTypes.OneKpiaddDeleted: return adapter.removeOne(action.payload.id, state);

        case KpiaddActionTypes.KpiaddsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }

        case KpiaddActionTypes.KpiaddsPageLoaded: {
            return adapter.addOne(action.payload.Kpiadds, {
                ...initialKpiaddState,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}



export const getKpiaddState = createFeatureSelector<KpiModel>('Kpiadds');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
