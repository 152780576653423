import { mergeMap, tap } from 'rxjs/operators';
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
import { AppState } from '../../reducers';
import { selectRecordsInStore, selectRecordsPageLoading, selectRecordsShowInitWaitingMessage } from '../_selectors/Record.selectors';


export class RecordsDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
        super();

		this.loading$ = this.store.pipe(
			select(selectRecordsPageLoading),
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectRecordsShowInitWaitingMessage)
        );
        
        this.store.pipe(
			select(selectRecordsInStore),
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});

    }
}