// Angular
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase';
import { AuthService } from '../_services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  response: any;
  uid: any;
  eid: string;
  constructor(public ngZone: NgZone, public afAuth: AngularFireAuth, private router: Router, private auth: AuthService, public http: HttpClient) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user: firebase.User) => {
        if (user) {
          //  resolve(true);
          firebase.auth().currentUser.getIdTokenResult().then(res => {
            if (res.claims.eid == null) {
              this.ngZone.run(async () => {
                this.router.navigate(['/auth/addEntreprise']);
              });
              resolve(false);
            } else {
              resolve(true);
            }
          })
        } else {
          this.ngZone.run(async () => {
            window.location.assign('/auth/login');
          });
          resolve(false);
        }
      });
    });

  }

}
