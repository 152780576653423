import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatPaginator, MatSort, MatSnackBar, MatDialog, MatTableDataSource } from '@angular/material';
import { distinctUntilChanged, skip, delay, take } from 'rxjs/operators';
import { Subscription, of, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../core/_base/crud';
import { ScenarioConfigModel, ScenarioConfigsDataSource, ScenarioConfigsPageRequested, OneScenarioConfigDeleted, Generate, Train, ScenarioConfigUpdated } from '../../../../core/scenario';

import { ScenarioAddComponent } from '../scenario-add/scenario-add.component';
import { Update } from '@ngrx/entity';
import { Router } from '@angular/router';
import { RecordModel, BqRecordsDataSource, RecordsPageRequested, RecordsDataSource, BqRecordsPageRequested, RecordUpdated, RecordsService, EventConfigModel, EventConfigsDataSource, EventConfigsPageRequested } from '../../../../core/event';

import { timer } from 'rxjs';
import { windowTime, tap, mergeAll } from 'rxjs/operators';

//emit immediately then every 1s

import { NgZone } from '@angular/core';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { Widget14Component } from '../../../../../app/views/partials/content/widgets/widget14/widget14.component';
import { Widget5Component } from '../../../../../app/views/partials/content/widgets/widget5/widget5.component';
import { ScenarioDetailComponent } from '../scenario-detail/scenario-detail.component';
import swal from 'sweetalert2';
import { LoaderComponent } from './loader/loader.component';
import { Modelvideo2Component } from '../../setup/setup/modelvideo2/modelvideo2.component';

@Component({
	selector: 'kt-scenario-list',
	templateUrl: './scenario-list.component.html',
	styleUrls: ['./scenario-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ScenarioListComponent implements OnInit, OnDestroy {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	listevents: any;
	displayedColumns = ['checkbox', 'name', 'label', 'description', 'latest_accuracy', 'latest_update', "action"];
	listData: MatTableDataSource<any>;
	sssss = 'All'
	dataSource: ScenarioConfigsDataSource;
	// displayedColumns = ['id', 'lastName', 'firstName', 'actions'];
	// @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	// @ViewChild('sort1', { static: true }) sort: MatSort;
	filterStatus = '';
	item: string;
	filterType = '';
	p: string;
	obj: Boolean;
	ScenarioConfigsResult: any[] = null;
	ScenarioConfigsResultActive: any[] = null;
	fini: any[] = null;
	EventConfigsResultfiltere: any[] = null;
	gotopopuo3() {
		this.dialog.open(Modelvideo2Component, {
		  data: {},
		  // width: '440px'
		});
	  }
	// Subscriptions
	private subscriptions: Subscription[] = [];
	hmd = new ScenarioConfigModel();
	msg: any = "";
	chararray = new BehaviorSubject([]);
	// listData: MatTableDataSource<any>;
	status: boolean = false;
	y: any;
	z: any;

	activate: boolean = true;
	stopactivate: boolean = true;
	RecordsResult: any[] = [];
	dataSourceRecords: RecordsDataSource;
	constructor(
		private firestore: AngularFirestore,
		private _cdr: ChangeDetectorRef,
		private ref: ChangeDetectorRef,
		public zone: NgZone,
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
		private RecordsService: RecordsService,
		private router: Router,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
		private store: Store<AppState>
	) { }

	ngOnInit() {
		this.getStatusInit();
		this.get();
		this.gettag();

	}
	// abstract markForCheck(): void
	// abstract detach(): void
	// abstract detectChanges(): void
	// abstract checkNoChanges(): void
	// abstract reattach(): void
	type(x) {
		this.fini = [];
		for (let index = 0; index < this.EventConfigsResultfiltere.length; index++) {
			if (x.toString().toLocaleLowerCase() === this.EventConfigsResultfiltere[index].label.toString().toLocaleLowerCase()) {
				this.fini.push(this.EventConfigsResultfiltere[index]);
			} if (x === 'All') {
				this.fini = this.EventConfigsResultfiltere;
			}
		}
	}
	changeStatus(): void {
		this.msg = "";

		setTimeout(() => {
			this.msg = "";

			this.status = true;
			this._cdr.detectChanges()
			this._cdr.markForCheck()

		}, 1500);
	}
	getStatusInit() {
		this.dataSourceRecords = new RecordsDataSource(this.store);
		const entitiesSubscription = this.dataSourceRecords.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe((res: any) => {
			for (let index = 0; index < res.length; index++) {
				this.RecordsResult = res[0];
				if (res[0].calculate_score == false) {
					this.activate = false;

				} else if (res[0].calculate_score == true) {
					this.activate = true;
				}
				this.changeStatus();
			}
		});
		this.subscriptions.push(entitiesSubscription);
		of(undefined).pipe(take(1), delay(1000)).subscribe(() => {
			this.loadRecordsList();
		});
	}
	get() {
		this.generation = false;
		this.id = '';
		this.dataSource = new ScenarioConfigsDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.getactive(res);

			this.listData = new MatTableDataSource(res);
			this.listData.paginator = this.paginator;
		});
		this.subscriptions.push(entitiesSubscription);
		of(undefined).pipe(take(1), delay(1000)).subscribe(() => {
			this.loadScenarioConfigsList();
		});
	}
	getactive(list) {
		this.ScenarioConfigsResult = [];
		this.ScenarioConfigsResultActive = [];
		this.fini = [];
		this.EventConfigsResultfiltere = [];
		for (let index = 0; index < list.length; index++) {
			const element = list[index];
			if (element.status === true) {
				this.ScenarioConfigsResultActive.push(element);

			} else {
				this.ScenarioConfigsResult.push(element);
			}
		}
		for (let index = 0; index < this.ScenarioConfigsResultActive.length; index++) {
			const element = this.ScenarioConfigsResultActive[index];
			this.fini.push(element);
			this.EventConfigsResultfiltere.push(element);
		}
		for (let index = 0; index < this.ScenarioConfigsResult.length; index++) {
			const element = this.ScenarioConfigsResult[index];
			this.fini.push(element);
			this.EventConfigsResultfiltere.push(element);
		}
		this.listData = new MatTableDataSource(this.fini);
		this.listData.sort = this.sort;
		this.listData.paginator = this.paginator;
		this.changeStatus();
	}
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	loadScenarioConfigsList() {
		const queryParams = new QueryParamsModel(
			false,
			"desc",
		);
		this.store.dispatch(new ScenarioConfigsPageRequested({ page: queryParams }));
	}

	deleteScenarioConfig(_item: ScenarioConfigModel) {

		const _title: string = this.translate.instant('Delete Scenario');
		const _description: string = this.translate.instant('Are you sure to permanently delete the Scenario');
		const _waitDesciption: string = this.translate.instant('Scenario has been deleted');
		const _deleteMessage = this.translate.instant('Scenario has been deleted');

		// const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		// dialogRef.afterClosed().subscribe(res => {
		// 	if (!res) {
		// 		return;
		// 	}
		// 	this.get();
		// 	this.changeStatus();
		// 	this.store.dispatch(new OneScenarioConfigDeleted({ id: _item.id }));
		// 	this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		// });




		swal.fire({
			html: '<img src="assets/ae7bb64f9f4b32dbc14a78d66bc78fa3.png" style="width: 60px;"> <br> <br> <b> <div style="color:#0D1861;"> Are you sure to permanently delete the Scenario ? </div>  </b>',
			customClass: {
				popup: 'border-radius-40',
				icon: 'no-border'
			},
			showCancelButton: true,
			confirmButtonColor: '#7070706b',
			cancelButtonColor: '#FFCC29',
			confirmButtonText: 'Cancel',
			cancelButtonText: 'Yes',
			
		}).then((result: any) => {
			console.log(result);
			
			if (result.dismiss == "cancel") {
			
				this.get();
				this.changeStatus();
				this.store.dispatch(new OneScenarioConfigDeleted({ id: _item.id }));
				this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
			}
		})
	}
	chart(_item: ScenarioConfigModel) {
		let dialogRef = this.dialog.open(Widget14Component, {
			data: { event: _item.graph, mode: 'edit' },
		});

		dialogRef.afterClosed().subscribe(result => {

		});
	}
	chart2(_item: ScenarioConfigModel) {

		let dialogRef = this.dialog.open(Widget5Component, {
			data: { event: _item.importance_dict, mode: 'edit' },
		});

		dialogRef.afterClosed().subscribe(result => {

		});
	}
	prepareUser(x): ScenarioConfigModel {

		const _user = new ScenarioConfigModel();
		_user.clear();
		_user.id = x.id;
		_user.titre = x.titre;
		_user.description = x.description;
		_user.goalCategory = x.use_case;
		_user.status = !x.status;


		_user.accuracy = x.accuracy;
		_user.kpi = x.kpis;
		return _user;
	}

	log(_item: ScenarioConfigModel) {
		let dialogRef = this.dialog.open(ScenarioDetailComponent, {
			data: { event: _item.id, mode: 'edit' },
		});

		dialogRef.afterClosed().subscribe(result => {

		});
	}

	clicked(_user: ScenarioConfigModel) {
		if (_user.status === true) {
			swal.fire({
				position: 'top-end',
				icon: 'success',
				text: 'Scenario is disactivated successfully',
				showConfirmButton: false,
				timer: 1500
			});
		} else {
			swal.fire({
				position: 'top-end',
				icon: 'success',
				text: 'Scenario is activated successfully',
				showConfirmButton: false,
				timer: 1500
			});
		}
		const editedCustomer = this.prepareUser(_user);
		this.toggleView(editedCustomer);
		// '/scenario/kpilist',_user.id]"
	}

	toggleView(_customer: ScenarioConfigModel) {
		const updateCustomer: Update<ScenarioConfigModel> = {
			id: _customer.id,
			changes: _customer
		};
		this.store.dispatch(new ScenarioConfigUpdated({
			partialScenarioConfig: updateCustomer,
			ScenarioConfig: _customer
		}));
		// this.router.navigate(['/scenario/kpilist', _customer.id]);
	}

	// abstract markForCheck(): void
	// abstract detach(): void
	// abstract detectChanges(): void
	// abstract checkNoChanges(): void
	// abstract reattach(): void
	generation: boolean;
	id: string = '';
	Genrate(id, i) {
		let x = false;
		this.RecordsService.get().subscribe((res: any) => {
			const dialogRef = this.dialog.open(LoaderComponent, {
				disableClose: true // This will prevent the dialog from closing by user action
			  });
			this.RecordsService.get().subscribe((res: any) => {
				const f = this.firestore.collection<any>('enterprises').doc(res.user_data.claims.eid).collection("scenarios").doc(id).valueChanges();
				f.subscribe((list: any) => {
					console.log(list.generating == false && list.training == false);
					console.log(list.generating);
					console.log(list.training);

					if (list.generating == false && x == true && list.training == false) {
						console.log("eeeeeeeeeeeeeee");
						dialogRef.close();
						this.get();

					} else {
						console.log("lelelelelellelelel");
						x = true
					}

					this.id = id;
					this.y = list.generating;
					this.changeStatus();
				})
			});
			// this.get()			

			this.zone.run(() => {
				this.status = false;
				this.id = id;
				this.store.dispatch(new Generate({ id: id }));
				this.store.dispatch(new Train({ id: id }));
				this.msg = "Data generating...";
				this.get();

			})
			this.id = id;

			this.msg = "";
			this.get();
			// ChangeDetectorRef.markForCheck(
			// Close the popup automatically after API result is received
		});




	}
	public window = window.innerWidth;
	//get window width on any window size change
	@HostListener('window:resize')
	onWindowResize() {
		this.window = window.innerWidth;
		// console.log('Window inner width:', this.window);
	}
	EventConfigsResult: EventConfigModel[] = [];
	dataSourcetag: EventConfigsDataSource;

	gettag() {
		this.EventConfigsResult = [];
		this.dataSourcetag = new EventConfigsDataSource(this.store);
		const entitiesSubscription = this.dataSourcetag.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.changeStatus();
			this.EventConfigsResult = res;
		});
		this.subscriptions.push(entitiesSubscription);
		of(undefined).pipe(take(1), delay(1000)).subscribe(() => {
			this.loadEventConfigsList();
		});
	}
	loadEventConfigsList() {
		const queryParams = new QueryParamsModel(
			false,
			"asc",
		);
		this.store.dispatch(new EventConfigsPageRequested({ page: queryParams }));
	}

	Train(id, i) {

		this.RecordsService.get().subscribe((res: any) => {
			const f = this.firestore.collection<any>('enterprises').doc(res.user_data.claims.eid).collection("scenarios").doc(id).valueChanges();
			f.subscribe((list: any) => {
				this.id = id;
				this.z = list.training;
				this.get();
				this.changeStatus();
			})
		});

		this.zone.run(() => {
			this.status = false;
			this.id = id;
			this.get();


			this.get();

		})
		this.id = id;

		this.msg = "";
		this.get();
		this.get();
		// ChangeDetectorRef.markForCheck(



	}

	loadRecordsList() {
		const queryParams = new QueryParamsModel(
			false,
			"asc",
		);
		this.store.dispatch(new RecordsPageRequested({ page: queryParams }));
	}
	// activated() {
	// 	const editedCustomer = this.prepareUser("on");
	// 	this.toggleView(editedCustomer);
	// 	this.activate = true;
	// 	this.stopactivate = false;
	// 	this.getStatusInit();

	// }
	// deactivate() {
	// 	const editedCustomer = this.prepareUser("off");
	// 	this.toggleView(editedCustomer);
	// 	this.getStatusInit();

	// 	this.activate = false;
	// 	this.stopactivate = true;
	// }
	addKPI() {
		const newCustomer = new ScenarioConfigModel();
		newCustomer.clear();
		this.OpenAddKpi(newCustomer);
	}
	OpenAddKpi(customer: ScenarioConfigModel) {
		const dialogRef = this.dialog.open(ScenarioAddComponent, { data: { customer } });
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
		});
	}
	train(id) {
		this.store.dispatch(new Train({ id: id }));
		this.get()
	}
}










