// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { SetupModule } from './setup/setup.module';
import { ScenarioModule } from './scenario/scenario.module';
import { AccountModule } from './account/account.module';
// import { AmChartsModule } from '@amcharts/amcharts3-angular';

@NgModule({
	declarations: [ ],
	exports: [],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		CoreModule,
		PartialsModule,
		SetupModule,
		ScenarioModule,
		AccountModule,
	],
	providers: []
})
export class PagesModule {
}
