import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, map, tap, delay } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryResultsModel } from '../../_base/crud';
import { DetailssService } from '../_services';
import { AppState } from '../../reducers';
import {

    DetailsActionToggleLoading,
    DetailsActionTypes,
    DetailssPageLoaded,
    DetailssPageRequested,
    DetailssPageToggleLoading,
    OneDetailsDeleted,
    DetailsOnServerCreated,
    DetailsCreated

} from '../_actions/details.actions';
import { of } from 'rxjs';

@Injectable()
export class DetailsEffects {
    showPageLoadingDistpatcher = new DetailssPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new DetailsActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new DetailsActionToggleLoading({ isLoading: false });

    @Effect()
    loadDetailssPage$ = this.actions$.pipe(
        ofType<DetailssPageRequested>(DetailsActionTypes.DetailssPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            return this.DetailssService.findDetailss(payload.list,payload.id);
        }),
        map(response => {
            const result: QueryResultsModel = response;
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new DetailssPageLoaded({
                Detailss: result
            });
            return pageLoadedDispatch;
        })
    );


    @Effect()
    deleteCustomer$ = this.actions$
        .pipe(
            ofType<OneDetailsDeleted>(DetailsActionTypes.OneDetailsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.DetailssService.deleteDetails(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

        @Effect()
        createDetails$ = this.actions$
            .pipe(
                ofType<DetailsOnServerCreated>(DetailsActionTypes.DetailsOnServerCreated),
                mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.DetailssService.Duplicated(payload.id,payload.id2,payload.champ).pipe(
                        tap(res => {
                            this.store.dispatch(new DetailsCreated({ Details: res }));
                        })
                    );
                }),
                map(() => {
                    return this.hideActionLoadingDistpatcher;
                }),
            );

    constructor(private actions$: Actions, private DetailssService: DetailssService, private store: Store<AppState>) { }

}
