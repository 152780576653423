// Angular
import { Component, Input, OnInit, ViewChild, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material';

import { ChartComponent } from "ng-apexcharts";
import { goalRedsService } from '../../../../../core/goal';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexDataLabels,
  ApexLegend,
  ApexStroke,
  ApexPlotOptions,
  ApexStates,
  ApexTheme,
  ApexTitleSubtitle
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  fill: any;
  stroke: ApexStroke;
  states: ApexStates;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  theme: ApexTheme;
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
};

@Component({
  selector: 'kt-popbygoal',
  templateUrl: './popbygoal.component.html',
  styleUrls: ['./popbygoal.component.scss']
})
export class PopbygoalComponent {
  @ViewChild('chart', { static: true }) chart: ChartComponent;
  public chartOptions: any;
  @Input() data: { labels: string[]; datasets: any[] };

  chartCom;
  constructor(@Inject(MAT_DIALOG_DATA) public datachart: any, public goalRedsService: goalRedsService, ) {

    this.goalRedsService.getchart(this.datachart.event2, this.datachart.event).subscribe(res => {
      this.chartCom = res;
      this.chartOptions = {
        series: res.count,
        chart: {
          width: 380,
          type: "donut",
          dropShadow: {
            enabled: true,
            color: "#111",
            top: -1,
            left: 3,
            blur: 3,
            opacity: 0.2
          }
        },
        stroke: {
          width: 0
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: false,
                  show: false,
                  
                },
                
              }
            }
          }
        },
        labels: res.keys,

        // fill: {
        //   type: "pattern",
        //   opacity: 1,
        //   pattern: {
        //     enabled: true,
        //     style: [
        //       "verticalLines",
        //       "squares",
        //       "horizontalLines",
        //       "circles",
        //       "slantedLines"
        //     ]
        //   }
        // },
        states: {
          hover: {
            filter: {
              type: "none"
            }
          }
        },
        theme: {
          palette: "palette2"
        },
        title: {
          text: "Vistor scoring per events number"
        },
        colors: ["#70b77e",
        "#EF6351","#c7c9cb","#8a716a"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      };
    });


  }
}