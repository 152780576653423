// Angular
import { Component, ElementRef, Input, OnInit, ViewChild, Inject } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../../../core/_base/layout';
// Charts
import { Chart } from 'chart.js/dist/Chart.min.js';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import {
	ApexAxisChartSeries,
	ApexChart,
	ChartComponent,
	ApexDataLabels,
	ApexPlotOptions,
	ApexYAxis,
	ApexTitleSubtitle,
	ApexXAxis,
	ApexFill
} from "ng-apexcharts";

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	yaxis: ApexYAxis;
	xaxis: ApexXAxis;
	fill: ApexFill;
	title: ApexTitleSubtitle;
};
@Component({
  selector: 'kt-cerclechart',
  templateUrl: './cerclechart.component.html',
  styleUrls: ['./cerclechart.component.scss']
})
export class CerclechartComponent implements OnInit {
	// Public properties
	@Input() title: string;
	@Input() desc: string;
	@Input() data: { labels: string[]; datasets: any[] };
	@ViewChild('chart', { static: true }) chart: ChartComponent;
	// public chartOptions: Partial<ChartOptions>;
	public chartOptions: any;
	somme: number = 0;

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService, @Inject(MAT_DIALOG_DATA) public datachart: any, public dialogRef: MatDialogRef<CerclechartComponent>, ) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	session:any;
	ngOnInit() {
		this.session=[];
		console.log(this.datachart.event);
		for (let index = 0; index < this.datachart.event.session_numbers_pct.length; index++) {
			const element = this.datachart.event.session_numbers_pct[index];
			this.session.push(  Math.round(element * 100) / 100);
		}
		if (this.session.length>0) {
			this.chartOptions = {
				series: [
				  {
					name: "Inflation",
					data: this.session,
				  }
				],
				colors: ['#ef6351'],
				chart: {
				  height: 350,
				  type: "bar",
				  toolbar: {
					show: true,
					tools: {
					  download: false
					}
				  }
				},
				plotOptions: {
				  bar: {
					dataLabels: {
					  position: "top" // top, center, bottom
					}
				  }
				},
				dataLabels: {
				  enabled: true,
				  formatter: function (val) {
					return val + "%";
				  },
				  offsetY: -20,
				  style: {
					fontSize: "12px",
					colors: ["#304758"]
				  }
				},
				states: {
				  hover: {
					  filter: {
						  type: 'none',
					  }
				  },
				  normal: {
					filter: {
						type: 'none',
					}
				},
			  },
				xaxis: {
				  categories: this.datachart.event.intervals,
				},
				fill: {
				  type: "gradient",
				  gradient: {
					shade: "light",
					type: "horizontal",
					shadeIntensity: 0.25,
					gradientToColors: undefined,
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [50, 0, 100, 100]
				  }
				},
				
				yaxis: {
				  axisBorder: {
					show: false
				  },
				  axisTicks: {
					show: false
				  },
				  labels: {
					show: false,
					formatter: function (val) {
					  return val + "%";
					}
				  }
				},
				title: {
				  text: 'Visitors percentage by score interval',
				  align: 'center',
				  margin: 10,
				  floating: false,
				  style: {
					fontSize:  '14px',
					fontWeight:  'bold',
					// fontFamily:  undefined,
					color:  '#646c9a'
				  },
			  },
				legend: {
				  show: false
				},
				grid: {
				  show: false
				},
				stroke: {
				  show: true,
				  width: 2,
				  colors: ["transparent"]
				},
				
			  };





		}

	}

}
