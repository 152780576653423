import { BaseModel } from '../../_base/crud';

export class EventConfigModel  {
	indicateur:string;
	type:string;
	tags:any;
	threshold:number;
	filtered_url:string;
	id:string;
	buttons:number;
	filtered_taginnertext:string
	filtered_tagname:string;
	filtered_tagid:string;
	filtered_tagclass:string;
	active:boolean
	name: any;
	graph:any;
	clear() {
		this.indicateur = '';
		this.type = '';
		this.tags = '';
		this.filtered_tagname='';
		this.filtered_taginnertext='';
		this.filtered_tagid='';
		this.filtered_tagclass='';
		this.filtered_url='';

	}
}

