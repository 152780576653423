// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { TriggerModel } from '../_models/TriggerModel';

export enum TriggerActionTypes {
    TriggerOnServerCreated = '[Edit Trigger Dialog] Trigger On Server Created',
    TriggerCreated = '[Edit Trigger Dialog] Trigger Created',
    TriggerUpdated = '[Edit Trigger Dialog] Trigger Updated',
    TriggerUpdated2 = '[Edit Trigger Dialog] Trigger Updated2',
    TriggersStatusUpdated = '[Triggers List Page] Triggers Status Updated',
    OneTriggerDeleted = '[Triggers List Page] One Trigger Deleted',

    TriggersPageRequested = '[Triggers List Page] Triggers Page Requested',
    TriggersPageLoaded = '[Triggers API] Triggers Page Loaded',
    BqTriggersPageRequested = '[BqTriggers List Page] BqTriggers Page Requested',
    BqTriggersPageLoaded = '[BqTriggers API] BqTriggers Page Loaded',

    ScoringTriggerPageRequested = '[ScoringTrigger List Page] ScoringTrigger Page Requested',
    ScoringTriggerPageLoaded = '[ScoringTrigger API] ScoringTrigger Page Loaded',
    TriggerPageRequested = '[ScoringTrigger List Page] Trigger Page Requested',
    TriggerPageLoaded = '[ScoringTrigger API] Trigger Page Loaded',
    TriggersPageCancelled = '[Triggers API] Triggers Page Cancelled',
    TriggersPageToggleLoading = '[Triggers] Triggers Page Toggle Loading',
    TriggerActionToggleLoading = '[Triggers] Triggers Action Toggle Loading'
}

export class TriggerOnServerCreated implements Action {
    readonly type = TriggerActionTypes.TriggerOnServerCreated;
    constructor(public payload: { Trigger: TriggerModel }) { }
}

export class TriggerCreated implements Action {
    readonly type = TriggerActionTypes.TriggerCreated;
    constructor(public payload: { Trigger: TriggerModel }) { }
}

export class TriggerUpdated implements Action {
    readonly type = TriggerActionTypes.TriggerUpdated;
    constructor(public payload: {
        partialTrigger: Update<TriggerModel>, 
        Trigger: TriggerModel 
    }) { }
}
export class TriggerUpdated2 implements Action {
    readonly type = TriggerActionTypes.TriggerUpdated2;
    constructor(public payload: {
        partialTrigger: Update<any>, 
        Trigger: any ,
        type:string
    }) { }
}


export class TriggersStatusUpdated implements Action {
    readonly type = TriggerActionTypes.TriggersStatusUpdated;
    constructor(public  payload: {
        Triggers: TriggerModel[],
        status: number
    }) { }
}

export class OneTriggerDeleted implements Action {
    readonly type = TriggerActionTypes.OneTriggerDeleted;
    constructor(public payload: { id: number }) {}
}


export class TriggersPageRequested implements Action {
    readonly type = TriggerActionTypes.TriggersPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class TriggersPageLoaded implements Action {
    readonly type = TriggerActionTypes.TriggersPageLoaded;
    constructor(public payload :any ) { }
}

export class BqTriggersPageRequested implements Action {
    readonly type = TriggerActionTypes.BqTriggersPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class BqTriggersPageLoaded implements Action {
    readonly type = TriggerActionTypes.BqTriggersPageLoaded;
    constructor(public payload :any ) { }
}
export class ScoringTriggerPageRequested implements Action {
    readonly type = TriggerActionTypes.ScoringTriggerPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class ScoringTriggerPageLoaded implements Action {
    readonly type = TriggerActionTypes.ScoringTriggerPageLoaded;
    constructor(public payload :any ) { }
}

export class TriggerPageRequested implements Action {
    readonly type = TriggerActionTypes.TriggerPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class TriggerPageLoaded implements Action {
    readonly type = TriggerActionTypes.TriggerPageLoaded;
    constructor(public payload :any ) { }
}
export class TriggersPageCancelled implements Action {
    readonly type = TriggerActionTypes.TriggersPageCancelled;
}

export class TriggersPageToggleLoading implements Action {
    readonly type = TriggerActionTypes.TriggersPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class TriggerActionToggleLoading implements Action {
    readonly type = TriggerActionTypes.TriggerActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type TriggerActions = TriggerOnServerCreated
| TriggerCreated
| TriggerUpdated
| TriggerUpdated2
| TriggersStatusUpdated
| OneTriggerDeleted
| TriggersPageRequested
| TriggersPageLoaded
| BqTriggersPageRequested
| BqTriggersPageLoaded
| ScoringTriggerPageRequested
| ScoringTriggerPageLoaded
| TriggerPageRequested
| TriggerPageLoaded
| TriggersPageCancelled
| TriggersPageToggleLoading
| TriggerActionToggleLoading;
