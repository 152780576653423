import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';

export class User extends BaseModel {
    id: number;
    eid: number;
    display_name: string;
    password: string;
    email: string;
    photo_url:string;
    phone_number:string;
    accessToken: string;
    refreshToken: string;
    roles: number[];
    fullname: string;
    occupation: string;
	companyName: string;
    address: Address;
    socialNetworks: SocialNetworks;

    clear(): void {
        this.id = undefined;
        this.display_name = '';
        this.password = '';
        this.email = '';
        this.roles = [];
        this.fullname = '';
        this.occupation = '';
        this.companyName = '';
        this.address = new Address();
        this.address.clear();
        this.socialNetworks = new SocialNetworks();
        this.socialNetworks.clear();
    }
}
