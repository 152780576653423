import { ActivatedRoute, Params } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { distinctUntilChanged, skip, delay, take } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { QueryParamsModel } from '../../../../core/_base/crud';
import { DetailsModel, DetailssDataSource, DetailssPageRequested } from '../../../../core/scenario';
import * as firebase from 'firebase';
import { RecordModel, BqRecordsDataSource, BqRecordsPageRequested, RecordUpdated, RecordsService } from '../../../../core/event';
import { Update } from '@ngrx/entity';


@Component({
	selector: 'kt-scenario-detail',
	templateUrl: './scenario-detail.component.html',
	styleUrls: ['./scenario-detail.component.scss']
})
export class ScenarioDetailComponent implements OnInit, OnDestroy {
	dataSource: DetailssDataSource;
	id: string;


	ScenarioConfigsResult: DetailsModel[] = [];
	// Subscriptions
	private subscriptions: Subscription[] = [];
	recipe: DetailsModel;
	paramsSubscription: Subscription;
	RecordsResult: any[] = [];
	dataSourceRecords: BqRecordsDataSource;

	activate: boolean = true;
	stopactivate: boolean = true;
	constructor(private route: ActivatedRoute, private RecordsService: RecordsService,
		public dialog: MatDialog,
		public snackBar: MatSnackBar
		, @Inject(MAT_DIALOG_DATA) public event: any, public dialogRef: MatDialogRef<ScenarioDetailComponent>,
		private store: Store<AppState>) { }



	ngOnInit() {
		this.getStatusInit();
		this.id = this.event.event;
		// this.route.params
		// 	.pipe(
		// 		map(params => {
		// 			return +params['id'];
		// 		})
		this.RecordsService.get().subscribe((res: any) => {
			console.log(res.user_data);
			
			this.RecordsService.getlog(res.user_data.claims.eid, this.id).subscribe(list =>{
				this.ScenarioConfigsResult = list;

			})
		});
	}

	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}


	getStatusInit() {
		this.dataSourceRecords = new BqRecordsDataSource(this.store);
		const entitiesSubscription = this.dataSourceRecords.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.RecordsResult = res;
			if (this.RecordsResult[0] == "off") {
				this.activate = false;
				this.stopactivate = true;

			} else if (this.RecordsResult[0] == "on") {
				this.activate = true;
				this.stopactivate = false;

			}

		});
		this.subscriptions.push(entitiesSubscription);
		of(undefined).pipe(take(1), delay(1000)).subscribe(() => {
			this.loadRecordsList();
		});
	}
	prepareUser(status: string): RecordModel {


		const _user = new RecordModel();
		_user.clear();
		_user.db = "bq";
		_user.status = status;
		return _user;
	}
	activated() {
		const editedCustomer = this.prepareUser("on");
		this.toggleView(editedCustomer);
		this.activate = true;
		this.stopactivate = false;
		this.getStatusInit();

	}
	deactivate() {
		const editedCustomer = this.prepareUser("off");
		this.toggleView(editedCustomer);
		this.getStatusInit();

		this.activate = false;
		this.stopactivate = true;
	}
	toggleView(_customer: RecordModel) {
		const updateCustomer: Update<RecordModel> = {
			id: _customer.db,
			changes: _customer
		};
		this.store.dispatch(new RecordUpdated({
			partialRecord: updateCustomer,
			Record: _customer
		}));

		if (_customer.status == "on") {
			this.snackBar.open(('Live'), '', {
				panelClass: 'success-toaster',
				duration: 2000,
				verticalPosition: 'top',
			});
		} else if (_customer.status == "off") {
			this.snackBar.open(('Stop live'), '', {
				panelClass: 'success-toaster',
				duration: 2000,
				verticalPosition: 'top',
			});
		}


	}
	loadRecordsList() {
		const queryParams = new QueryParamsModel(
			false,
			"asc",
		);
		this.store.dispatch(new BqRecordsPageRequested({ page: queryParams }));
	}


}


















