import { DomSanitizer } from '@angular/platform-browser';
import { SetupService } from '../../../../core/setup/setup.service';
import { CopierService } from '../../../../core/setup/copier.service';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { distinctUntilChanged, skip, delay, take } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { QueryParamsModel } from '../../../../core/_base/crud';
import { RecordModel, BqRecordsDataSource, BqRecordsPageRequested, RecordUpdated } from '../../../../core/event';
import { Update } from '@ngrx/entity';

import { environment } from '../../../../../environments/environment';
import { Modelvideo3Component } from './modelvideo3/modelvideo3.component';


@Component({
	selector: 'kt-setup',
	templateUrl: './setup.component.html',
	styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {
	x: string;
	name = 'Angular 5';
	fileUrl;

	texttoken: string;
	source: any;
	RecordsResult: any[] = [];
	dataSourceRecords: BqRecordsDataSource;
	public window = window.innerWidth;
	//get window width on any window size change
	@HostListener('window:resize')
	onWindowResize() {
	  this.window = window.innerWidth;
	  // console.log('Window inner width:', this.window);
	}
	activate: boolean = true;
	stopactivate: boolean = true;
	code='<your_coupon_code>';
	txt=`{ "is_valid": True,"discount_percentage': 20 }`;
	private subscriptions: Subscription[] = [];
	constructor(private setupservice: SetupService, private CopierService: CopierService, private sanitizer: DomSanitizer, private _matSnackBar: MatSnackBar, public dialog: MatDialog,
		public snackBar: MatSnackBar, private _cdr: ChangeDetectorRef,
		private store: Store<AppState>) {
		const data = `
    <script>
    const req = new XMLHttpRequest();
    req.open('GET', 'https://ipinfo.io/json', false); 
    req.send(null);
    var ipconfg= JSON.parse(req.response)
        var adress_ip = ipconfg.ip
    var city = ipconfg.city
        document.addEventListener("click",function(event){   
var cookie = document.cookie
var output = {};
cookie.split(/\s*;\s*/).forEach(function(pair) {
pair = pair.split(/\s*=\s*/);
output[pair[0]] = pair.splice(1).join('=');
});
var json = JSON.stringify(output, null, 4);
var ga=output._ga;
var gid=output._gid;
var ajs_user_id=output.ajs_user_id;
var ajs_group_id=output.ajs_group_id;
var anonymous_id=output.ajs_anonymous_id;
var status = ""
var url="";
var string1 = "";
for(i=0;i<event.path.length-4;i++){  
string1 += event.path[i].localName+"." + event.path[i].className+"." }
path = string1.replace(/ /g, '');

url=  document.location.href

const req = new XMLHttpRequest();
req.open('GET', `+environment.API_URL+`/enterprises/db_status', false); 
req.send(null);
var statusfs= JSON.parse(req.response).status_fs
var statusbq= JSON.parse(req.response).status_bq
db ="fs";
status="record" 
if (statusfs =='record'){
toGMTString()
req.open("POST", 'https://prod-smarket.appspot.com/enterprises/post_events_fs', true); 
req.setRequestHeader("Content-Type", "application/json");
req.send(JSON.stringify({      
path: path,
timeStamp: event.timeStamp,
session_id:document.cookie,
type: event.type,
x: event.x,
y: event.y,
url:url,
ga:ga,
ajs_user_id:ajs_user_id,
ajs_group_id:ajs_group_id,
anonymous_id:anonymous_id,
gid:gid,
adress_ip:adress_ip,
city:city
}));

}  
});

</script>
    `;
		this.setupservice.getstup().subscribe(res => {
			this.x = res;
			const blob = new Blob([this.x + data], { type: 'application/octet-stream' });

			this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
		});
	}
	changeStatus2(): void {

		setTimeout(() => {

			this._cdr.detectChanges()
			this._cdr.markForCheck()

		}, 1000);
	}
	claim:any;
	gotopopuo3() {
		this.dialog.open(Modelvideo3Component, {
		  data: {},
		  // width: '440px'
		});
	  }
	ngOnInit() {
		this.setupservice.get().subscribe((res: any) => { 
			this.claim=res.user_data.claims
			if(environment.API_URL === 'https://console-back-notapvifoq-od.a.run.app'){
				this.source = '<script language="JavaScript" type="text/javascript" src = " https://storage.googleapis.com/datascale_js/datascalejs_' + res.user_data.claims.eid + '.min.js?ignoreCache=1"> </script>';
			}else{
				this.source = '<script language="JavaScript" type="text/javascript" src = " https://storage.googleapis.com/datascale_js_dev/datascalejs_' + res.user_data.claims.eid + '.min.js?ignoreCache=1"> </script>';
			}
			this.changeStatus2();
		});

		this.getStatusInit();
	}
	codeCopied=false;
	/**
		 * Copy the source
		 *
		 * @param {string} text
		 */
	copySource(text: string): void {
		if (this.CopierService.copyText(text)) {
			this._matSnackBar.open('Code copied', '', { duration: 2500 });
			this.codeCopied=true;
		}
		else {
			this._matSnackBar.open('Copy failed. Please try again!', '', { duration: 2500 });
		}
	}

	getStatusInit() {
		this.dataSourceRecords = new BqRecordsDataSource(this.store);
		const entitiesSubscription = this.dataSourceRecords.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.RecordsResult = res;
			if (this.RecordsResult[0] == "off") {
				this.activate = false;
				this.stopactivate = true;

			} else if (this.RecordsResult[0] == "on") {
				this.activate = true;
				this.stopactivate = false;
			}
		});
		this.subscriptions.push(entitiesSubscription);
		of(undefined).pipe(take(1), delay(1000)).subscribe(() => {
			this.loadRecordsList();
		});
	}
	prepareUser(status: string): RecordModel {


		const _user = new RecordModel();
		_user.clear();
		_user.db = "bq";
		_user.status = status;
		return _user;
	}
	activated() {
		const editedCustomer = this.prepareUser("on");
		this.toggleView(editedCustomer);
		this.activate = true;
		this.stopactivate = false;
		this.getStatusInit();

	}
	deactivate() {
		const editedCustomer = this.prepareUser("off");
		this.toggleView(editedCustomer);
		this.getStatusInit();

		this.activate = false;
		this.stopactivate = true;
	}
	toggleView(_customer: RecordModel) {
		const updateCustomer: Update<RecordModel> = {
			id: _customer.db,
			changes: _customer
		};
		console.log(updateCustomer);

		this.store.dispatch(new RecordUpdated({
			partialRecord: updateCustomer,
			Record: _customer
		}));

		if (_customer.status == "on") {
			this.snackBar.open(('Live'), '', {
				panelClass: 'success-toaster',
				duration: 2000,
				verticalPosition: 'top',
			});
		} else if (_customer.status == "off") {
			this.snackBar.open(('Stop live'), '', {
				panelClass: 'success-toaster',
				duration: 2000,
				verticalPosition: 'top',
			});
		}


	}
	loadRecordsList() {
		const queryParams = new QueryParamsModel(
			false,
			"asc",
		);
		this.store.dispatch(new BqRecordsPageRequested({ page: queryParams }));
	}

}
