// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth
import {AuthGuard} from './core/auth';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('../app/views/pages/auth/auth.module').then(m => m.AuthModule)},
	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard], 
		data: {returnUrl: window.location.pathname},
		children: [
			{
				path: 'event-config',
				loadChildren: () => import('../app/views/pages/event/event.module').then(k => k.EventModule),
			},
			{
				path: 'gdpr-config',
				loadChildren: () => import('../app/views/pages/gdpr/gdpr.module').then(k => k.GdprModule),
			},
			{
				path: 'configuration',
				loadChildren: () => import('../app/views/pages/configuration/configuration.module').then(k => k.ConfigurationModule),
			},
			{
				path: 'popup',
				loadChildren: () => import('../app/views/pages/popup/popup.module').then(k => k.PopupModule),
			},
			
			{
				path: 'visiteur',
				loadChildren: () => import('../app/views/pages/visiteur-red/visiteur-red.module').then(k => k.VisiteurRedModule),
			},
			{
				path: 'setup',
				loadChildren: () => import('.//views/pages/setup/setup.module').then(stu => stu.SetupModule)
			},
			{
				path: 'scenario',
				loadChildren: () => import('.//views/pages/scenario/scenario.module').then(k => k.ScenarioModule)
			},
			{
				path: 'account',
				loadChildren: () => import('.//views/pages/account/account.module').then(k => k.AccountModule)
			},
			{
				path: 'newsletter',
				loadChildren: () => import('.//views/pages/newsletter/newsletter.module').then(k => k.NewsletterModule)
			},
			{
				path: 'userManagment',
				loadChildren: () => import('.//views/pages/user-management/user-management.module').then(u => u.UserManagementModule)
			},
			{
				path: 'event',
				loadChildren: () => import('.//views/pages/eventscap/eventscap.module').then(u => u.EventscapModule)
			},
			{
				path: 'goal',
				loadChildren: () => import('.//views/pages/goals/goals.module').then(u => u.GoalsModule)
			},
			
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
				},
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'popup/goal', pathMatch: 'full'},
			{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
		],
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
