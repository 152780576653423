import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
import { scoringRecordModel } from '../_models/scoringRecordModel';
import { scoringRecordState } from '../_reducers/scoringRecord.reducers';

export const selectscoringRecordsState = createFeatureSelector<scoringRecordState>('scoringRecords');

export const selectscoringRecordById = (scoringRecordId: number) => createSelector(
    selectscoringRecordsState,
    scoringRecordState => scoringRecordState.entities[scoringRecordId]
);

export const selectscoringRecordsPageLoading = createSelector(
    selectscoringRecordsState,
    scoringRecordState => scoringRecordState.listLoading
);

export const selectscoringRecordsActionLoading = createSelector(
    selectscoringRecordsState,
    scoringRecordState => scoringRecordState.actionsloading
);

export const selectLastCreatedscoringRecordId = createSelector(
    selectscoringRecordsState,
    scoringRecordState => scoringRecordState.lastCreatedscoringRecordId
);

export const selectscoringRecordsShowInitWaitingMessage = createSelector(
    selectscoringRecordsState,
    scoringRecordState => scoringRecordState.showInitWaitingMessage
);

export const selectscoringRecordsInStore = createSelector(
    selectscoringRecordsState,
    scoringRecordState => {
        const items: scoringRecordModel[] = [];
        each(scoringRecordState.entities, element => {
            items.push(element);
        });
        return new QueryResultsModel(items, scoringRecordState.totalCount, '');
    }
);

