import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { RecordActions, RecordActionTypes } from '../_actions/Record.actions';
import { RecordModel } from '../_models/RecordModel';
import { QueryParamsModel } from '../../_base/crud';

export interface RecordState extends EntityState<RecordModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedRecordId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<RecordModel> = createEntityAdapter<RecordModel>();

export const initialRecordState: RecordState = adapter.getInitialState({
    RecordForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedRecordId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function RecordsReducer(state = initialRecordState, action: RecordActions): RecordState {
    switch  (action.type) {
        case RecordActionTypes.RecordsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedRecordId: undefined
            };
        }    

        case RecordActionTypes.RecordActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }

        case RecordActionTypes.RecordOnServerCreated: return {
            ...state
        };

        case RecordActionTypes.RecordUpdated: return adapter.updateOne(action.payload.partialRecord, state);
        case RecordActionTypes.RecordUpdated2: return adapter.updateOne(action.payload.partialRecord, state);

        // case RecordActionTypes.RecordsStatusUpdated: {
        //     const _partialRecord: Update<RecordModel>[] = [];
        //     for (let i = 0; i < action.payload.Records.length; i++) {
        //         _partialRecord.push({
		// 		    id: action.payload.Records[i].id,
		// 		    changes: {
        //                 status: action.payload.status
        //             }
		// 	    });
        //     }
        //     return adapter.updateMany(_partialRecord, state);
        // }

        case RecordActionTypes.OneRecordDeleted: return adapter.removeOne(action.payload.id, state);

        case RecordActionTypes.RecordsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }

        case RecordActionTypes.RecordsPageLoaded: {                       
            return adapter.addOne(action.payload.Records, {
                ...initialRecordState,                
            });
        }
       
        default: return state;
    }
}



export const getRecordState = createFeatureSelector<RecordModel>('Records');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
