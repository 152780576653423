// Angular
import { Component, ElementRef, Input, OnInit, ViewChild, Inject, OnChanges, ChangeDetectorRef } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../../../core/_base/layout';
// Charts
import { Chart } from 'chart.js/dist/Chart.min.js';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexFill
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;  
  title: ApexTitleSubtitle;
};
@Component({
  selector: 'kt-chartscinariodashbprd',
  templateUrl: './chartscinariodashbprd.component.html',
  styleUrls: ['./chartscinariodashbprd.component.scss']
})
export class ChartscinariodashbprdComponent implements OnChanges {
  // Public properties
  @Input() type: string = 'line';
  @Input('chat') chat: any;
	public chartOptions: any; 
  somme: number = 0;

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 */
  constructor(private layoutConfigService: LayoutConfigService, private _cdr: ChangeDetectorRef,
  ) {
  }
  changeStatus(): void {

    setTimeout(() => {

      this._cdr.detectChanges()
      this._cdr.markForCheck()

    }, 1500);
  }
  obj: any = '';
  session:any;

  ngOnChanges() {
    if(this.chat){
      this.obj = '';
    this.obj = this.chat;
    this.session=[];
		for (let index = 0; index < this.chat.session_numbers_pct.length; index++) {
			const element = this.chat.session_numbers_pct[index];
			this.session.push(  Math.round(element * 100) / 100);
		} 
    this.changeStatus();
		if (this.session.length>0) {
      const maxIndex = this.session.indexOf(Math.max.apply(null, this.session));

      // Create an array of colors, with the maximum value in blue and others in gray
      const colors = this.session.map((value, index) => (index === maxIndex ? "#3BB5FE" : "#B3C8DC"));
    this.chartOptions = {
      series: [
        {
          name: "",
          data: this.session,
        },
      ],
      colors: colors,
      tootlip:{
        enabled:false,
        shared:false,
        intersect:false,
        x:{
          show:false
        }
      },
      chart: {
        height: 250,
        type: "bar",
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
          distributed: true,
        },
        enabledShades:false
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
        normal: {
          filter: {
            type: "none",
          },
        },
      },
      xaxis: {
        categories: this.chat.intervals,
        totlipe:{
					enabled:false
				}
      },
      fill: {
        type: "pattern",
        opacity: 1,
        pattern: {
          enabled: true,
          style: [
            // "verticalLines",
            // "squares",
            // "horizontalLines",
            // "circles",
          ],
        },
      },

      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          },
        },
      },
      title: {
        text: "Visitor Percentage By Score Interval",
        align: "left",
        margin: 10,
        floating: false,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          // fontFamily:  undefined,
          color: "#0D1861",
        },
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
    };;
    this.changeStatus();

  }
    }
}
}
