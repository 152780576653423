import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
import { BqRecordsModel } from '../_models/BqRecordsModel';
import { BqRecordState } from '../_reducers/BqRecords.reducers';

export const selectBqRecordsState = createFeatureSelector<BqRecordState>('BqRecords');

export const selectBqRecordById = (BqRecordId: number) => createSelector(
    selectBqRecordsState,
    BqRecordState => BqRecordState.entities[BqRecordId]
);

export const selectBqRecordsPageLoading = createSelector(
    selectBqRecordsState,
    BqRecordState => BqRecordState.listLoading
);

export const selectBqRecordsActionLoading = createSelector(
    selectBqRecordsState,
    BqRecordState => BqRecordState.actionsloading
);

export const selectLastCreatedBqRecordId = createSelector(
    selectBqRecordsState,
    BqRecordState => BqRecordState.lastCreatedBqRecordId
);

export const selectBqRecordsShowInitWaitingMessage = createSelector(
    selectBqRecordsState,
    BqRecordState => BqRecordState.showInitWaitingMessage
);

export const selectBqRecordsInStore = createSelector(
    selectBqRecordsState,
    BqRecordState => {
        const items: BqRecordsModel[] = [];
        each(BqRecordState.entities, element => {
            items.push(element);
        });
        return new QueryResultsModel(items, BqRecordState.totalCount, '');
    }
);

