// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { EventConfigModel } from '../_models/EventConfigModel';

export enum EventConfigActionTypes {
    EventConfigOnServerCreated = '[Edit EventConfig Dialog] EventConfig On Server Created',
    EventConfigCreated = '[Edit EventConfig Dialog] EventConfig Created',
    EventConfigUpdated = '[Edit EventConfig Dialog] EventConfig Updated',
    EventConfigsStatusUpdated = '[EventConfigs List Page] EventConfigs Status Updated',
    OneEventConfigDeleted = '[EventConfigs List Page] One EventConfig Deleted',
    AllEventConfigDeleted = '[EventConfigs List Page] all EventConfigs Deleted',
    EventConfigsPageRequested = '[EventConfigs List Page] EventConfigs Page Requested',
    EventConfigsPageLoaded = '[EventConfigs API] EventConfigs Page Loaded',


    EventConfigsPageCancelled = '[EventConfigs API] EventConfigs Page Cancelled',
    EventConfigsPageToggleLoading = '[EventConfigs] EventConfigs Page Toggle Loading',
    EventConfigActionToggleLoading = '[EventConfigs] EventConfigs Action Toggle Loading'
}

export class EventConfigOnServerCreated implements Action {
    readonly type = EventConfigActionTypes.EventConfigOnServerCreated;
    constructor(public payload: { EventConfig: any }) { }
}

export class EventConfigCreated implements Action {
    readonly type = EventConfigActionTypes.EventConfigCreated;
    constructor(public payload: { EventConfig: EventConfigModel }) { }
}

export class EventConfigUpdated implements Action {
    readonly type = EventConfigActionTypes.EventConfigUpdated;
    constructor(public payload: {
        partialEventConfig: Update<EventConfigModel>, 
        EventConfig: EventConfigModel,
        id:string;
    }) { }
}

export class EventConfigsStatusUpdated implements Action {
    readonly type = EventConfigActionTypes.EventConfigsStatusUpdated;
    constructor(public  payload: {
        EventConfigs: EventConfigModel[],
        status: number
    }) { }
}

export class OneEventConfigDeleted implements Action {
    readonly type = EventConfigActionTypes.OneEventConfigDeleted;
    constructor(public payload: { indicateur: string,type:string }) {}
}

export class AllEventConfigDeleted implements Action {
    readonly type = EventConfigActionTypes.AllEventConfigDeleted;
    constructor(public payload: {  }) {}
}


export class EventConfigsPageRequested implements Action {
    readonly type = EventConfigActionTypes.EventConfigsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class EventConfigsPageLoaded implements Action {
    readonly type = EventConfigActionTypes.EventConfigsPageLoaded;
    constructor(public payload: { EventConfigs: EventConfigModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class EventConfigsPageCancelled implements Action {
    readonly type = EventConfigActionTypes.EventConfigsPageCancelled;
}

export class EventConfigsPageToggleLoading implements Action {
    readonly type = EventConfigActionTypes.EventConfigsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class EventConfigActionToggleLoading implements Action {
    readonly type = EventConfigActionTypes.EventConfigActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type EventConfigActions = EventConfigOnServerCreated
| EventConfigCreated
| EventConfigUpdated
| EventConfigsStatusUpdated
| OneEventConfigDeleted
| EventConfigsPageRequested
| EventConfigsPageLoaded
| AllEventConfigDeleted
| EventConfigsPageCancelled
| EventConfigsPageToggleLoading
| EventConfigActionToggleLoading;
