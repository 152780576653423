import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
import { EventConfigState } from '../_reducers/EventConfig.reducers';
import { EventConfigModel } from '../_models/EventConfigModel';

export const selectEventConfigsState = createFeatureSelector<EventConfigState>('EventConfigs');

export const selectEventConfigById = (EventConfigId: number) => createSelector(
    selectEventConfigsState,
    EventConfigState => EventConfigState.entities[EventConfigId]
);

export const selectEventConfigsPageLoading = createSelector(
    selectEventConfigsState,
    EventConfigState => EventConfigState.listLoading
);

export const selectEventConfigsActionLoading = createSelector(
    selectEventConfigsState,
    EventConfigState => EventConfigState.actionsloading
);

export const selectLastCreatedEventConfigId = createSelector(
    selectEventConfigsState,
    EventConfigState => EventConfigState.lastCreatedEventConfigId
);

export const selectEventConfigsShowInitWaitingMessage = createSelector(
    selectEventConfigsState,
    EventConfigState => EventConfigState.showInitWaitingMessage
);

export const selectEventConfigsInStore = createSelector(
    selectEventConfigsState,
    EventConfigState => {
        const items: EventConfigModel[] = [];
        each(EventConfigState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: EventConfigModel[] = httpExtension.sortArray(items, EventConfigState.lastQuery.sortField, EventConfigState.lastQuery.sortOrder);
        return new QueryResultsModel(result, EventConfigState.totalCount, '');
    }
);

