import { BaseModel } from '../../_base/crud';

export class KpiModel extends BaseModel {
  id: number;
  titre?: string;
  description: string;
  usecase: string;
  status: Boolean;
  accuracy: accuracy;
  kpis: kpis;
  name: string;
  clear() {
    this.id = 0;
    this.titre = '';
    this.description = '';
    this.name='';
    this.usecase = '';
    this.accuracy = null;
    this.kpis = null;
  }

}
export class accuracy {
  latest_accuracy: number;
  latest_update: Date;
}


export class kpis {
  general: general;
  standard: standard;
}
export class general {
  city: string;
  Browser: string;
  DeviceType: string;
  Date: Array<string>;
  Time_spent_in_the_session: string;
  Number_of_clicks_on_the_buttons: Array<string>;
  Number_of_pages_read_in_the_session: Array<string>;
  Time_spent_in_a_page_dwell_time: Array<string>;
}
export class standard {
  Single_article_bought: Array<string>;
}