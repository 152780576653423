import { mergeMap, tap } from 'rxjs/operators';
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
import { AppState } from '../../reducers';
import { selectDetailssInStore, selectDetailssPageLoading, selectDetailssShowInitWaitingMessage } from '../_selectors/details.selectors';


export class DetailssDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
        super();

		this.loading$ = this.store.pipe(
			select(selectDetailssPageLoading),
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectDetailssShowInitWaitingMessage)
        );
        
        this.store.pipe(
			select(selectDetailssInStore),
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});

    }
}