import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Translate
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
// Services
import { HttpUtilsService, TypesUtilsService, InterceptService, LayoutUtilsService } from '../../../core/_base/crud';
// Material
import {
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatSelectModule,
  MatMenuModule,
  MatProgressBarModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatTabsModule,
  MatNativeDateModule,
  MatCardModule,
  MatRadioModule,
  MatIconModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatSnackBarModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatDividerModule,
  MatChipsModule,
} from '@angular/material';
import { NgxMaskModule } from 'ngx-mask';
import { IConfig } from 'ngx-mask';
import {Ng2PaginationModule} from 'ng2-pagination'; //importing ng2-pagination
import { MatStepperModule } from '@angular/material/stepper';

// Shared
import { ActionNotificationComponent } from '../../partials/content/crud';

// Core Module

// Core => Services
import {
	ScenarioConfigsReducer,
	ScenarioConfigEffects,
	ScenarioConfigsService,
} from '../../../../app/core/scenario';
import {
	DetailssReducer,
	DetailsEffects,
	DetailssService,
} from '../../../../app/core/scenario';
import {
	EventConfigsReducer,
	EventConfigEffects,
	EventConfigsService,
} from '../../../../app/core/event';
import {
	EventCapturedsReducer,
	EventCapturedEffects,
	EventCapturedsService,
} from '../../../../app/core/event';
import {
	RecordsReducer,
	RecordEffects,
	RecordsService,
} from '../../../../app/core/event';
import {
	KpisReducer,
	KpiEffects,
	KpisService,
} from '../../../../app/core/scenario';

import {
	KpiaddsReducer,
	KpiaddEffects,
} from '../../../../app/core/scenario';

// Shared
import {
	DeleteEntityDialogComponent
} from '../../partials/content/crud';



import { ScenarioComponent } from './scenario.component';
import { ScenarioListComponent } from './scenario-list/scenario-list.component';
import { ScenarioEditComponent, RemoveUnderscorePipe } from './scenario-edit/scenario-edit.component';
import { ScenarioDetailComponent } from './scenario-detail/scenario-detail.component';
import { ScenarioAddComponent, OrderByPipe } from './scenario-add/scenario-add.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from './scenario-list/loader/loader.component';
import { SetupModule } from '../setup/setup.module';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const routes: Routes = [ 
  {
    path: '',
    component: ScenarioComponent,
    children: [
      {
      	path: 'kpilist',
        component: ScenarioListComponent,
      },
      {
      	path: 'kpiEdit/:id',
      	component: ScenarioEditComponent,

      },
      {
      	path: 'kpilist/:id',
      	component: ScenarioDetailComponent,

      }
    ]
  }
];
@NgModule({
  declarations: [
    ScenarioComponent,
    ScenarioListComponent,
    ScenarioEditComponent,
    ScenarioDetailComponent,
    ScenarioAddComponent,
    RemoveUnderscorePipe,
    OrderByPipe,
    LoaderComponent
    // KpiDetailComponent,
    //  AddKpiComponent, 
    //  EditKpiComponent
  ],
  imports: [
    CommonModule,
		HttpClientModule,
		PartialsModule,
		RouterModule.forChild(routes),
    FormsModule,
    NgxMaskModule.forRoot(options),
    ReactiveFormsModule,
    MatStepperModule,
    Ng2PaginationModule,SetupModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgbModule ,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,
    MatSlideToggleModule,
    MatDividerModule,
		StoreModule.forFeature('ScenarioConfigs', ScenarioConfigsReducer),
		EffectsModule.forFeature([ScenarioConfigEffects]),
		StoreModule.forFeature('Detailss', DetailssReducer),
		EffectsModule.forFeature([DetailsEffects]),
		StoreModule.forFeature('Kpis', KpisReducer),
    EffectsModule.forFeature([KpiEffects]),
    StoreModule.forFeature('Kpiadds', KpiaddsReducer),
    StoreModule.forFeature('EventCaptureds', EventCapturedsReducer),
		EffectsModule.forFeature([EventCapturedEffects]), 
    EffectsModule.forFeature([KpiaddEffects]),
    StoreModule.forFeature('EventConfigs', EventConfigsReducer),
    EffectsModule.forFeature([EventConfigEffects]),
    StoreModule.forFeature('Records', RecordsReducer),
		EffectsModule.forFeature([RecordEffects]),
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'kt-mat-dialog-container__wrapper',
        height: 'auto',
        width: '900px'
      }
    },
		TypesUtilsService,
		LayoutUtilsService,
    HttpUtilsService,
    RecordsService,
    EventConfigsService,
    ScenarioConfigsService,
    EventCapturedsService,
		DetailssService,
		KpisService,
		TypesUtilsService,
		LayoutUtilsService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],
  entryComponents: [
    ScenarioAddComponent ,
    DeleteEntityDialogComponent,
    ActionNotificationComponent,
    ScenarioDetailComponent,
    LoaderComponent
  ],
  exports: [
    ScenarioAddComponent,
    OrderByPipe,
    ScenarioDetailComponent
    ]

})
export class ScenarioModule { }
