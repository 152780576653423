import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryRealtimeParamsModel, HttpExtenstionsModel } from '../../_base/crud';
import { DetailGState } from '../_reducers/DetailG.reducers';
import { goalRedModel } from '../_models/goalRedModel';

export const selectDetailGsState = createFeatureSelector<DetailGState>('DetailG');

export const selectDetailGById = (DetailGId: number) => createSelector(
    selectDetailGsState,
    DetailGState => DetailGState.entities[DetailGId]
);

export const selectDetailGsPageLoading = createSelector(
    selectDetailGsState,
    DetailGState => DetailGState.listLoading
);

export const selectDetailGsActionLoading = createSelector(
    selectDetailGsState,
    DetailGState => DetailGState.actionsloading
);

export const selectLastCreatedDetailGId = createSelector(
    selectDetailGsState,
    DetailGState => DetailGState.lastCreatedDetailGId
);

export const selectDetailGsShowInitWaitingMessage = createSelector(
    selectDetailGsState,
    DetailGState => DetailGState.showInitWaitingMessage
);

export const selectDetailGsInStore = createSelector(
    selectDetailGsState,
    DetailGState => {
        const items: goalRedModel[] = [];
        each(DetailGState.entities, element => {
            items.push(element);
        });        
        const httpExtension = new HttpExtenstionsModel();
        const result: goalRedModel[] = httpExtension.sortArray(items, DetailGState.lastQuery.sortField, DetailGState.lastQuery.sortOrder);
        return new QueryRealtimeParamsModel(result);
    }
);

