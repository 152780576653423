// Angular
import { Component, ContentChild, Input, OnInit, TemplateRef, ViewChild, OnChanges } from '@angular/core';
import {
	ApexAxisChartSeries,
	ApexTitleSubtitle,
	ApexDataLabels,
	ApexChart,
	ChartComponent,
	ApexPlotOptions
} from "ng-apexcharts";
export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	title: ApexTitleSubtitle;
	tooltip: any;
	plotOptions: ApexPlotOptions;
	legend: any;
};

@Component({
	selector: 'kt-widget4',
	templateUrl: './widget4.component.html',
	styleUrls: ['./widget4.component.scss']
})
export class Widget4Component implements OnChanges {
	public chartOptions: Partial<any>;
	@ViewChild('chart', { static: true }) chart: ChartComponent;
	@Input() heatmap: any;
	datadict: any;
	keys: number[] = [];
	dict: any;
	dictfilter: any;
	@Input('chat') chat: any;

	series: any;
	ngOnChanges() {
		if (this.chat) {
			const chatData = this.chat; // Store chat in a local variable

			this.chartOptions = {
				series: [
					{
						// name: "feature importance",
						data: [100,
							this.chat.smarket_session_percentage,
							this.chat.percentage_scored_sessions,
							this.chat.percentage_cta,
							this.chat.total_mkt_actions_percentage,
							this.chat.pos_actions_percentage],
					},
				],
				colors: [
					"#3BB5FE",
					"#90BDF1",
					"#B4D1F2",
					"#C3DAF3",
					"#C3D3E2",
					"#CFDBE6",
					"#B3C8DC",
				],
				chart: {
					type: "bar",
					height: 300,
					width: 800,
					toolbar: {
						show: true,
						tools: {
							download: false,
						},
					},
				},
				states: {
					hover: {
						filter: {
							type: 'none',
						},
						normal: {
							filter: {
								type: 'none',
							},
						},
					},
				},
				dataLabels: {
					enabled: true,
					textAnchor: "start",
					style: {
						colors: ["#fff"],
					},
					formatter: function (val, opt) {
						switch (opt.dataPointIndex) {
							case 0:
								return +' '+chatData.total_sessions;
							case 1:
								return +' '+chatData.smarket_session_number;
							case 2:
								return +' '+chatData.scored_sessions;
							case 3:
								return +' '+chatData.cta_triggered;
							case 4:
								return +' '+chatData.total_mkt_actions_number;
							case 5:
								return +' '+chatData.pos_actions_number;
							default:
								return ' ';
						}
					},
					offsetX: 0,
					dropShadow: {
						enabled: true,
					},
				},

				plotOptions: {
					bar: {
						horizontal: true,
						distributed: true,
					},
				},
				xaxis: {
					categories: [
						"Total Number Of Sessions",
						"Number Of Data Scale Sessions",
						"Number Of Scored Sessions",
						"Number Of Cta Triggered",
						"Total Number Of Interactions ",
						"Number Of Positive Interactions",
					],

				},
				legend: {
					show: false, // Consider hiding legend
				},
				yaxis: {
					labels: {
						style: {
							fontSize: "9px",
							rotation: -60, // Rotate labels slightly
						},
						// paddingLeft: 10, // Reduce left padding if needed
						// paddingRight: 10, // Reduce right padding if needed

					},
				},
			};
		}




	}

	constructor() {

	}
}
