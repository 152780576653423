// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { BqRecordsModel } from '../_models/BqRecordsModel';

export enum BqRecordsActionTypes {
    BqRecordsOnServerCreated = '[Edit BqRecords Dialog] BqRecords On Server Created',
    BqRecordsCreated = '[Edit BqRecords Dialog] BqRecords Created',
    BqRecordsUpdated = '[Edit BqRecords Dialog] BqRecords Updated',
    BqRecordsUpdated2 = '[Edit BqRecords Dialog] BqRecords Updated2',
    BqRecordsStatusUpdated = '[BqRecordss List Page] BqRecordss Status Updated',
    OneBqRecordsDeleted = '[BqRecordss List Page] One BqRecords Deleted',

    BqRecordsPageRequested = '[BqRecordss List Page] BqRecordss Page Requested',
    BqRecordsPageLoaded = '[BqRecordss API] BqRecordss Page Loaded',
    BqBqRecordsPageRequested = '[BqBqRecordss List Page] BqBqRecordss Page Requested',
    BqBqRecordsPageLoaded = '[BqBqRecordss API] BqBqRecordss Page Loaded',

    ScoringBqRecordsPageRequested = '[ScoringBqRecords List Page] ScoringBqRecords Page Requested',
    ScoringBqRecordsPageLoaded = '[ScoringBqRecords API] ScoringBqRecords Page Loaded',
    TriggerPageRequested = '[ScoringBqRecords List Page] Trigger Page Requested',
    TriggerPageLoaded = '[ScoringBqRecords API] Trigger Page Loaded',
    BqRecordsPageCancelled = '[BqRecordss API] BqRecordss Page Cancelled',
    BqRecordsPageToggleLoading = '[BqRecordss] BqRecordss Page Toggle Loading',
    BqRecordsActionToggleLoading = '[BqRecordss] BqRecordss Action Toggle Loading'
}

export class BqRecordsOnServerCreated implements Action {
    readonly type = BqRecordsActionTypes.BqRecordsOnServerCreated;
    constructor(public payload: { BqRecords: BqRecordsModel }) { }
}

export class BqRecordsCreated implements Action {
    readonly type = BqRecordsActionTypes.BqRecordsCreated;
    constructor(public payload: { BqRecords: BqRecordsModel }) { }
}

export class BqRecordsUpdated implements Action {
    readonly type = BqRecordsActionTypes.BqRecordsUpdated;
    constructor(public payload: {
        partialBqRecords: Update<BqRecordsModel>, 
        BqRecords: BqRecordsModel 
    }) { }
}
export class BqRecordsUpdated2 implements Action {
    readonly type = BqRecordsActionTypes.BqRecordsUpdated2;
    constructor(public payload: {
        partialBqRecords: Update<any>, 
        BqRecords: any ,
        type:string
    }) { }
}


export class BqRecordsStatusUpdated implements Action {
    readonly type = BqRecordsActionTypes.BqRecordsStatusUpdated;
    constructor(public  payload: {
        BqRecordss: BqRecordsModel[],
        status: number
    }) { }
}

export class OneBqRecordsDeleted implements Action {
    readonly type = BqRecordsActionTypes.OneBqRecordsDeleted;
    constructor(public payload: { id: number }) {}
}


export class BqRecordsPageRequested implements Action {
    readonly type = BqRecordsActionTypes.BqRecordsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class BqRecordsPageLoaded implements Action {
    readonly type = BqRecordsActionTypes.BqRecordsPageLoaded;
    constructor(public payload :any ) { }
}

export class BqBqRecordsPageRequested implements Action {
    readonly type = BqRecordsActionTypes.BqBqRecordsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class BqBqRecordsPageLoaded implements Action {
    readonly type = BqRecordsActionTypes.BqBqRecordsPageLoaded;
    constructor(public payload :any ) { }
}
export class ScoringBqRecordsPageRequested implements Action {
    readonly type = BqRecordsActionTypes.ScoringBqRecordsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class ScoringBqRecordsPageLoaded implements Action {
    readonly type = BqRecordsActionTypes.ScoringBqRecordsPageLoaded;
    constructor(public payload :any ) { }
}

export class TriggerPageRequested implements Action {
    readonly type = BqRecordsActionTypes.TriggerPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class TriggerPageLoaded implements Action {
    readonly type = BqRecordsActionTypes.TriggerPageLoaded;
    constructor(public payload :any ) { }
}
export class BqRecordsPageCancelled implements Action {
    readonly type = BqRecordsActionTypes.BqRecordsPageCancelled;
}

export class BqRecordsPageToggleLoading implements Action {
    readonly type = BqRecordsActionTypes.BqRecordsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class BqRecordsActionToggleLoading implements Action {
    readonly type = BqRecordsActionTypes.BqRecordsActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type BqRecordsActions = BqRecordsOnServerCreated
| BqRecordsCreated
| BqRecordsUpdated
| BqRecordsUpdated2
| BqRecordsStatusUpdated
| OneBqRecordsDeleted
| BqRecordsPageRequested
| BqRecordsPageLoaded
| BqBqRecordsPageRequested
| BqBqRecordsPageLoaded
| ScoringBqRecordsPageRequested
| ScoringBqRecordsPageLoaded
| TriggerPageRequested
| TriggerPageLoaded
| BqRecordsPageCancelled
| BqRecordsPageToggleLoading
| BqRecordsActionToggleLoading;
