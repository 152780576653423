import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
import { EventCapturedState } from '../_reducers/EventCaptured.reducers';
import { EventCapturedModel } from '../_models/EventCapturedModel';

export const selectEventCapturedsState = createFeatureSelector<EventCapturedState>('EventCaptureds');

export const selectEventCapturedById = (EventCapturedId: number) => createSelector(
    selectEventCapturedsState,
    EventCapturedState => EventCapturedState.entities[EventCapturedId]
);

export const selectEventCapturedsPageLoading = createSelector(
    selectEventCapturedsState,
    EventCapturedState => EventCapturedState.listLoading
);

export const selectEventCapturedsActionLoading = createSelector(
    selectEventCapturedsState,
    EventCapturedState => EventCapturedState.actionsloading
);

export const selectLastCreatedEventCapturedId = createSelector(
    selectEventCapturedsState,
    EventCapturedState => EventCapturedState.lastCreatedEventCapturedId
);

export const selectEventCapturedsShowInitWaitingMessage = createSelector(
    selectEventCapturedsState,
    EventCapturedState => EventCapturedState.showInitWaitingMessage
);

export const selectEventCapturedsInStore = createSelector(
    selectEventCapturedsState,
    EventCapturedState => {
        const items: EventCapturedModel[] = [];
        each(EventCapturedState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: EventCapturedModel[] = httpExtension.sortArray(items, EventCapturedState.lastQuery.sortField, EventCapturedState.lastQuery.sortOrder);
        return new QueryResultsModel(result, EventCapturedState.totalCount, '');
    }
);

