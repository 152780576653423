// Angular
import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment1 } from '../../../../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';

@Component({
	selector: 'kt-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['notification.component.scss']
})
export class NotificationComponent {

	// Show dot on top of the icon
	@Input() dot: string;

	// Show pulse on icon
	@Input() pulse: boolean;

	@Input() pulseLight: boolean;

	// Set icon class name
	@Input() icon = 'flaticon2-bell-alarm-symbol';
	@Input() iconType: '' | 'success';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG: boolean;

	// Set bg image path
	@Input() bgImage: string;

	// Set skin color, default to light
	@Input() skin: 'light'  = 'light';

	@Input() type: 'brand' | 'success' = 'success';
	eids: any;
	eid: any;

	/**
	 * Component constructor
	 *
	 * @param sanitizer: DomSanitizer
	 */
	constructor(private router: Router,private sanitizer: DomSanitizer, private http: HttpClient, private afAuth: AngularFireAuth,private _cdr: ChangeDetectorRef, ) {

		this.get().subscribe((res: any) => {
			this.eids = res.user_data.claims.eids;
			this.eid = res.user_data.claims.eid;
		});
	}

	 save(x) {
		this.changeeid(x).subscribe(list => {
			this.get().subscribe((res: any) => {
				this.changeStatus();
				this.router.navigateByUrl('/goal/detail', { skipLocationChange: true }).then(() => {
					this.router.navigate(['/popup/goal']);
					this.changeStatus();
					this.get();
		
				});
				this.eids = res.user_data.claims.eids;
				this.eid = res.user_data.claims.eid;
			});

		})
	}
	changeStatus(): void {

		setTimeout(() => {
		  this._cdr.detectChanges()
		  this._cdr.markForCheck()

		}, 100);
	   }
	get() {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.get(environment.API_URL+'/users/user_claims', { headers: headers });
	}

	changeeid(eid): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.put(environment.API_URL+'/users/set_active_namespace/' + eid, { headers: headers });
	}
	backGroundStyle(): string { 
		if (!this.bgImage) {
			return 'none';
		}

		return 'url(' + this.bgImage + ')';
	}
}
