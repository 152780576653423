// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { EventCapturedModel } from '../_models/EventCapturedModel';

export enum EventCapturedActionTypes {
    EventCapturedOnServerCreated = '[Edit EventCaptured Dialog] EventCaptured On Server Created',
    EventCapturedCreated = '[Edit EventCaptured Dialog] EventCaptured Created',
    EventCapturedUpdated = '[Edit EventCaptured Dialog] EventCaptured Updated',
    EventCapturedsStatusUpdated = '[EventCaptureds List Page] EventCaptureds Status Updated',
    OneEventCapturedDeleted = '[EventCaptureds List Page] One EventCaptured Deleted',

    EventCapturedsPageRequested = '[EventCaptureds List Page] EventCaptureds Page Requested',
    EventCapturedsPageLoaded = '[EventCaptureds API] EventCaptureds Page Loaded',


    EventCapturedsPageCancelled = '[EventCaptureds API] EventCaptureds Page Cancelled',
    EventCapturedsPageToggleLoading = '[EventCaptureds] EventCaptureds Page Toggle Loading',
    EventCapturedActionToggleLoading = '[EventCaptureds] EventCaptureds Action Toggle Loading'
}

export class EventCapturedOnServerCreated implements Action {
    readonly type = EventCapturedActionTypes.EventCapturedOnServerCreated;
    constructor(public payload: { EventCaptured: EventCapturedModel }) { }
}

export class EventCapturedCreated implements Action {
    readonly type = EventCapturedActionTypes.EventCapturedCreated;
    constructor(public payload: { EventCaptured: EventCapturedModel }) { }
}

export class EventCapturedUpdated implements Action {
    readonly type = EventCapturedActionTypes.EventCapturedUpdated;
    constructor(public payload: {
        partialEventCaptured: Update<EventCapturedModel>, 
        EventCaptured: EventCapturedModel 
    }) { }
}

export class EventCapturedsStatusUpdated implements Action {
    readonly type = EventCapturedActionTypes.EventCapturedsStatusUpdated;
    constructor(public  payload: {
        EventCaptureds: EventCapturedModel[],
        status: number
    }) { }
}

export class OneEventCapturedDeleted implements Action {
    readonly type = EventCapturedActionTypes.OneEventCapturedDeleted;
    constructor(public payload: { id: number }) {}
}


export class EventCapturedsPageRequested implements Action {
    readonly type = EventCapturedActionTypes.EventCapturedsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class EventCapturedsPageLoaded implements Action {
    readonly type = EventCapturedActionTypes.EventCapturedsPageLoaded;
    constructor(public payload: { EventCaptureds: EventCapturedModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class EventCapturedsPageCancelled implements Action {
    readonly type = EventCapturedActionTypes.EventCapturedsPageCancelled;
}

export class EventCapturedsPageToggleLoading implements Action {
    readonly type = EventCapturedActionTypes.EventCapturedsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class EventCapturedActionToggleLoading implements Action {
    readonly type = EventCapturedActionTypes.EventCapturedActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type EventCapturedActions = EventCapturedOnServerCreated
| EventCapturedCreated
| EventCapturedUpdated
| EventCapturedsStatusUpdated
| OneEventCapturedDeleted
| EventCapturedsPageRequested
| EventCapturedsPageLoaded
| EventCapturedsPageCancelled
| EventCapturedsPageToggleLoading
| EventCapturedActionToggleLoading;
