import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, map, tap, delay } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryResultsModel } from '../../_base/crud';
import { ScenarioConfigsService } from '../_services';
import { AppState } from '../../reducers';
import {

    ScenarioConfigActionToggleLoading,
    ScenarioConfigActionTypes,
    ScenarioConfigsPageLoaded,
    ScenarioConfigsPageRequested,
    ScenarioConfigsPageToggleLoading,
    OneScenarioConfigDeleted,
    ScenarioConfigOnServerCreated,
    ScenarioConfigCreated,
    Generate,
    Train,
    ScenarioConfigsDetailRequested,
    ScenarioConfigUpdated

} from '../_actions/scenarioConfig.actions';
import { of } from 'rxjs';

@Injectable()
export class ScenarioConfigEffects {
    showPageLoadingDistpatcher = new ScenarioConfigsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new ScenarioConfigActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new ScenarioConfigActionToggleLoading({ isLoading: false });

    @Effect()
    loadScenarioConfigsPage$ = this.actions$.pipe(
        ofType<ScenarioConfigsPageRequested>(ScenarioConfigActionTypes.ScenarioConfigsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.ScenarioConfigsService.findScenarioConfigs(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new ScenarioConfigsPageLoaded({
                ScenarioConfigs: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteCustomer$ = this.actions$
        .pipe(
            ofType<OneScenarioConfigDeleted>(ScenarioConfigActionTypes.OneScenarioConfigDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.ScenarioConfigsService.deleteSenario(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );
       @Effect()
        generate$ = this.actions$
            .pipe(
                ofType<Generate>(ScenarioConfigActionTypes.Generate),
                mergeMap(( { payload } ) => {
                    
                        this.store.dispatch(this.showActionLoadingDistpatcher);
                        return this.ScenarioConfigsService.genrate(payload.id);
                    } 
                ),
                map(() => {
                    return this.hideActionLoadingDistpatcher;
                }),
            ); 
            @Effect()
            Train$ = this.actions$
                 .pipe(
                     ofType<Train>(ScenarioConfigActionTypes.Train),
                     mergeMap(( { payload } ) => {
                         
                             this.store.dispatch(this.showActionLoadingDistpatcher);
                             return this.ScenarioConfigsService.train(payload.id);
                         } 
                     ),
                     map(() => {
                         return this.hideActionLoadingDistpatcher;
                     }),
                 ); 

        @Effect()
        createScenarioConfig$ = this.actions$
            .pipe(
                ofType<ScenarioConfigOnServerCreated>(ScenarioConfigActionTypes.ScenarioConfigOnServerCreated),
                mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.ScenarioConfigsService.createScenarioConfig(payload.ScenarioConfig).pipe(
                        tap(res => {
                            this.store.dispatch(new ScenarioConfigCreated({ ScenarioConfig: res }));
                        })
                    );
                }),
                map(() => {
                    return this.hideActionLoadingDistpatcher;
                }),
            );

            @Effect()
            updateToggel$ = this.actions$
                .pipe(
                    ofType<ScenarioConfigUpdated>(ScenarioConfigActionTypes.ScenarioConfigUpdated),
                    mergeMap(( { payload } ) => {
                        this.store.dispatch(this.showActionLoadingDistpatcher);
                        return this.ScenarioConfigsService.updateToggle(payload.ScenarioConfig);
                    }),
                    map(() => {
                        return this.hideActionLoadingDistpatcher;
                    })
                );
            
    constructor(private actions$: Actions, private ScenarioConfigsService: ScenarioConfigsService, private store: Store<AppState>) { }

}
