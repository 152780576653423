import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, map, tap, delay } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryResultsModel } from '../../_base/crud';
import { TriggersService } from '../_services';
import { AppState } from '../../reducers';
import {

    TriggerActionToggleLoading,
    TriggerActionTypes,
    TriggersPageLoaded,
    TriggersPageRequested,

   TriggerPageRequested,

    TriggersPageToggleLoading,
    TriggerOnServerCreated,
    TriggerCreated,
    TriggerUpdated,
    TriggerUpdated2,

   TriggerPageLoaded


} from '../_actions/Trigger.actions';
import { of } from 'rxjs';

@Injectable()
export class TriggerEffects {
    showPageLoadingDistpatcher = new TriggersPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new TriggerActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new TriggerActionToggleLoading({ isLoading: false });

    @Effect()
    loadTriggersPage$ = this.actions$.pipe(
        ofType<TriggersPageRequested>(TriggerActionTypes.TriggersPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.TriggersService.findTriggers(payload.page);            
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];            
            const pageLoadedDispatch = new TriggersPageLoaded({
                Triggers: result
            });
            return pageLoadedDispatch;
        })
    );





        @Effect()
        createTrigger$ = this.actions$
            .pipe(
                ofType<TriggerOnServerCreated>(TriggerActionTypes.TriggerOnServerCreated),
                mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.TriggersService.createTrigger(payload.Trigger).pipe(
                        tap(res => {
                            this.store.dispatch(new TriggerCreated({ Trigger: res }));
                        })
                    );
                }),
                map(() => {
                    return this.hideActionLoadingDistpatcher;
                }),
            );

            @Effect()
            updateToggel$ = this.actions$
                .pipe(
                    ofType<TriggerUpdated>(TriggerActionTypes.TriggerUpdated),
                    mergeMap(( { payload } ) => {                        
                        this.store.dispatch(this.showActionLoadingDistpatcher);
                        this.TriggersService.updateTrigger(payload.Trigger);
                        return this.TriggersService.updateTrigger(payload.Trigger);
                    }),
                    map(() => {
                        return this.hideActionLoadingDistpatcher;
                    })
                );
                @Effect()

                updateToggel2$ = this.actions$
                .pipe(
                    ofType<TriggerUpdated2>(TriggerActionTypes.TriggerUpdated2),
                    mergeMap(( { payload } ) => {                                                
                        this.store.dispatch(this.showActionLoadingDistpatcher);
                        this.TriggersService.updateTrigger2(payload.Trigger,payload.type);
                        return this.TriggersService.updateTrigger2(payload.Trigger,payload.type);
                    }),
                    map(() => {
                        return this.hideActionLoadingDistpatcher;
                    })
                );
    constructor(private actions$: Actions, private TriggersService: TriggersService, private store: Store<AppState>) { }

}
