import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryRealtimeParamsModel, HttpExtenstionsModel } from '../../_base/crud';
import { KpiaddState } from '../_reducers/kpiadd.reducers';
import { KpiModel } from '../_models/kpiModel';

export const selectKpiaddsState = createFeatureSelector<KpiaddState>('Kpiadds');

export const selectKpiaddById = (KpiaddId: number) => createSelector(
    selectKpiaddsState,
    KpiaddState => KpiaddState.entities[KpiaddId]
);

export const selectKpiaddsPageLoading = createSelector(
    selectKpiaddsState,
    KpiaddState => KpiaddState.listLoading
);

export const selectKpiaddsActionLoading = createSelector(
    selectKpiaddsState,
    KpiaddState => KpiaddState.actionsloading
);

export const selectLastCreatedKpiaddId = createSelector(
    selectKpiaddsState,
    KpiaddState => KpiaddState.lastCreatedKpiaddId
);

export const selectKpiaddsShowInitWaitingMessage = createSelector(
    selectKpiaddsState,
    KpiaddState => KpiaddState.showInitWaitingMessage
);

export const selectKpiaddsInStore = createSelector(
    selectKpiaddsState,
    KpiaddState => {
        const items: KpiModel[] = [];
        each(KpiaddState.entities, element => {
            items.push(element);
        });        
        const httpExtension = new HttpExtenstionsModel();
        const result: KpiModel[] = httpExtension.sortArray(items, KpiaddState.lastQuery.sortField, KpiaddState.lastQuery.sortOrder);
        return new QueryRealtimeParamsModel(result);
    }
);

