import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { ScenarioConfigActions, ScenarioConfigActionTypes } from '../_actions/scenarioConfig.actions';
import { ScenarioConfigModel } from '../_models/scenarioConfigModel';
import { QueryParamsModel } from '../../_base/crud';

export interface ScenarioConfigState extends EntityState<ScenarioConfigModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedScenarioConfigId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ScenarioConfigModel> = createEntityAdapter<ScenarioConfigModel>();

export const initialScenarioConfigState: ScenarioConfigState = adapter.getInitialState({
    ScenarioConfigForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedScenarioConfigId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function ScenarioConfigsReducer(state = initialScenarioConfigState, action: ScenarioConfigActions): ScenarioConfigState {
    switch  (action.type) {
        case ScenarioConfigActionTypes.ScenarioConfigsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedScenarioConfigId: undefined
            };
        }    

        case ScenarioConfigActionTypes.ScenarioConfigActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
                

        case ScenarioConfigActionTypes.ScenarioConfigOnServerCreated: return {
            ...state
        };

        case ScenarioConfigActionTypes.ScenarioConfigUpdated: return adapter.updateOne(action.payload.partialScenarioConfig, state);

        // case ScenarioConfigActionTypes.ScenarioConfigsStatusUpdated: {
        //     const _partialScenarioConfig: Update<ScenarioConfigModel>[] = [];
        //     for (let i = 0; i < action.payload.ScenarioConfigs.length; i++) {
        //         _partialScenarioConfig.push({
		// 		    id: action.payload.ScenarioConfigs[i].id,
		// 		    changes: {
        //                 status: action.payload.status
        //             }
		// 	    });
        //     }
        //     return adapter.updateMany(_partialScenarioConfig, state);
        // }

        case ScenarioConfigActionTypes.OneScenarioConfigDeleted: return adapter.removeOne(action.payload.id, state);

        case ScenarioConfigActionTypes.ScenarioConfigsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }

        case ScenarioConfigActionTypes.ScenarioConfigsPageLoaded: {
            return adapter.addMany(action.payload.ScenarioConfigs, {
                ...initialScenarioConfigState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}



export const getScenarioConfigState = createFeatureSelector<ScenarioConfigModel>('ScenarioConfigs');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
